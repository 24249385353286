<template>
  <ab-flow-base-cmp v-if="isReady" :block="block" :class="' widget-cmp ' + (isExpanded ? ' expanded ' : '') + classesString" :style="stylesString">
    <diagram-component-editor-cmp ref="diagram" :block="dcBlock"/>
  </ab-flow-base-cmp>
</template>

<script>

import {renderMixins} from "../../renderMixins";
import AbFlowBaseCmp from "../../Containers/Designer/AbFlowBaseCmp.vue";
import DiagramComponentEditorCmp from "../../Logic/DiagramComponent/DiagramComponentEditorCmp.vue";

export default {
  components: {DiagramComponentEditorCmp, AbFlowBaseCmp},
  mixins: [renderMixins],
  props: ['block'],
  name: "WidgetEditorCmp",
  provide() {
    return {
      parentWidget: this,
    }
  },
  data: () => ({
    widgetId: false,
    storage: false,
    dcBlock: false,
    isReady: false
  }),
  async created() {

    // Get id from type
    this.widgetId = this.block.type?.split(":")?.[1]

    // Init storage
    //this.initStorage();

    // Export events
    this.exportIncomingEvents()

    // Construct block
    this.dcBlock = {widget: this.block?.title, type: "DiagramComponent", id: this.block.id, properties: {diagramComponentId: this.widgetId}}

    // Get arguments and store to storage
    //this.widgetParams = this.getArguments(this.block.id);
    //console.error(this.widgetParams)

    // Ready
    this.isReady = true
  },

  methods: {

    /**
     * Export incoming events
     */
    exportIncomingEvents() {
      for(const ceCmp of this.widgetSource?.source?.children?.filter(c => c.type === 'CustomEvent' && c.properties?.eventType === 'incoming') || [] ) {

        // Event name
        const evName = ceCmp.properties?.name

        // Register event in parent diagram
        this.parentDiagram.registerHandler(this.block.id, evName, (data) => {
          //console.log('Run event', this.parentDiagram, this, ceCmp, evName, data)
          //this.renderer.a2u.run(this.parentDiagram, this.$refs.diagram, ceCmp.id, evName, data)
          //console.log('Run event', this.parentDiagram, this, ceCmp, evName, data)
          //this.$refs.diagram.runDiagram(evName, data)

          this.renderer.a2u.run(this.$refs.diagram.$refs.renderer, this.$refs.diagram.$refs.renderer, ceCmp.id, evName, data)
        })
      }
    }
  },

  computed: {

    /**
     * Get widget params
     * @return {{}}
     */
    widgetParams() {
      return this.getArguments(this.block.id);
    },

    /**
     * Block is expanded
     */
    isExpanded() {
      return this.block?.properties?.extended
    },

    /**
     * Widget blocks
     * @return {*}
     */
    widgetBlocks() {
      return this.widgetSource?.source?.children?.find(c => c.properties?.fragmentType === 'widget')?.children;
    },

    /**
     * Widget source
     * @return {*}
     */
    widgetSource() {
      return this.renderer.a2u.diagrams[this.widgetId]
    }
  },
}

</script>

<style lang="scss">

.widget-cmp {
  position: relative;
  flex-direction: column;
  min-width: auto;
  min-height: auto;

  &.expanded {
    width: 100%;
    height: 100%;
  }
}
</style>
