import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * Manage subscriptions
 */
export class InAppRestorePurchasesProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param event
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    async processEvent() {

        // Debug list of goods for web environment
        if(this.context.renderer.a2u.getPlatform() === "web") {
            return this.debugResponse({success: {}, error: {}, empty: {}}, `Restore purchases`)
        }

        // Show loading
        this.app.$q.loading.show();

        // Disallow restore event
        this.context.renderer.a2u.allowRestore(false);

        // Get ad instance
        const iapManager = this.context.renderer.a2u.getDevice()?.getPluginInstance?.("IAPManager");

        try {
            // Get products list
            const res = await iapManager?.restorePurchases?.();
            console.log("Restore purchases result", res)

            // Process result
            return this.processOutgoingLinks(this.context, this.block.id, false, res?.status || "error");
        } finally {
            // Hide loading
            this.app.$q.loading.hide();

            // Allow restore event
            this.context.renderer.a2u.allowRestore(false);
        }
    }
}
