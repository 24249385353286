<template>
  <div>
    <q-input
      outlined
      v-model="variablePath"
      autogrow
      label="Variable path"
      hint="Example: app:variableName.path or diagram:variableName.path"
    >
      <template #after>
        <q-btn square color="positive" icon="play_arrow" @click="getValue" />
      </template>
    </q-input>

    <q-banner v-if="error" dense inline-actions class="text-white bg-red q-mt-md">
      {{ error }}
    </q-banner>

    <q-card class="q-mt-md">
      <q-card-section class="bg-primary text-white">
        Variable value
      </q-card-section>

      <q-card-section>
        <pre>{{ preparedResult }}</pre>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
export default {
  name: 'AppStorage',

  inject: ['renderer'],

  data() {
    return {
      variablePath: '',
      result: 'Please enter a variable path',
      error: null,
    };
  },

  computed: {
    /**
     * Computes a string representation of the result.
     * - If the result is `undefined`, returns the string "undefined".
     * - If the result is `null`, returns the string "null".
     * - If the result is an object, returns the JSON stringified version of the object.
     * - Otherwise, returns the string representation of the result.
     *
     * @returns {string} The string representation of the result.
     */
    preparedResult() {
      if (this.result === undefined) {
        return "undefined";
      } else if (this.result === null) {
        return "null";
      } else if (typeof this.result === 'object') {
        return JSON.stringify(this.result, null, 2);
      } else {
        return String(this.result);
      }
    },
  },

  methods: {
    /**
     * Resets the state of the component.
     * - Sets the result to a default message.
     * - Clears any existing error messages.
     */
    resetState() {
      this.result = 'Please enter a variable path';
      this.error = null;
    },

    /**
     * Retrieves the value of the variable specified by `variablePath`.
     * - Uses the `getValue` method from the `renderer.a2u.currentDiagramComonent`.
     * - If the method is not available, throws an error.
     * - If an error occurs during execution, logs the error and sets the error message.
     */
    getValue() {
      try {
        if (typeof this.renderer?.a2u?.currentDiagramComonent?.getValue !== 'function') {
          throw new Error('getValue method is not available');
        }

        if (!this.variablePath) {
          throw new Error('Variable path is empty');
        }

        this.result = this.renderer.a2u.currentDiagramComonent.getValue({
          valueType: "variable",
          value: this.variablePath,
        });

        console.log('Variable value:', this.result);
      } catch (e) {
        console.error('Error executing SQL query:', e);

        this.error = e.message;
      }
    },
  },

  created() {
    this.resetState();
  },
}
</script>
