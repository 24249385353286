import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class OpenEmailProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param event
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    processEvent(event, data) {

        let subject = this.context.interpretString(this.block.properties?.subject) || null;

        if (subject) {
            subject = window.encodeURIComponent(subject);
        }

        const email = this.context.getValue(this.block.properties?.email);

        // Get result
        window.open(`mailto:${email}${subject ? `?subject=${subject}` : ''}`);

        // Process events
        this.processOutgoingLinks(this.context, this.block.id);
    }
}
