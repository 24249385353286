import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';

/**
 * Class representing a Three.js plugin.
 */
export class ThreePlugin {
  scene = null;
  camera = null;
  renderer = null;
  clock = null;
  animationMixers = [];

  /**
   * Create a ThreePlugin.
   * @param {Object} params - The parameters.
   * @param {HTMLElement} params.container - The container element.
   * @param {Object} params.size - The size of the renderer.
   * @param {string} params.bgColor - The background color.
   * @param {Array} params.cameraPosition - The camera position.
   * @param {Array} params.cameraLookAt - The camera lookAt position.
   */
  constructor({ container, size, bgColor, cameraPosition, cameraLookAt }) {
    this.container = container;
    this.size = size;
    this.bgColor = bgColor;
    this.cameraPosition = cameraPosition;
    this.cameraLookAt = cameraLookAt;
  }

  /**
   * Initialize the scene.
   */
  initScene() {
    // Create the scene
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(this.bgColor);

    // Create the camera
    this.camera = new THREE.PerspectiveCamera(75, this.size.width / this.size.height, 0.1, 1000);
    this.camera.position.set(...this.cameraPosition)
    this.camera.lookAt(...this.cameraLookAt);

    // Add lights to the scene
    const ambientLight = new THREE.AmbientLight(0xffffff, 2);
    this.scene.add(ambientLight);

    const light = new THREE.PointLight(0xffffff, 400);
    light.position.set(5, 20, 0);
    light.shadow.mapSize.width = 1024;
    light.shadow.mapSize.height = 1024;
    light.castShadow = true;
    this.scene.add(light);

    // Create the renderer
    this.renderer = new THREE.WebGLRenderer({antialias:true});
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.size.width, this.size.height);
    this.container.appendChild(this.renderer.domElement);

    this.clock = new THREE.Clock();

    // Start the animation
    this.animate();
  }

  /**
   * Animate the scene.
   * @param {number} t - The current time.
   */
  animate(t) {
    // Request the next animation frame
    requestAnimationFrame(this.animate.bind(this));

    // Get the delta time
    const delta = this.clock.getDelta();

    // Update each animation mixer
    this.animationMixers.forEach((mixer) => {
      mixer.update(delta);
    });

    // Render each object in the scene
    this.scene.traverse((obj) => {
      if (obj.render) obj.render(t)
    });

    // Render the scene
    this.renderer.render(this.scene, this.camera);
  }

  /**
   * Set the scene background color.
   * @param {string} bgColor - The background color.
   */
  setSceneBackground(bgColor) {
    this.scene.background = new THREE.Color(bgColor);
  }

  /**
   * Set the position of the camera.
   * @param {Array} position - The new position of the camera. An array of three numbers representing the x, y, and z coordinates.
   */
  setCameraPosition(position) {
    this.camera.position.set(...position);
  }

  /**
   * Set the point at which the camera is looking.
   * @param {Array} lookAt - The point at which the camera should look. An array of three numbers representing the x, y, and z coordinates.
   */
  setCameraLookAt(lookAt) {
    this.camera.lookAt(...lookAt);
  }

  /**
   * Resize the scene.
   * @param {Object} size - The new size.
   */
  resizeScene({ width, height }) {
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(width, height);
  }

  /**
   * Create an animation mixer.
   * @param {Object} object - The 3D object.
   * @return {Object} The animation mixer.
   */
  createAnimationMixer(object) {
    const mixer = new THREE.AnimationMixer(object);

    this.animationMixers.push(mixer);

    return mixer;
  }

  /**
   * Load a FBX file.
   * @param {string} url - The URL of the FBX file.
   * @return {Promise} A promise that resolves with the loaded object.
   */
  async fbxLoader(url) {
    const loader = new FBXLoader();

    return new Promise((resolve) => {
      loader.load(url, (object) => {
        resolve(object);
      });
    });
  }
}
