import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class CheckTrialPeriodProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param event
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    processEvent(event, data) {
        // Get products ids
        const products = this.context.interpretString(this.block?.properties?.products)?.trim();

        // Debug list of goods for web environment
        if(this.context.renderer.a2u.getPlatform() === "web") {
            return this.debugResponse({yes: {}, no: {}}, `Trial period available: ${products}?`)
        }

        // Get ad instance
        const iapManager = this.context.renderer.a2u.getDevice()?.getPluginInstance?.("IAPManager");

        // Check trial period
        iapManager?.checkTrialPeriod(products === '*' ? products : products.split(',')).then(result => {
            this.processOutgoingLinks(this.context, this.block.id, undefined, result ? "yes" : "no");
        }).catch(e => {
            console.error("Error check trial period", e);
            this.processOutgoingLinks(this.context, this.block.id, undefined, "no");
        });
    }
}
