import {BaseProcessor} from 'a2u-renderer-common/src/processors/BaseProcessor';

export class BaseComponentProcessor extends BaseProcessor {
    /**
     * Get diagram
     * @returns {*}
     */
    getDiagram() {
        return this.diagram
    }

    /**
     * Get global application
     * @returns {*}
     */
    getApplication() {
        return this.diagram.application
    }

    /**
     * Get parent application
     * @returns {*}
     */
    getParentApplication() {
        return this.getApplication().parentApplication
    }

    /**
     * Log
     * @param type
     * @param args
     */
    log(type, ...args) {
        this.getApplication().log(type, ...args)
    }

    /**
     * Logs an error using the application's error logger.
     *
     * @param {Error} error - The error object to be logged.
     */
    logError(error) {
        try {
            this.context.renderer.a2u.errorLogger.log(error)
        } catch (e) {
            console.error('Failed to log error', e);
        }
    }
}
