import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * Manage subscriptions
 */
export class InAppManageSubsProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param event
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    async processEvent() {
        const moduleId = this.context.renderer.a2u.getModuleId();
        const targetPlatform = this.context.renderer.a2u.source.modules[moduleId]?.type === 'web' ? 'web' : 'mobile';

        // Debug list of goods for web environment
        if(this.context.renderer.a2u.getPlatform() === "web" && targetPlatform !== 'web') {
            return this.debugResponse({success: {}}, `Manage subscriptions`)
        }

        try {
            // Get ad instance
            const iapManager = this.context.renderer.a2u.getDevice()?.getPluginInstance?.("IAPManager");

            if (targetPlatform === 'web') {
                this.app.$q.loading.show();
            }

            // Get products list
            const res = await iapManager?.manageSubscriptions?.();
            console.log("Manage subscription result", res)

            // Process result
            return this.processOutgoingLinks(this.context, this.block.id, false, "success");
        } finally {
            if (targetPlatform === 'web') {
                this.app.$q.loading.hide();
            }
        }
    }
}
