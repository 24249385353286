import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";
import {SoundManager} from "../../utils/soundManager";

/**
 * Vibrate Processor
 */
export class VibrateProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param event
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    async processEvent(event, data) {

        // Get ad params
        const vibrationType = this.block.properties?.vibrationType;

        // Load Haptics plugin
        const Haptics = this.context.renderer.a2u.getDevice()?.getPlugin?.("Haptics");

        const soundManager = SoundManager.getInstance();

        // Check if vibration is muted
        if (soundManager.isChannelMuted('vibration')) {
            return;
        }

        // Check if Haptics exists
        if (Haptics) {
            try {
                // Duration map
                const durationsMap = {
                    small: 200,
                    medium: 500,
                    long: 1000,
                };

                // Vibrate
                await Haptics.vibrate({
                    duration: durationsMap[this.block.properties?.vibrationType] || 300,
                });
            } catch (e) {
                console.log('Vibration is not allowed: ', e);

                // Fall back to default vibration
                soundManager.vibrate(vibrationType);
            }
        } else {
            // Fall back to default vibration
            soundManager.vibrate(vibrationType);
        }
    }
}
