import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * System alarm processor
 */
export class SystemAlarmProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }

    /**
     * Converts an asset path to a Base64 string.
     *
     * This method first calls the 'assetPath' method of the 'a2u' object with the asset as the argument.
     * It then calls the 'assetBase64' method of the 'pathHelper' object with the result of the 'assetPath' method as the argument.
     * It returns the result of the 'assetBase64' method.
     *
     * @param {string} asset - The asset to convert.
     * @returns {Promise<string>} A promise that resolves to the Base64 string of the asset.
     * @async
     */
    async _assetBase64(asset) {
        try {
            return await this.context.renderer.a2u.assetBase64(asset);
        } catch (e) {
            console.log("Error in assetBase64", e);

            return null;
        }
    }

    /**
     * Process incoming event
     * @param event
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    async processEvent(event, data) {
        const alarmType = this.block?.properties?.alarmType || 'alarm'; // push

        // Getting alarm config from input
        const alarms = [];

        // Random alarm id
        let alarmId = parseInt(Math.floor(10000 * Math.random()));

        // Config
        const config = data?.input?.length
          ? data.input
          : (this.block?.properties?.alarms?.items || []).map((cfg) => ({
              ...cfg,
              id: alarmId++,
          }));

        // Check if alarms is not empty array
        if(config.length) {
            // Check format and add alarms to array
            for(const al of config || []) {
                const id = al?.id
                const hour = al?.hour
                const minute = al?.minute
                const dayOfWeek = al?.dayOfWeek

                // Check if all params is set
                if(id === undefined || hour === undefined || minute === undefined || dayOfWeek === undefined) continue

                // Add alarm to array
                alarms.push({id, hour, minute, dayOfWeek})
            }
        }

        let dismissImage = undefined;
        let openImage = undefined;
        let backgroundImage = undefined;

        if (alarmType !== 'push') {
            // Dismiss image path
            dismissImage = this.block?.properties?.dismissImageMode === 'animation' ?
              await this._assetBase64(this.block?.properties?.dismissLottie?.source_url):
              await this._assetBase64(this.block?.properties?.dismissImage?.source_url)

            // Open image path
            openImage = this.block?.properties?.openImageMode === 'animation' ?
              await this._assetBase64(this.block?.properties?.openLottie?.source_url):
              await this._assetBase64(this.block?.properties?.openImage?.source_url)

            // Background image
            backgroundImage = this.block?.properties?.backgroundMode === 'image' ?
              await this._assetBase64(this.block?.properties?.backgroundImage?.source_url) :
              this.context.renderer.a2u.getColor(this.block?.properties?.backgroundColor || "primary", "background")
        }

        // Sound file
        // Image path
        const soundPathVal = this.context.getValue(this.block?.properties?.alarmSound)
        const soundPath = typeof soundPathVal === 'object' ? soundPathVal?.source_url : soundPathVal;
        const soundFile = soundPath ? await this._assetBase64(soundPath) : false

        // Postpone title
        const ppTitle = this.context.interpretString(this.block?.properties?.postponeTitle);
        const ppBgColor = this.block?.properties?.postponeBgColor

        // Check for empty images
        if((!dismissImage || !openImage) && alarmType === 'alarm') {
            console.error("Alarm images are not set", this.block)
            return this.processOutgoingLinks(this.context, this.block.id, "Alarm images are not set", "completed");
        }

        // Alarm config
        const alarmConfig = {
            scheduleId: this.block.id,
            alarms: alarms,
            content:  {
                type: alarmType,
                title: this.context.interpretString(this.block?.properties?.title) || 'Alarm',
                subtitle: this.context.interpretString(this.block?.properties?.subTitle) || 'Warning',
                textColor: this.context.renderer.a2u.getColor(this.block?.properties?.foregroundColor || "primary", "foreground"),
                background: {
                    type: this.block?.properties?.backgroundMode || "color",
                    data: backgroundImage
                },
                delayOptions:{
                    title:`${ppTitle}`,
                    buttonStyle:{
                        background:{
                            type: "color", //image/color
                            "data": `${ppBgColor}` //#ffffff/base64image
                        }
                    }
                },
                skipButton: {
                    type: this.block?.properties?.dismissImageMode || "image",
                    data: dismissImage,
                    text: this.context.interpretString(this.block?.properties?.dismissText) || '',
                },
                openButton: {
                    type: this.block?.properties?.openImageMode || "image",
                    data: openImage,
                    text: this.context.interpretString(this.block?.properties?.openText) || '',
                },
                vibration: this.block?.properties?.vibrate ? 1 : 0,
                sound: {
                    enabled: this.block?.properties?.alarmSound ? 1 : 0,
                    id: soundPath?.replace(":", "_") || "test",
                    data: soundFile,
                    repetitions: this.block?.properties?.repetitions || 0,
                    alarmVolume: {
                        minVolume: Math.max(parseInt(this.block?.properties?.minVolume) || 10, 0),
                        maxVolume: Math.min(parseInt(this.block?.properties?.maxVolume) || 100, 100),
                        increasingTime: parseInt(this.block?.properties?.increasingTime) || 0,
                    },
                },
                openUrl: `app://${this.context.interpretString(this.block?.properties?.startEvent) || "start"}`
            }
        }

        try {
            // Get alarm plugin instance
            const alarmManager = this.context.renderer.a2u.getDevice()?.getPlugin?.("Alarm")

            // Cancel old alarms
            alarmManager?.cancelAlarms?.({
                scheduleId: this.block.id
            })

            // Set new alarms
            if(alarmConfig.alarms.length) alarmManager?.setAlarms?.(alarmConfig)
        } catch(ex) {
            console.error("Error in alarm plugin", ex)
        }

        // Process events
        this.processOutgoingLinks(this.context, this.block.id, false, "completed");
    }
}
