import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class AlarmRequestPermissionProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param source
     * @param source_event
     * @param action
     * @param data
     */
    async processEvent() {
        // Get ad instance
        const permManager = this.context.renderer.a2u.getDevice()?.getPlugin?.("Alarm")

        // Check if no Ad manager - debug response
        if(!permManager || (this.context.renderer.a2u.getPlatform() === 'web') ) {
            // Get result
            return this.debugResponse({yes: {}, no: {}});
        }

        // Check permission
        let result

        try {
            // Disallow restore event
            this.context.renderer.a2u.allowRestore(false);
            result = await permManager.requestPermission({
                type: this.block?.properties?.alarmType || 'alarm', // push
            })
        } catch (error) {
            console.error("Request permission error", error);
            result = {granted: false}
        } finally {
            // Allow restore event
            this.context.renderer.a2u.allowRestore(true);
        }

        // Process result
        return this.processOutgoingLinks(this.context, this.block.id, undefined, result?.granted ? 'yes' : 'no');
    }
}
