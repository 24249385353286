<template>

    <span class="block-editor-pe">
        <template v-for="(block) of blocks" :key="block.id">
            <base-component-pe :block="block"/>
        </template>
    </span>

</template>

<script>

import {nanoid} from "nanoid";
import BaseComponentPe from "./components/BaseComponentPe.vue";

export default {
    props: {
        autoFocus: {
            type: Boolean,
            default: false
        },
        blocks: {
            type: Array,
            default: () => []
        },
        edit: {
            type: Boolean,
            default: false
        },
        singleLine: {
            type: Boolean,
            default: false
        }
    },
    components: {BaseComponentPe},
    name: "BlockEditor",
    provide: function () {
        return {
            blockEditor: this
        }
    },
    data: () => ({
        focusedBlockId: false,
        activeBlockEditor: false,
    }),

    mounted() {

        // Focus on first block
        if (this.autoFocus) {
            this.focusBlock(this.blocks[0])
        }

        // Watch edit
        this.$watch("edit", (value) => {
            console.log("Edit changed", value)
            if(value && this.autoFocus) {
                this.focusBlock(this.blocks[0])
            }
        })
    },

    methods: {

        /**
         * Blur block
         * @param bid
         */
        blur(bid) {
            if(this.focusedBlockId === bid) {
                this.focusedBlockId = false
            }
        },

        /**
         * Focus first block
         */
        focusFirst() {
            this.focusBlock(this.blocks[0])
        },

        /**
         * Focus block
         * @param block
         */
        focusBlock(block) {

            // Skip empty blocks
            if(!block) return

            // Set focused block
            this.focusedBlockId = block.id

            // Set active editor
            this.pageEditor.activeBlockEditor = this

        },

        /**
         * Generate id
         * @return {*}
         */
        generateId() {
            return nanoid(10)
        },

        /**
         * Add block
         * @param block
         * @param type
         */
        async addBlock(block, type = "text", focus = true) {

            // Find component index
            const index = !block ? 0 : this.blocks.findIndex(el => el.id === block.id)

            // Exit if component not found or it is last
            if(index === -1) return

            // Add new block
            const newBlock = await this.pageEditor.addBlock(type, index + 1)

            // Focus on new block
            if(focus) this.$nextTick(() => {
                this.focusBlock(newBlock)
            })

            // Return new block
            return newBlock
        },

        /**
         * Delete block
         * @param block
         */
        async deleteBlock(block) {

            // Find component index
            const index = this.blocks.findIndex(el => el.id === block.id)

            // Exit if component not found or it is last
            if(index === -1) return

            // Delete block
            await this.pageEditor.deleteBlock(block.id)

            // Focus on next block
            this.$nextTick(() => {
                if(this.blocks[index]) {
                    this.focusBlock(this.blocks[index])
                } else if(this.blocks[index - 1]) {
                    this.focusBlock(this.blocks[index - 1])
                }
            })
        },
    },
}

</script>

<style lang="scss">

.block-editor-pe {
}

</style>
