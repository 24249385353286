import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * Storage condition processor
 */
export class StorageConditionProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param source
     * @param source_event
     * @param action
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    processEvent(source, source_event, action, data) {

        // Check for debug mode and ask for options
        if(this.debugResponse(Object.fromEntries(this.block.properties?.events?.map(e => [e.name, e.name])))) return

        // Get value from storage
        const value = this.getApplication().storage.get(this.block.properties?.name || '')

        // Some conditions were reached
        let reached = false

        // Fire events according to conditions
        for(const cond of this.block.properties?.events || []) {

            // Get condition value
            const conditionValue = this.block.properties?.conditionValue

            // Perform comparisons
            if(
                (cond.conditionOperation === '' && value === undefined) ||
                (cond.conditionOperation === '=' && value == conditionValue) ||
                (cond.conditionOperation === '!=' && value != conditionValue)
            ) {

                // Condition was reached
                reached = true

                // Fire event
                this.fireEvent(cond.name, data)
            }

        }

        // If no condition was reached - fire event 'else'
        if(!reached) this.fireEvent('else')
    }
}
