import {CodeFunctionProcessor} from 'a2u-renderer-common/src/processors/Logic/Code/CodeFunctionProcessor.js';

/**
 * SignInFunction processor
 */
export class SignInFunctionProcessor extends CodeFunctionProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param event
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    async processEvent(event, data) {

        // Check if debug mode
        if(this.app.renderer.a2u.runMode === "debug") {
            return this.debugResponse({
                result: {},
                error: {message: 'Wrong credentials'},
            });
        }

        const provider = this.context.getValue(this.block?.properties?.provider);

        try {
            // Define user
            let user;

            switch (provider) {
                case 'facebook': // Auth by Facebook
                    user = await this.authByPlugin('Facebook');
                    break;
                case 'google': // Auth by Google
                    user = await this.authByPlugin('Google');
                    break;
                case 'apple': // Auth by Apple
                    user = await this.authByPlugin('Apple');
                    break;
                case 'promo-code': // Auth by Promo Code
                    user = await this.authByPromoCode();
                    break;
                default: // Auth by default
                    user = await this.authDefault();
                    break;
            }

            if (user?.token) {
                if (provider !== 'default') {
                    this.app.app.auth().doLogin(user)
                }

                await this.app.renderer.a2u.socketListener.init();

                await this.updateIapProducts();

                this.processOutgoingLinks(this.context, this.block.id, user, "result");
            } else {
                this.processOutgoingLinks(this.context, this.block.id, user, "error");
            }
        } catch (res) {
            this.processOutgoingLinks(this.context, this.block.id, res, "error");
        }
    }

    /**
     * Authenticates a user using their email and password.
     *
     * This method retrieves the email and password from the block properties,
     * then calls the login method of the auth service with these credentials.
     *
     * @returns {Promise<Object>} A promise that resolves to the user object if the authentication is successful.
     * @throws {Error} If the authentication fails.
     */
    async authDefault() {
        // Get email and password
        const email = this.context.getValue(this.block?.properties?.email);
        const password = this.context.getValue(this.block?.properties?.password);

        // Call the login method of the auth service with the email and password
        return await this.app.app.auth().login(email, password);
    }

    /**
     * Authenticates a user using a specified plugin.
     *
     * This method retrieves the specified plugin from the device,
     * creates a new instance of the plugin, and then calls the login method of the plugin.
     *
     * @param {string} pluginName - The name of the plugin to use for authentication.
     * @returns {Promise<Object>} A promise that resolves to the user object if the authentication is successful.
     * @throws {Error} If the specified plugin is not found.
     */
    async authByPlugin(pluginName) {
        // Get the specified plugin from the device
        const plugin = this.app.renderer.a2u.getDevice().getPlugin(`SocialAuth${pluginName}Plugin`);

        // If the plugin is not found, throw an error
        if (!plugin) {
            throw `${pluginName} auth plugin not found`;
        }

        // Create a new instance of the plugin
        const instance = new plugin(this.app);

        // Call the login method of the plugin
        return await instance.login();
    }

    /**
     * Authenticates a user using a promotional code.
     * This method retrieves the promotional code from the block's properties,
     * finds the corresponding authentication plugin for promo codes, and attempts to log in using the promo code.
     * If the plugin is not found, an error is thrown.
     *
     * @async
     * @returns {Promise<Object>} A promise that resolves to the user object if the authentication is successful.
     * @throws {Error} If the promo code authentication plugin is not found.
     */
    async authByPromoCode() {
        // Retrieve the promo code from the block's properties
        const promoCode = this.context.getValue(this.block?.properties?.promoCode);

        // Attempt to get the promo code authentication plugin
        const plugin = this.app.renderer.a2u.getDevice().getPlugin('AuthPromoCodePlugin');

        // If the plugin is not found, throw an error
        if (!plugin) {
            throw 'Promo code auth plugin not found';
        }

        // Create a new instance of the plugin and attempt to log in with the promo code
        const instance = new plugin(this.app);

        // Return the result of the login attempt
        return await instance.login(promoCode);
    }

    /**
     * Updates and synchronizes in-app purchase (IAP) products.
     * This method attempts to retrieve an instance of the IAPManager plugin. If the plugin is found,
     * it calls the `update` method to refresh the list of products available for purchase and then
     * synchronizes the purchase records with the server by calling `syncPurchases`. If the plugin is not
     * found, the method exits early. Errors during the update or synchronization process are caught and logged.
     */
    async updateIapProducts() {
        try {
            const plugin = await this.context?.renderer?.a2u?.getDevice()?.getPluginInstance?.("IAPManager");

            if (!plugin) {
                return;
            }

            await plugin.update();
            await plugin.syncPurchases();
        } catch (e) {
            console.error('Error updating IAP products', e);
        }
    }
}
