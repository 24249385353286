<template>
  <test-app/>
</template>

<script>

import TestApp from "../test/TestApp";

export default {
  name: 'App',
  components: {
    TestApp,
  },
}

</script>

<style lang="scss">

html, body, #app {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

</style>
