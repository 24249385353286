import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * Check ad consent processor
 */
export class CheckAdConsentProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param event
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    async processEvent(event, data) {

        // Get ad instance
        const adManager = this.context.renderer.a2u.adManager

        // Check if no Ad manager - debug response
        if(!adManager || this.context.renderer.a2u.getPlatform() === 'web') {
            return this.debugResponse({yes: {}, no: {}}, `Check user ad consent`)
        }

        try {
            // Cancel old alarms
            const res = await adManager.checkAdConsent();
            return this.processOutgoingLinks(this.context, this.block.id, false, res ? "yes" : "no");
        } catch (ex) {
            console.log(ex)
            return this.processOutgoingLinks(this.context, this.block.id, ex, "no");
        }
    }
}
