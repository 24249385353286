<template>

  <ab-flow-base-cmp class="select-box-editor-cmp" :block="block" :transparent="true" :class="classesString" :style="stylesString">
    <q-select
        class="full-width"
        v-model="currentValue"
        :multiple="!!multiple"
        :label="label"
        :hint="hint"
        :options="options"
        emit-value
        map-options
        :behavior="behavior"
        :rules="validationRules"
        lazy-rules
        hide-bottom-space
        v-bind="dynamicProps"
    />
  </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "../renderMixins";
import AbFlowBaseCmp from "../Containers/Designer/AbFlowBaseCmp.vue";
import {formComponentsMixins} from "../Form/formComponentsMixins";
import validatableFormFieldMixin from './validatableFormFieldMixin';

export default {
  components: {AbFlowBaseCmp},
  mixins: [renderMixins, formComponentsMixins, validatableFormFieldMixin],
  props: ['block'],
  name: "SelectEditorCmp",
  computed: {
    /**
     * Label
     * @return {string}
     */
    label() {
      return this.block.properties?.label || undefined
    },

    /**
     * Hint
     * @return {string}
     */
    hint() {
      return this.block.properties?.hint || undefined
    },

    /**
     * Multiple value
     * @return {string}
     */
    multiple() {
      return this.block.properties?.multiple || undefined
    },

    /**
     * Computed property that returns the position of extra options.
     * @return {string} The position of extra options. If the `manualOptionsState` property of the block is not defined, it returns 'none'.
     */
    extraOptionsPosition() {
      return this.block.properties?.manualOptionsState || 'none';
    },

    /**
     * Computed property that returns an array of extra options.
     * Each option is an object with `title` and `value` properties.
     * If the `extraOptionsPosition` is 'none', it returns an empty array.
     * If the `manualOptions` property of the block is not defined, it also returns an empty array.
     * @return {Array} An array of extra options.
     */
    extraOptions() {
      return (this.block.properties?.manualOptions?.items || [])
        .map((v) => ({title: v.label, value: v.value}));
    },

    /**
     * Options list
     * @return {*}
     */
    options() {
      // Main options
      const mainOptions = this.getValue(this.block?.properties?.options) || [];

      // Extra options position
      const extraOptsPos = this.extraOptionsPosition;

      // Options
      const opts = [];

      // If extra options should be before main options
      if (extraOptsPos === 'before' && this.extraOptions.length) {
        opts.push(...this.extraOptions);
      }

      // Main options
      opts.push(...mainOptions);

      // If extra options should be after main options
      if (extraOptsPos !== 'before' && this.extraOptions.length) {
        opts.push(...this.extraOptions);
      }

      // return options
      return opts?.map(v => ({label: v.title || v.label || v.name, value: v.value || v.id}))
    },

    /**
     * Behavior property of the block.
     * This computed property returns the behavior property of the block if it exists, otherwise it returns 'default'.
     * @return {string} The behavior property of the block or 'default' if it doesn't exist.
     */
    behavior() {
      return this.block.properties?.behavior || 'default';
    },
  }
}

</script>

<style lang="scss">

.select-box-editor-cmp {
  .q-field {
    height: 100%;

    .q-field__append {
      height: 100%;
      color: inherit;
    }

    .q-field__control {
      color: var(--control-focus-color);
      height: 100%;
      margin-left: 5px;
      margin-right: 5px;

      .q-field__native, .q-field__label {
        color: var(--control-text-color) !important;
      }
    }

    .q-field__messages {
      color: var(--control-text-color);
    }

    &.q-field--error {
      .q-field__messages, .text-negative {
        color: var(--foreground-color-validation-error, #c10015) !important;
      }
    }

    &.q-field--error {
      padding-bottom: 20px;

      .q-field__bottom {
        position: absolute;
      }
    }
  }

  .q-icon {
    color: inherit;
  }
}

</style>
