/**
 * Object containing validation rules and their corresponding validator functions and messages.
 * @type {Object}
 */
const validators = {
  /**
   * Validation rule for required fields.
   * The validator function checks if the value is truthy.
   */
  required: {
    validator: (v) => !!v,
    message: 'Field is required',
  },

  /**
   * Validation rule for email fields.
   * The validator function checks if the value matches the email regex pattern.
   */
  email: {
    validator: (v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v),
    message: 'Invalid email address',
  },

  /**
   * Validation rule for numeric fields.
   * The validator function checks if the value matches the numeric regex pattern.
   */
  numeric: {
    validator: (v) => /^-?[\d]+(\.[\d]+)?$/.test(v),
    message: 'Invalid number',
  },

  /**
   * Validation rule for minimum length fields.
   * The validator function checks if the value is a string and its length is greater than or equal to the specified length.
   */
  minLength: {
    validator: (v, length) => typeof v === 'string' && v.length >= length,
    message: 'The field must be at least {length} characters long',
    arguments: ['length'],
  },

  /**
   * Validation rule for maximum length fields.
   * The validator function checks if the value is a string and its length is less than or equal to the specified length.
   */
  maxLength: {
    validator: (v, length) => typeof v === 'string' && v.length <= length,
    message: 'The field must be at most {length} characters long',
    arguments: ['length'],
  },

  /**
   * Validation rule for date fields.
   * The validator function checks if the value matches the date regex pattern.
   */
  date: {
    validator: (v) => /^-?[\d]+\/[0-1]\d\/[0-3]\d$/.test(v),
    message: 'Invalid date',
  },
};

/**
 * Vue.js mixin for validatable form fields.
 * Provides a computed property related to validation rules.
 */
export default {
  computed: {
    /**
     * Returns an array of validation rules that are not already used in the current block.
     * @returns {Array<Function>}
     */
    validationRules() {
      // Get validation rules from the block properties
      const rules = (this.block?.properties?.validationRules?.items || [])
        .filter((r) => r.rule in validators);

      // If there are no rules, return undefined
      if (!rules.length) {
        return undefined;
      }

      // Map the rules to their corresponding validator functions
      return rules.map((r) => {
        // Get the validator function for the rule
        const validator = validators[r.rule];

        // Get the localized message for the rule
        const message = this.interpretString({
          isLocalizable: true,
          localeAlias: `${this.renderer?.a2u?.moduleId}.validation.errors.${r.rule}`,
          value: validator.message,
        });

        // If the validator function does not require arguments, return the validator function with the message
        if (!validator.arguments) {
          return (v) => validator.validator(v) || message;
        }

        // If the validator function requires arguments, return a function that returns the validator function with the arguments and the message
        const args = validator.arguments.map((arg) => r[arg] || undefined);

        return (v) => validator.validator(v, ...args) || message.replace(/{([^{}]*)}/g, (_, argument) => r[argument]);
      });
    },
  },
}
