import {BaseComponentProcessor} from "../../../utils/processor/BaseComponentProcessor";

/**
 * ListView processor
 */
export class RepeaterProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param source
     * @param source_event
     * @param action
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    processEvent(source, source_event, action, data) {

        // Log
        this.log("info", "RepeaterProcessor.processEvent", source, source_event, action, data)

        // Process event according to action
        /*switch (action) {
            case 'datasource':
                this.block.datasource = data
                this.log("info", "RepeaterProcessor.processEvent", "datasource", this.block.datasource)
                break
        }*/

    }
}
