export const componentMixins = {

    props: ["block"],
    inject: {
        blockEditor: {
            default: null
        },
        pageEditor: {
            default: null
        },
        currentComponent: {
            default: null
        },
    },

    data: () => ({
        blockProps: {},
        nodeBlock: {},
        internalLoad: false
    }),

    async created() {

    },

    computed: {

        /**
         * Get current block value
         * @return {}
         */
        blockValue() {
            return {}
        },

        /**
         * Check if the block is focused
         * @return {boolean}
         */
        isFocused() {
            //console.log(this.blockEditor?.focusedBlockId, this.block.id, this.pageEditor?.activeBlockEditor, this.blockEditor)
            return this.pageEditor?.edit && this.blockEditor?.focusedBlockId === this.block.id && this.pageEditor?.activeBlockEditor === this.blockEditor;
        },

        /**
         * Check if component in editor edit mode
         * @return boolean
         */
        ifEdit() {
            return this.pageEditor?.edit;
        }
    }
}
