import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class RequestAppReviewProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param source
     * @param source_event
     * @param action
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    async processEvent() {

        // Get ad instance
        const rateManager = this.context.renderer.a2u.getDevice()?.getPlugin?.("RateApp")

        // Check if no Ad manager - debug response
        if(!rateManager || (this.context.renderer.a2u.getPlatform() === 'web' && this.app.renderer.a2u.runMode === "debug") ) {
            // Get result
            return this.debugResponse({rated: {}});
        }

        // Check permission
        const result = await rateManager.requestReview()
        console.log("Rate app result", result)
    }
}
