import {BaseComponentProcessor} from "../../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class WebSocketListenerProcessor extends BaseComponentProcessor {
    /**
     * Process incoming event
     */
    async processEvent() {

        throw new Error('Should be re-implemented using single-point solution appParser.socketListener');

        /*
        let registerHandler = this.context?.parentDiagram?.registerHandler;

        // check if parentDiagram is not a function
        if (typeof registerHandler !== 'function') {
            registerHandler = this.context?.renderer?.a2u?.currentDiagramComonent?.registerHandler;
        }

        // check if parentDiagram is not a function
        if (typeof registerHandler !== 'function') {
            console.error('parentDiagram.registerHandler is not a function')

            return;
        }

        // get channel
        const channel = this.block?.properties?.channel || null;

        // register handler
        registerHandler('socket', 'socket-data', (data) => {
            this.processIncomingData(channel, data);

            this.context?.app?.client?.getSocket()?.emit('user-socket-updates:ack', {
                id: data.id,
            });
        });

        // register handler for connect event
        registerHandler('socket', 'connect', async () => {
            await this.loadUserUpdates(channel);
        });

        // register handler for reconnect event
        registerHandler('socket', 'reconnect', async () => {
            await this.loadUserUpdates(channel);
        });

        // register handler for restore event
        registerHandler('app', 'restore', async () => {
            await this.loadUserUpdates(channel);
        });

         */
    }

    /**
     * Processes incoming data for a specific channel.
     * This method filters incoming data based on the specified channel. If the channel parameter is not provided,
     * or if the incoming data's channel matches the specified channel, the data is forwarded to the next step in the
     * processing pipeline using the `processOutgoingLinks` method. This ensures that only relevant data is processed
     * and passed along, based on the channel configuration.
     *
     * @param {string|null} channel - The channel to filter incoming data. If null, all data is processed.
     * @param {Object} data - The incoming data object, which includes the channel and payload.
     */
    processIncomingData(channel, data) {
        if (!channel || data.channel === channel) {
            this.processOutgoingLinks(this.context, this.block.id, {
                id: data.id,
                channel: data.channel,
                ...(data.data || {}),
            }, 'incoming-data');
        }
    }

    /**
     * Asynchronously loads user updates based on a specified channel.
     * This method attempts to fetch and process user-specific updates from a WebSocket or similar real-time data source.
     * It first checks if the user is authenticated. If not, the method returns early. Then, it searches for a module
     * with a defined `socketUrl` within the application's renderer context. If such a module is found, it makes a call
     * to the module's endpoint to retrieve updates for the specified channel. Each update received is then processed
     * by forwarding it, along with the channel information, to the `processIncomingData` method for further handling.
     *
     * The method handles errors by logging them to the console, ensuring that the application can gracefully handle
     * failures in loading user updates.
     *
     * @param {string|null} channel - The channel for which updates are to be loaded. If null, updates for all channels may be loaded.
     */
    async loadUserUpdates(channel) {
        try {
            // Check if the user is authenticated
            if (!this.context.app.auth().getUserId()) {
                return;
            }

            // Find a module with a socket URL
            const socketModule = Object.values(this.context?.renderer?.a2u?.source?.modules || [])
              .find((m) => !!m.socketUrl);

            if (!socketModule) {
                return;
            }

            // Load updates for the specified channel
            const {updates} = await this.context.app.client.call(
              `${socketModule.endpointUrl}/socket-updates`,
              'updates',
              channel,
            );

            if (!updates?.length) {
                return;
            }

            // Process each update
            for (const update of updates) {
                this.processIncomingData(channel, {
                    channel: update.channel,
                    data: update?.payload,
                });
            }
        } catch (e) {
            console.error('Error loading user updates:', e);
        }
    }
}
