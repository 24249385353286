import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";
import {SoundManager} from "../../../renderer/utils/soundManager";

/**
 * Code processor
 */
export class FullscreenAdProcessor extends BaseComponentProcessor {
    static allowExecution = true; // This is a static variable that is used to control the execution of the processEvent method.

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param event
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    processEvent(event, data) {
        // Get params
        const showTimeout = this.block?.properties?.showTimeout || 0
        const waitLoading = this.block?.properties?.waitLoading || false
        const waitTimeout = this.block?.properties?.waitTimeout || 30
        const adType = this.block?.properties?.adType || 'interstitial'

        // Skip key
        const skipKey = 'last-imp-fullscreen-ad';

        // Get ad instance
        const adManager = this.context.renderer.a2u.adManager

        // Get last impression time from internal storage
        const lastImpressionTime = showTimeout ? this.app.renderer.a2u.systemStorage.get(skipKey) || 0 : 0;

        // Check if last impression time is not expired
        if( adType === 'interstitial' && lastImpressionTime && Date.now() - lastImpressionTime < showTimeout * 1000) {
            return this.processOutgoingLinks(this.context, this.block.id, false, "skipped");
        }

        // Check if no Ad manager - debug response
        if(!adManager || this.context.renderer.a2u.getPlatform() === 'web') {
            // Store last impression time to internal storage
            this.app.renderer.a2u.systemStorage.set(skipKey, Date.now())

            // Process outgoing links
            return this.debugResponse({success: {}, error: {}, closed: {}, "no-ads": {}}, `Fullscreen ad`)
        }

        // If execution is allowed - return
        if (!FullscreenAdProcessor.allowExecution) {
            return;
        }

        // Set execution flag
        FullscreenAdProcessor.allowExecution = false;

        try {
            // Show loading
            this.context.$q.loading.show({message: 'Loading...'})

            // Mute all sounds
            SoundManager.getInstance().muteAll(true);

            // Disallow restore event
            this.context.renderer.a2u.allowRestore(false);

            // Cancel old alarms
            adManager.show(this.context, this.block?.properties?.placement, () => {

                // Unmute all sounds
                SoundManager.getInstance().updateState();

                // Hide loading
                this.context.$q.loading.hide()

                // Allow restore event
                this.context.renderer.a2u.allowRestore(true);

                // Store last impression time to internal storage
                this.app.renderer.a2u.systemStorage.set(skipKey, Date.now());

                // Set execution flag
                FullscreenAdProcessor.allowExecution = true;

                // Process outgoing links
                return this.processOutgoingLinks(this.context, this.block.id, false, "success");
            }, (error) => {

                // Unmute all sounds
                SoundManager.getInstance().updateState();

                // Allow restore event
                this.context.renderer.a2u.allowRestore(true);

                // Hide loading
                this.context.$q.loading.hide();

                // Set execution flag
                FullscreenAdProcessor.allowExecution = true;
                return this.processOutgoingLinks(this.context, this.block.id, error?.message, error?.type || 'error')
            }, waitLoading, waitTimeout)
        } catch (ex) {
            // Allow restore event
            this.context.renderer.a2u.allowRestore(true);
            this.context.$q.loading.hide()
            console.log(ex);

            // Set execution flag
            FullscreenAdProcessor.allowExecution = true;
            return this.processOutgoingLinks(this.context, this.block.id, ex, "error");
        }
    }
}
