import TextPe from "./components/ui/TextPe.vue";

export const contentComponentList = [
    {
        title: "Text",
        type: "text",
        grp: "ui",
        value: {content: "..."},
        component: TextPe
    },
]
