<template>
  <div>
    <div v-if="!startDiagram" class="text-subtitle1">
      No start diagram found
    </div>
    <div v-else-if="!startEvents.length" class="text-subtitle1">
      No start events found
    </div>

    <q-card v-else class="q-mb-lg">
      <q-card-section>
        <div class="text-overline" v-text="startDiagram.title" />

        <q-separator class="q-my-md" />

        <div class="flex column">
          <q-radio
            v-for="(event, idx) in startEvents"
            :key="`test-${event.name}-${idx}`"
            v-model="startEvent"
            :val="event.name"
            :label="event.name"
          />
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
export default {
  name: 'StartEvent',

  inject: ['renderer'],

  data() {
    return {
      startEvent: 'start',
    };
  },

  computed: {
    /**
     * The startDiagram computed property returns the start diagram for the renderer's source.
     *
     * @return {Object} The start diagram for the renderer's source.
     */
    startDiagram() {
      return (this.renderer?.source?.diagrams || [])
        .find((diagram) => diagram.id === this.renderer?.source?.startDiagramId);
    },

    /**
     * The startEvents computed property returns the start events for the start diagram.
     * It filters the children of the start diagram to only include nodes of type 'CustomEvent' with an eventType of 'incoming'.
     * It then maps these nodes to an object with the title, name, and eventType properties.
     *
     * @return {Array} The start events for the start diagram.
     */
    startEvents() {
      return (this.startDiagram?.source?.children || [])
        .filter((node) => node.type === 'CustomEvent' && node.properties?.eventType === 'incoming')
        .map((event) => ({
          title: event.title,
          name: event.properties?.name,
          eventType: event.properties?.eventType,
        }));
    },
  },

  watch: {
    /**
     * The startEvent watch handler updates the application's client configuration when the startEvent changes.
     *
     * @param {string} value - The new value of the startEvent.
     */
    startEvent(value) {
      this.app.client.config.set('settings.startevents', value);
    },
  },

  mounted() {
    // Sets the startEvent to the value stored in the application's client configuration.
    this.startEvent = this.app.client.config.get('settings.startevents', 'start');
  },
}
</script>
