<template>

  <ab-flow-base-cmp :block="block" :class="' content-editor-cmp ' + classesString" :style="stylesString">
    <block-editor :blocks="currentValue" :edit="true" :auto-focus="false"/>
  </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "../../renderMixins";
import AbFlowBaseCmp from "../../Containers/Designer/AbFlowBaseCmp.vue";
import BlockEditor from "../ContentEditor/BlockEditor.vue";
import {formComponentsMixins} from "../../Form/formComponentsMixins";

export default {
  components: {BlockEditor, AbFlowBaseCmp},
  mixins: [renderMixins, formComponentsMixins],
  props: ['block'],
  name: "ContentEditorEditorCmp",
  computed: {},
}

</script>

<style lang="scss">
.content-editor-cmp {
  position: relative;

}
</style>
