import {StorageValueManger} from 'a2u-renderer-common/src/utils/storageValueManger';

/**
 * The DiagramProcess class is used to manage the process of a diagram.
 * It uses the StorageValueManager class to handle operations related to storage values.
 */
export class DiagramProcess {
  /**
   * Constructs a new instance of the DiagramProcess class.
   * @param {Object} renderer - The renderer object.
   * @param {Object} storageMeta - The metadata for the storage.
   * @param {Object} dataContext - The context data for the diagram.
   * @param {Object} parentDiagram - The parent diagram for the current diagram.
   */
  constructor({renderer, storageMeta, dataContext, parentDiagram}) {
    this.dataContext = dataContext;
    this.parentDiagram = parentDiagram;
    this.renderer = renderer;

    this.storageValueManager = new StorageValueManger({
      owner: this,
      storageMeta,
      dataContext: this.dataContext,
      parentDiagram: this.parentDiagram,
      valueExtractor: (v) => v?.value,
      translator: this.renderer?.app?.client?.translate,
    });
  }

  /**
   * Gets the value of a variable.
   * @param {string} variable - The variable to get the value of.
   * @param {string} toVariable - The variable to assign the value to.
   * @param {any} input - The input value (optional).
   * @returns {any} The value of the variable.
   */
  getValue(variable, toVariable, input = undefined) {
    return this.storageValueManager.getValue(variable, toVariable, input);
  }

  /**
   * Interprets a string.
   * @param {string} params - The string to interpret.
   * @returns {string} The interpreted string.
   */
  interpretString(params) {
    return this.storageValueManager.interpretString(params);
  }

  /**
   * Sets the value of a variable based on an operation.
   * @param {Object} variableData - The data of the variable.
   * @param {string} operation - The operation to perform.
   * @param {any} value - The value to set.
   * @returns {any} The result of the operation.
   */
  setOperationValue(variableData, operation, value) {
    return this.storageValueManager.setOperationValue(variableData, operation, value);
  }

  /**
   * Sets the value of a variable.
   * @param {Object} variableData - The data of the variable.
   * @param {any} value - The value to set.
   * @returns {any} The set value.
   */
  setValue(variableData, value) {
    return this.storageValueManager.setValue(variableData, value);
  }
}
