import {EventEmitter} from './eventEmitter';

export class ProcessManager {
  /**
   * Constructs a new instance of the ProcessManager class.
   *
   * @param {AppParser} app - The application object.
   */
  constructor({app}) {
    this.app = app;
    this.eventEmitter = new EventEmitter();
  }

  /**
   * This method is used to run a specific diagram in the application.
   * It delegates the task to the 'runDiagram' method of the 'app' object, passing the provided diagram ID and data.
   *
   * @param {Object} owner - The owner of the diagram.
   * @param {string} diagramId - The ID of the diagram to run.
   * @param {Object} data - The data to pass to the 'runDiagram' method.
   * @param {Object} diagramStorage - The storage object for the diagram.
   */
  run(owner, diagramId, data, diagramStorage) {
    this.app.runDiagramProcess(owner, diagramId, data, diagramStorage);
  }

  /**
   * This asynchronous method is used to get the database instance from the application context.
   * It first retrieves the database instance from the application context.
   * If the database instance exists, it executes a SQL query to create a table named 'processes' if it does not already exist.
   * The 'processes' table has three columns: 'id', 'promise_id', and 'data'.
   * The 'id' column is an integer and is the primary key of the table.
   * The 'promise_id' column is a string and stores the ID of the promise.
   * The 'data' column is a text and stores the data associated with the promise.
   * After executing the SQL query, it returns the database instance.
   *
   * @returns {Object} The database instance.
   */
  async getDb() {
    try {
      const db = this?.app?.getDb();

      await db.rawQuery("CREATE TABLE IF NOT EXISTS `processes` (\n" +
        "    `id` INTEGER PRIMARY KEY AUTOINCREMENT,\n" +
        "    `promise_id` VARCHAR(255),\n" +
        "    `data` TEXT\n" +
        ");");

      return db;
    } catch (e) {
      console.error('Error getting db:', e);
    }

    return undefined;
  }

  /**
   * Creates a new promise for a given chat ID, block ID and event.
   *
   * @param {string} promiseId - The ID of the chat.
   * @param {string} blockId - The ID of the block.
   * @param {Object} event - The event object.
   * @returns {Promise<string|undefined>} The ID of the created promise.
   */
  async createPromise(promiseId, blockId, event) {
    const db = await this.getDb();

    if (!db) {
      return undefined;
    }

    try {
      await db.rawQuery("INSERT INTO `processes` (`promise_id`, `data`) VALUES (?, ?)", [
        promiseId,
        JSON.stringify({blockId, event}),
      ]);

      return promiseId;
    } catch (e) {
      console.error('Error creating promise:', e);
    }

    return undefined;
  }

  /**
   * This asynchronous method is used to find a promise in the 'processes' table of the database.
   * It first retrieves the database instance from the application context.
   * If the database instance exists, it executes a SQL query to select the promise with the provided promise ID from the 'processes' table.
   * If the promise exists, it returns the promise.
   * If the promise does not exist, it logs a warning and returns undefined.
   * If an error occurs during the execution of the SQL query, it logs the error and returns undefined.
   *
   * @param {string} promiseId - The ID of the promise to find.
   * @returns {Promise<Object|undefined>} The promise if it exists, otherwise undefined.
   */
  async findPromise(promiseId) {
    const db = await this.getDb();

    if (!db) {
      return undefined;
    }

    try {
      const result = await db.rawQuery('SELECT * FROM `processes` WHERE `promise_id`=?', [promiseId]);

      const promise = typeof result === 'object' ? result[0] : undefined;

      if (!promise) {
        return undefined;
      }

      return promise;
    } catch (e) {
      console.error('Error finding promise:', e);
    }

    return undefined;
  }

  /**
   * This asynchronous method is used to check if a promise exists in the 'processes' table of the database.
   * It calls the 'findPromise' method with the provided promise ID.
   * If the promise exists, it returns true.
   * If the promise does not exist, it returns false.
   *
   * @param {string} promiseId - The ID of the promise to check.
   * @returns {Promise<boolean>} True if the promise exists, otherwise false.
   */
  async hasPromise(promiseId) {
    return !!await this.findPromise(promiseId);
  }

  /**
   * Resolves a promise for a given promise ID and data.
   *
   * @param {Object} owner - The owner of the diagram.
   * @param {string} promiseId - The ID of the promise.
   * @param {Object} data - The data to resolve the promise with.
   * @param {Object} diagramStorage - The storage object for the diagram.
   */
  async resolvePromise(owner, promiseId, data, diagramStorage) {
    const db = await this.getDb();

    if (!db) {
      return undefined;
    }

    const promise = await this.findPromise(promiseId);

    if (!promise) {
      console.warn(`Promise not found: ${promiseId}`);

      return;
    }

    try {
      await db.rawQuery('DELETE FROM `processes` WHERE `promise_id`=?', [promiseId]);

      const promiseData = JSON.parse(promise.data);

      this.app.restoreEvent(owner, promiseData.blockId, promiseData.event, data, diagramStorage);
    } catch (e) {
      console.error('Error resolving promise:', e);
    }
  }
}
