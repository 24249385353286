/**
 * Mobile device helper
 */
export class Device {

    static instance = null;

    /**
     * Create device class
     * @param a2u
     * @param plugins
     */
    constructor(a2u, plugins) {

        // Store a2u
        this.a2u = a2u
        this.plugins = plugins || {}

    }

    /**
     * Get instance
     * @return {null}
     */
    static getInstance(a2u) {
        if(!this.instance) this.instance = new Device(a2u);
        return this.instance;
    }

    /**
     * Destroy
     * @return {Promise<void>}
     */
    static async destroy() {

    }


    /**
     * Get plugin by name
     * @param pluginName
     */
    getPlugin(pluginName) {
        return this.plugins[pluginName];
    }

    /**
     * Get platform
     * @return {*}
     */
    getPlatform() {
        return this.a2u.getPlatform()
    }


    /**
     * Get plugin instance
     * @param pluginName
     * @return {boolean}
     */
    getPluginInstance(pluginName) {
        const plClass = this.plugins[pluginName];
        return plClass?.getInstance(this)
    }
}
