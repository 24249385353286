
<template>
    <ButtonEditorCmp :block="block" type="submit"/>
</template>

<script>
import ButtonEditorCmp from "../Basic/Button/ButtonEditorCmp.vue";
import {formComponentsMixins} from "../Form/formComponentsMixins";

export default {
    mixins: [formComponentsMixins],
    name: "SubmitEditorCmp",
    props: ['block'],
    components: {ButtonEditorCmp},
}

</script>
