import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * LogoutFunction processor
 */
export class LogoutFunctionProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param source
     * @param source_event
     * @param action
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    processEvent(source, source_event, action, {email, password}) {

        // Debug response
        if(this.debugResponse({success:{}, error: {}})) return

        // Logout
        this.getParentApplication().app.auth().logout()
        this.fireEvent('success')
    }
}
