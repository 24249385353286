<template>
  <ab-flow-base-cmp :block="block" class="lottie-editor-cmp" :class="classesString" :style="stylesString">
    <q-spinner-tail v-if="!source_data && !source_url" class="q-mx-auto q-my-auto" color="primary" size="2em" />
    <LottiePlayer
      v-else
      :key="keyNumber"
      :animation-url="source_url"
      :animation-data="source_data"
      :loop="isLottieLoop"
    />
  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "../../Containers/Designer/AbFlowBaseCmp.vue";
import {renderMixins} from "../../renderMixins";
import LottiePlayer from './LottiePlayer.vue';

export default {
  mixins: [renderMixins],
  components: {AbFlowBaseCmp, LottiePlayer},
  props: ['block'],
  name: "LottieEditorCmp",
  data() {
    return {
      keyNumber: 0,
    };
  },

  computed: {
    /**
     * lottie source
     * @return {string}
     */
    lottie_source() {
      // Get path
      return this.renderer.a2u.assetPath(this.getValue(this.block?.properties?.lottie))
    },

    /**
     * lottie source url
     * @return {string|undefined}
     */
    source_url() {
      return typeof this.lottie_source === 'string' ? this.lottie_source : undefined
    },

    /**
     * lottie source data
     * @return {string|undefined}
     */
    source_data() {
      return typeof this.lottie_source === 'object' ? this.lottie_source : undefined
    },

    /**
     * Is lottie loop
     * @return {boolean}
     */
    isLottieLoop() {
      return this.block.properties?.loop === 1 || false;
    },
  },

  watch: {
    lottie_source() {
      this.keyNumber++;
    },
  },
}

</script>

<style lang="scss">

.lottie-editor-cmp {
  display: flex;
  z-index: 0;
}

</style>
