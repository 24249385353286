import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * RegularDialog processor
 */
export class RegularDialogProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param event
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    processEvent(event, data) {

        // Get props
        const mode = this.block.properties?.mode;
        const title = this.context.interpretString(this.block.properties?.title);
        const message = this.context.interpretString(this.block.properties?.message);
        const confirmLabel = this.context.interpretString(this.block.properties?.confirmLabel);
        const cancelLabel = this.context.interpretString(this.block.properties?.cancelLabel);

        this.context.$q.dialog({
            title: title,
            message: message,
            persistent: true,
            prompt: mode === 'prompt' ? {
                model: '',
                type: 'text',
            } : undefined,
            ok: {
                label: confirmLabel,
            },
            cancel: mode === 'confirm' ? {
                label: cancelLabel,
            } : undefined,
        }).onOk((result) => {

                // Get result
                const resultValue = mode === 'prompt' ? result : true;

                // Return result event
                this.processOutgoingLinks(this.context, this.block.id, resultValue, 'ok');
        }).onCancel(() => {
            // Return result event
            this.processOutgoingLinks(this.context, this.block.id, false, 'cancel');
        });
    }
}
