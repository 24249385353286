<template>
  <ab-flow-base-cmp :block="block" class="carousel-editor-cmp relative-position" :class="classesString" :style="stylesString">
    <q-carousel
        v-model="currentSlide"
        animated
        :arrows="hasArrows"
        :navigation="hasNavigation"
        :autoplay="slideInterval"
        swipeable
        infinite
        class="full-width full-height"
    >
      <q-carousel-slide v-for="(s, k) of slides" :name="k" class="column col" :key="k">
        <fragment-renderer-cmp :block="s"/>
      </q-carousel-slide>

    </q-carousel>
  </ab-flow-base-cmp>
</template>

<script>

import {renderMixins} from "../../renderMixins";
import AbFlowBaseCmp from "../../Containers/Designer/AbFlowBaseCmp.vue";
import FragmentRendererCmp from "../../Containers/Fragment/FragmentRendererCmp.vue";

export default {
  components: {FragmentRendererCmp, AbFlowBaseCmp},
  mixins: [renderMixins],
  inject: {
    canvas: {
      default: false
    },
    animation_frame: {
      default: 0
    }
  },
  data: () => ({
    currentSlide: 0
  }),
  props: ['block'],
  name: "CarouselEditorCmp",
  computed: {

    /**
     * Slide interval
     * @return {number|number}
     */
    slideInterval() {
      return this.block?.properties?.isAutoSlide === 1 ? this.block?.properties?.autoSlideInterval * 1000 : 0
    },

    /**
     * Has arrows
     * @return {boolean}
     */
    hasArrows() {
      return this.block?.properties?.hasArrows === 1 || false
    },

    /**
     * Has navigation
     * @return {boolean}
     */
    hasNavigation() {
      return this.block?.properties?.hasNavigation === 1 || false
    },

    /**
     * Get slides list
     * @return {*}
     */
    slides() {

      // Fragments
      const frs = []

      // Get slides list
      const slides = this.renderer.a2u.links.outgoing[this.block.id].children?.map(l => l.target)

      // Get slides
      for(const sl of slides) {
        const fr = this.renderer.a2u.blocks[sl].node
        if(fr) frs.push(fr)
      }

      // Get parent widget
      return frs
    }
  }
}

</script>

<style lang="scss">

.carousel-editor-cmp {
  .q-carousel__slide {
    padding: 0;
  }

  .q-carousel__navigation-icon, .q-carousel__arrow {
    color: var(--foreground-color-primary);
  }
}

</style>
