export class AdManager {

    // Supported providers
    static instance = null;

    /**
     * Ad config
     * config example: {providers: [{type: "AdmobAdapter", config: {placements: [{name, type, [id]}]}}, {...}], placements: [{name, type}]}
     * @param a2u
     * @param providers
     */
    constructor(a2u) {
        this.placements = {}
        this.providers = {}
        this.a2u = a2u
        this.isTesting = this.a2u.runMode !== 'release'
        this.defaultTimeout = 60
        this.inPreloading = false
        this.bottomBannerCanBeShown = false
    }

    /**
     * Init ads
     * @return {Promise<void>}
     */
    async init(config) {

        // Store config
        this.config = config

        // Init providers
        for(const [name, config] of Object.entries(this.config.providers)) {

            try {
                // Create provider
                const prClass = this.a2u.getDevice().getPlugin(name.charAt(0).toUpperCase() + name.slice(1) + "Adapter");
                if (!prClass) {
                    this.log(`Provider ${name} not found`)
                    continue;
                }

                // Init provider
                const provider = await (new prClass(this, config)).init({ debug: this.isTesting })

                // Store provider to map
                if (provider) {
                    this.providers[name] = provider;
                } else {
                    this.log(`Provider ${name} init error`, config)
                }
            } catch (ex) {
                this.log(`Provider ${name} init error`, ex)
            }
        }

        // Set placements initial status
        for (const placement of Object.values(this.config.placements)) {
            this.setPlacementStatus(placement.name, 'init', null)
        }

        // Start ad loading cycle
        this.preloadAds()

        // All ok
        return true;
    }

    /**
     * Get default provider
     * @return {unknown}
     */
    getDefaultProvider() {
        return Object.values(this.providers || [])[0]
    }

    /**
     * Check if user has ad consent
     * @return {boolean|*|boolean}
     */
    async checkAdConsent() {

        // Get default provider and ask for consent
        const provider = this.getDefaultProvider()

        // Check if no provider - no necessary to ask
        if(!provider) return true;

        // Check if no consent
        return await provider.checkAdConsent()
    }

    /**
     * Show consent dialog
     * @return {boolean|*|boolean}
     */
    async showConsentDialog() {
        // Get default provider and ask for consent
        const provider = this.getDefaultProvider()

        // Check if no provider - no necessary to ask
        if(!provider) return true;

        // Check if no consent
        const res = await provider.showConsentDialog()

        // Set placements to status "init"
        for (const placement of Object.values(this.config.placements)) {
            this.setPlacementStatus(placement.name, 'init', null)
        }

        // Start preloading
        this.preloadAds()

        // Return consent result
        return res;
    }

    /**
     * Preload ads
     */
    async preloadAds() {

        // Preload ads only if we have consent on ads
        const hasConsent = await this.checkAdConsent();

        // Process all placements
        for (const placement of Object.values(this.config.placements)) {

            // Set status to "no consent" if no consent
            if(!hasConsent) {
                this.setPlacementStatus(placement.name, 'no-consent', null)
                continue;
            }

            // Check if placement not loaded yet
            if (['loaded', 'loading'].includes(this.placements?.[placement.name]?.status)) continue

            // Requests
            const requests = []

            // Query each provider with such placement
            for (const pr of Object.values(this.providers)) {
                // Query placement
                const adRequest = await pr.requestPlacement(placement.name)
                if (adRequest) requests.push(adRequest)
            }

            // Sort by cpm
            requests.sort((a, b) => b.cpm - a.cpm)

            // Trying to load ad from the first provider
            for (const adRequest of requests) {

                try {

                    // Loading first ad
                    this.setPlacementStatus(placement.name, 'loading', null)

                    // Load ad
                    adRequest.load().then(ad => {
                        // Set status
                        if (ad && !ad.error) {
                            this.setPlacementStatus(placement.name, 'loaded', ad)
                        } else {
                            this.setPlacementStatus(placement.name, ad?.error || 'error', null)
                        }
                    }).catch(ex => {
                        this.setPlacementStatus(placement.name, 'error', null)
                        this.log('Error in preload process', ex)
                    })

                } catch (ex) {
                    this.setPlacementStatus(placement.name, 'error', null)
                    this.log('Error in preload process', ex)
                }
            }
        }
    }

    /**
     * Set placement status
     * @param placementName
     * @param status
     * @param ad
     */
    setPlacementStatus(placementName, status, ad) {
        if(!this.placements[placementName]) this.placements[placementName] = {}
        this.placements[placementName].status = status;
        this.placements[placementName].previousAd = this.placements[placementName].ad;
        this.placements[placementName].ad = ad;
        this.log(`Placement ${placementName} status changed to ${status}`)
    }

    /**
     * Show ad
     * @param context
     * @param placementId
     * @param waitForLoading
     * @param timeout
     * @param success
     * @param error
     */
    async show(context, placementId, success, error, waitForLoading = false, timeout = 30) {

        // Getting placement by id
        const placement = this.config.placements[placementId]
        if(!placement) {
            this.log(`Placement ${placementId} not found`, this.config.placements)
            error({type: "error", message: `Placement ${placementId} not found`} )
            return false;
        }

        // Check if placement loaded - show it
        if(this.placements[placement.name]?.ad) {

            // Check if ad type is banner and banner already shown
            if(placement.type === 'banner' && !this.bottomBannerCanBeShown) {
                // Skip showing banner
                error({type: "cant-be-shown"})
                this.log(`Skip showing banner ${placementId}`)
                return;
            }

            // Show ad and preload next
            this.placements[placement.name].ad.show(context).then(async (result) => {
                this.log(`Placement ${placement.name} shown, result:`, result)
                success()

                // If not banner - preload next
                if(placement.type !== 'banner') {

                    // Store last show time to placement
                    this.placements[placement.name].lastShowTime = Date.now()

                    // Reset status
                    this.setPlacementStatus(placement.name, 'shown', null)

                    // Preload ads
                    this.preloadAds()
                }
            }).catch(async err => {
                console.error(`Placement ${placement.name} show error:`, err)
                error({type: (err?.data?.errorCode === 1010 ? "closed" : "error"), message: err.message} )
                this.setPlacementStatus(placement.name, (err?.data?.errorCode === 1010 ? "shown" : "error"), null)
                this.preloadAds()
            })
        } else if (waitForLoading) {

            // Preload ads
            this.preloadAds();

            // Check if ad loaded each 300 ms
            const startTime = Date.now()
            const interval = setInterval(() => {

                // Check if ad loaded
                if(this.placements[placement.name]?.status === 'loaded') {
                    clearInterval(interval)
                    this.show(context, placementId, success, error)
                } else if(Date.now() - startTime > timeout * 1000) {
                    // Timeout reached
                    clearInterval(interval)
                    error({type: "error", message: `Timeout ${timeout} sec`})
                } else if(this.placements[placement.name]?.status === 'no-ads') {
                    // Preloading finished, but no ad loaded
                    clearInterval(interval)
                    error({type: "no-ads"})
                } else if(!['loading', 'init'].includes(this.placements[placement.name]?.status)) {
                    // Preloading finished, but no ad loaded
                    clearInterval(interval)
                    error({type: "error", message: this.placements[placement.name]?.status})
                }
            }, 300)

        } else {
            error({type: "error", message: `ad ads`} )
            this.preloadAds()
        }
    }

    /**
     * Show banner
     * @param context
     * @param placementId
     * @param success
     * @param error
     * @param waitForLoading
     * @param timeout
     * @return {Promise<boolean|undefined>}
     */
    showBanner(context, placementId, success, error, waitForLoading = false, timeout = 30) {

        // Shown
        this.bottomBannerCanBeShown = true

        // Show banner
        this.log(`Show banner ${placementId}`)

        // Just regular show
        return this.show(context, placementId, success, error, waitForLoading, timeout)
    }

    /**
     * Hide banner
     * @param placementId
     */
    hideBanner(placementId) {

        // Hidden
        this.bottomBannerCanBeShown = false

        // Show banner
        this.log(`Hide banner ${placementId}`)

        // Getting placement by id
        const placement = this.config.placements[placementId]
        if(!placement) {
            this.log(`Placement ${placementId} not found`, this.config.placements)
            return false;
        }

        // Hide banner
        if(this.placements[placement.name]?.ad) {
            // Show ad and preload next
            this.placements[placement.name].ad.hide();
        } else {
            this.log(`Placement ${placement.name} not found, nothing to hide`)
        }
    }

    /**
     * Log
     */
    log(...props) {
        console.log("AD-MANAGER:", ...props)
    }

    /**
     * Check privacy options status
     * @return {boolean}
     */
    async checkPrivacyOptions() {

        // Get default provider and ask for consent
        const provider = this.getDefaultProvider()

        // Check if no provider - no necessary to ask
        if(!provider) return true;

        // Check if no consent
        return await provider.checkPrivacyOptions()
    }

    /**
     * Show privacy options dialog
     * @return {boolean}
     */
    async showPrivacyOptions() {

        // Get default provider and ask for consent
        const provider = this.getDefaultProvider()

        // Check if no provider - no necessary to ask
        if(!provider) return true;

        // Check if no consent
        const res = await provider.showPrivacyOptions()

        // Set placements to status "init"
        for (const placement of Object.values(this.config.placements)) {
            this.setPlacementStatus(placement.name, 'init', null)
        }

        // Start preloading
        this.preloadAds()

        // Return status
        return res;
    }

    /**
     * This method is used to get a native ad from the 'NativeAds' provider.
     *
     * @param {string} placementId
     * @param {number} timeout
     * @return {Promise<object|null>}
     */
    async getNativeAd(placementId, timeout = 30) {
        // Check if we are in testing mode and return a test ad
        if (this.isTesting) {
            return {
                id: 1,
                title: 'Title',
                description: 'Description',
                image: 'https://placekitten.com/200',
                cta: 'Call to action',
                click_url: 'https://example.com'
            };
        }

        // Getting placement by id
        const placement = this.config.placements[placementId]
        if(!placement) {
            this.log(`Placement ${placementId} not found`, this.config.placements)

            return null;
        }

        const context = this;

        return new Promise((success, error) => {
            // Preload ads
            context.preloadAds();

            // Check if ad loaded each 300 ms
            const startTime = Date.now()
            const interval = setInterval(() => {
                // Check if ad loaded
                if(context.placements[placement.name]?.status === 'loaded') {
                    clearInterval(interval);

                    success(context.placements[placement.name]?.ad?.adinfo);
                } else if(Date.now() - startTime > timeout * 1000) {
                    // Timeout reached
                    clearInterval(interval)
                    error({type: "error", message: `Timeout ${timeout} sec`})
                } else if(context.placements[placement.name]?.status === 'no-ads') {
                    // Preloading finished, but no ad loaded
                    clearInterval(interval)
                    error({type: "no-ads"})
                } else if(!['loading', 'init'].includes(context.placements[placement.name]?.status)) {
                    // Preloading finished, but no ad loaded
                    clearInterval(interval)
                    error({type: "error", message: context.placements[placement.name]?.status})
                }
            }, 300);
        });
    }
}
