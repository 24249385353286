import {nanoid} from "nanoid";
import axios from 'axios';
import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class ImagePickerProcessor extends BaseComponentProcessor {
    async processEvent() {
        // Check if the run mode is debug
        if(this.app.renderer.a2u.runMode === "debug") {
            return this.debugResponse({
                result: 'https://picsum.photos/100',
                cancel: {},
                error: {},
            });
        }

        try {
            // Get camera plugin
            const CapacitorCamera = this.context.renderer.a2u.getDevice()?.getPlugin?.("CapacitorCamera");

            const quality = parseInt(this.block?.properties?.quality || 90);
            const maxWidth = parseInt(this.block?.properties?.maxWidth);
            const maxHeight = parseInt(this.block?.properties?.maxHeight);
            const uploadToServer = this.block?.properties?.uploadToServer === 1;

            const options = {
                resultType: 'uri',
            };

            if (quality) {
                options.quality = Math.min(Math.max(quality, 0), 100);
            }

            if (maxWidth) {
                options.width = maxWidth;
            }

            if (maxHeight) {
                options.height = maxHeight;
            }
            // Disallow restore event
            this.context.renderer.a2u.allowRestore(false);

            // Retrieve image
            const result = await CapacitorCamera.getPhoto(options);

            const webPath = uploadToServer ? await this.saveOnServer(result) : await this.saveOnFileSystem(result);

            // Check if no web path
            if (!webPath) {
                return;
            }

            // Variable to save
            const varToSave = this.block.properties?.variableToSave;

            // Save result to variable
            if (varToSave) {
                this.context.setOperationValue(varToSave, 'set', {
                    valueType: 'static',
                    value: webPath,
                });
            }

            // Process result
            this.processOutgoingLinks(this.context, this.block.id, webPath, 'result');
        } catch (e) {
            console.error('ImagePickerProcessor error:', e);

            // Process error
            if ((e?.message || e || '').includes('cancel')) {
                this.processOutgoingLinks(this.context, this.block.id, null, 'cancel');
            } else {
                this.processOutgoingLinks(this.context, this.block.id, null, 'error');
            }
        } finally {
            // Allow restore event
            this.context.renderer.a2u.allowRestore(true);
        }
    }

    /**
     * Saves the image on the server.
     *
     * This method first retrieves the module ID from the block properties.
     * It then checks if the module ID exists in the source modules and retrieves the endpoint URL.
     * If the endpoint URL does not exist, it throws an error.
     * It then generates a filename for the image using the nanoid function and the format of the image.
     * It fetches the blob of the image from the web path and creates a new File object with the blob, the filename, and the type of the blob.
     * It then uploads the file to the server using the upload method of the client and the endpoint URL.
     * Finally, it returns a string that includes the response from the server.
     *
     * @async
     * @param {Object} data - The data of the image to save.
     * @param {string} data.format - The format of the image.
     * @param {string} data.webPath - The web path of the image.
     * @returns {Promise<object>} A promise that resolves to a string that includes the response from the server.
     * @throws {Error} Throws an error if the endpoint URL does not exist.
     */
    async saveOnServer(data) {
        // Get module ID
        const moduleId = this.block?.properties?.uploadModuleId;

        // Get upload URL
        const uploadUrl = moduleId ? this.context?.renderer?.a2u?.source?.modules[moduleId]?.endpointUrl : false;

        // Check if upload URL exists
        if (!uploadUrl) {
            throw new Error('Upload module not found');
        }

        // Generate filename
        const filename = `${nanoid(10)}.${data.format}`;

        // Fetch blob
        const blob = await this._fetchBlob(data.webPath);

        // Create file
        const file = new File([blob], filename, { type: blob.type });

        // Upload file
        const res = await this.context.app.client.upload(`images/${nanoid(10)}.png`, file, uploadUrl);

        // Return response
        return {
            source_url: `storage:${res}`,
            type: "file",
            moduleId: moduleId,
            title: filename,
        };
    }

    /**
     * Saves the image on the file system.
     *
     * This method retrieves the web path of the image from the provided data.
     * It then calls the 'saveLocally' method of the 'a2u' object of the renderer context with the web path.
     * The 'saveLocally' method is expected to save the image on the file system and return a promise that resolves to the path of the saved image.
     * This method returns the promise from the 'saveLocally' method.
     *
     * @async
     * @param {Object} data - The data of the image to save.
     * @param {string} data.webPath - The web path of the image.
     * @returns {Promise<string>} A promise that resolves to the path of the saved image.
     */
    async saveOnFileSystem(data) {
        return await this.context.renderer.a2u.saveLocally(data.webPath);
    }

    /**
     * Fetches a blob from a given URL.
     *
     * This method sends a GET request to the provided URL with the response type set to 'blob'.
     * It then returns the data from the response.
     *
     * @async
     * @param {string} blobUrl - The URL to fetch the blob from.
     * @returns {Promise<Blob>} A promise that resolves to the blob data from the response.
     * @throws {Error} Throws an error if the request fails.
     */
    async _fetchBlob(blobUrl) {
        const {data} = await axios.get(blobUrl, { responseType: 'blob' });

        return data;
    }
}
