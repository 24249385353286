import {AbStorage} from "a2u-renderer-common/src/utils/abStorage";
import {reactive} from 'vue';

export const getConfig = async (fileName = "settings.json") => {

    // Create storage
    const storage = new AbStorage({
        load: async () => {
            try {
                return JSON.parse(localStorage.getItem(fileName, "{}"))
            } catch (ex) {
                return {}
            }
        },
        save: (data) => {

            // Data to save
            const saveData = {
                settings: data?.settings || {}
            }
            localStorage.setItem(fileName, JSON.stringify(saveData))
        },
        storageCreator: (data) => reactive(data),
    });

    // Load storage
    await storage.loadStorage()

    // Return storage
    return storage
}
