/**
 * Class representing a database storage utility.
 */
export class DbStorage {
  /**
   * Saves data to the database.
   * @param {string} dbName - The name of the database.
   * @param {Object} data - The data to be saved.
   * @returns {Promise<void>}
   */
  static async saveDb(dbName, data) {
    try {
      await this.saveDbToIndexedDB(dbName, data);
    } catch (e) {
      console.error("Error saving db to indexedDB", e);
    }
  }

  /**
   * Retrieves data from the database.
   * @param {string} dbName - The name of the database.
   * @returns {Promise<Object|boolean>} - The retrieved data or false if not found.
   */
  static async getDb(dbName) {
    try {
      return await this.getDbFromIndexedDb(dbName) || false;
    } catch (e) {
      console.error("Error getting db from indexedDB", e);
    }

    return false;
  }

  /**
   * Opens a connection to the database.
   * @param {string} dbName - The name of the database.
   * @returns {Promise<IDBDatabase>} - The opened database.
   */
  static openDatabase(dbName) {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName, 1);

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('database')) {
          db.createObjectStore('database', { keyPath: 'name' });
        }
      };

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  }

  /**
   * Saves data to IndexedDB.
   * @param {string} dbName - The name of the database.
   * @param {Object} data - The data to be saved.
   * @returns {Promise<void>}
   */
  static async saveDbToIndexedDB(dbName, data) {
    const db = await DbStorage.openDatabase(dbName);

    return new Promise((resolve, reject) => {
      const transaction = db.transaction(["database"], "readwrite");
      const objectStore = transaction.objectStore("database");

      const saveRequest = objectStore.put({ name: dbName, data: data });

      saveRequest.onerror = (event) => reject("Save error: " + event.target.error);
      saveRequest.onsuccess = () => resolve();
    });
  }

  /**
   * Retrieves data from IndexedDB.
   * @param {string} dbName - The name of the database.
   * @returns {Promise<Object|null>} - The retrieved data or null if not found.
   */
  static async getDbFromIndexedDb(dbName) {
    const db = await DbStorage.openDatabase(dbName);

    return new Promise((resolve, reject) => {
      const transaction = db.transaction(['database'], 'readonly');
      const store = transaction.objectStore('database');

      const request = store.get(dbName);

      request.onsuccess = function(event) {
        if (event.target.result) {
          resolve(event.target.result.data);
        } else {
          resolve(null); // If no data found
        }
      };

      request.onerror = function(event) {
        reject(event.target.error);
      };
    });
  }
}
