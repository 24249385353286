import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";
import {SoundManager} from "../../utils/soundManager";

/**
 * Sound Manager Processor
 */
export class SoundManagerProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param event
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    processEvent(event, data) {

        // Get ad params
        const soundChannel = this.block.properties?.soundChannel;
        const operation = this.block.properties?.operation
        const volume = this.app.getValue(this.block.properties?.muted) || 50
        const muted = this.app.getValue(this.block.properties?.muted)

        switch(operation) {
            case 'volume':
                SoundManager.getInstance().setVolume(soundChannel, volume);
                break;
            case 'mute':
                SoundManager.getInstance().muteChannel(soundChannel, muted);
                break;
        }
    }
}
