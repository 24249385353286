<template>
    <div class="base-cmp-pe" :class="{focused: isFocused}" @click="focusBlock">
        <component class="component-pe" :is="getComponent()" :block="block"/>
        <div class="base-cmp-pe-manage"  v-if="isFocused" >
            <component-menu-pe ref="menu" :block="block"/>
        </div>

    </div>
</template>

<script>

import TextPe from "./ui/TextPe.vue";
import {contentComponentList} from "../contentComponentList";
import ComponentMenuPe from "../ComponentMenuPe.vue";
import {componentMixins} from "../componentMixins";

export default {
    mixins: [componentMixins],
    inject: ['blockEditor'],
    provide: function () {
        return {
            currentComponent: this
        }
    },
    name: "BaseComponentPe",
    components: {ComponentMenuPe, TextPe},
    props: ["block"],
    methods: {

        /**
         * Get component
         * @return {{component: {components: {ContentEditablePe: {emits: string[], data: function(): {value: string, currentValue: string}, computed: {hasContent(): boolean}, methods: {getSelection(): (Range|null), setValue(*): void, processKeyDown(*): void}, name: string, inject: [string], mounted(): void, props: string[]}}, name: string, inject: [string], props: [string]}, title: string} | {component: {components: {ContentEditablePe: {emits: string[], data: function(): {value: string, currentValue: string}, computed: {hasContent(): boolean}, methods: {getSelection(): (Range|null), setValue(*): void, processKeyDown(*): void}, name: string, inject: [string], mounted(): void, props: string[]}}, name: string, inject: [string], props: [string]}, title: string}}
         */
        getComponent() {
            return contentComponentList.find(el => el.type === this.block.type)?.component
        },

        /**
         * Focus current block
         */
        focusBlock() {
            if(this.pageEditor.edit) this.blockEditor.focusBlock(this.block)
        },

        /**
         * Open block menu
         */
        openMenu() {
            this.$refs.menu.show()
        }
    }
}

</script>

<style lang="scss">

.base-cmp-pe {
    min-height: 1em;
    min-width: 1em;
    display: inline;
}

.base-cmp-pe-manage {
    visibility: hidden;
    width: 20px;
    display: inline;
}

.base-cmp-pe.focused {

    .component-pe {
        //box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14), 0 1px 10px rgba(0, 0, 0, 0.12);
        //border-right:5px solid #eee;
        //background: #f5f5f5;
    }

    .base-cmp-pe-manage {
        visibility: visible;
    }
}

.base-cmp-pe:hover {
    .base-cmp-pe-manage {
        visibility: visible;
    }
}

</style>
