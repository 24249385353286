import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * Close modal processor
 */
export class CloseModalProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param event
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    processEvent() {

        // Reset modals
        this.context.currentDiagram?.hideModals?.();

        // Return result event
        this.processOutgoingLinks(this.context, this.block.id, false, 'closed');
    }
}
