import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * SignInFunction processor
 */
export class SignUpFunctionProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param source
     * @param source_event
     * @param action
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    async processEvent(source, source_event) {

        // Check if debug mode
        if(this.app.renderer.a2u.runMode === "debug") {
            return this.debugResponse({
                success: {},
                'user-exists': {},
                error: {message: 'Wrong credentials'},
            });
        }

        // Get auth provider
        const provider = this.context.getValue(this.block?.properties?.provider);

        try {
            let result;

            switch (provider) {
                case 'facebook': // Sign in with Facebook
                    result = await this.authByPlugin('Facebook');
                    break;
                case 'google': // Sign in with Google
                    result = await this.authByPlugin('Google');
                    break;
                case 'apple': // Sign in with Apple
                    result = await this.authByPlugin('Apple');
                    break;
                case 'promo-code': // Sign in with Promo Code
                    result = await this.authByPromoCode();
                    break;
                default: // Default sign in
                    result = await this.authDefault();
                    break;
            }

            if (result && result?.id) {
                // Do user login
                if (provider !== 'default') {
                    this.app.app.auth().doLogin(result)
                }

                if (provider === 'promo-code') {
                    // Variable to save
                    const varToSave = this.block.properties?.variableToSavePromoCode;

                    // Save result to variable
                    if (varToSave) {
                        this.context.setOperationValue(varToSave, 'set', {
                            valueType: 'static',
                            value: result.promo_code,
                        });
                    }
                }

                await this.app.renderer.a2u.socketListener.init();

                await this.updateIapProducts();

                this.processOutgoingLinks(this.context, this.block.id, result, "success");

                return;
            } else if (result?.error?.code === 'user_exists') {
                return this.processOutgoingLinks(this.context, this.block.id, null, 'user-exists');
            }

            return this.processOutgoingLinks(this.context, this.block.id, null, "error");
        } catch (res) {
            this.processOutgoingLinks(this.context, this.block.id, res, "error");
        }
    }

    /**
     * Authenticates a user using their email and password.
     *
     * This method retrieves the email and password from the block properties,
     * then calls the signup method of the auth service with these credentials.
     * If the signup is successful, it emits the user object to the outgoing links of the block.
     *
     * @returns {Promise<void>} A promise that resolves when the user object has been emitted.
     * @throws {Error} If the signup fails.
     */
    async authDefault() {
        // Get email and password
        const email = this.context.getValue(this.block?.properties?.email);
        const password = this.context.getValue(this.block?.properties?.password);

        // Call the signup method of the auth service with the email and password
        // Emit the user object to the outgoing links of the block
        return await this.app.app.auth().signup(email, password);
    }

    /**
     * Authenticates a user using a specified social authentication plugin.
     *
     * This method retrieves the specified plugin from the device,
     * creates a new instance of the plugin, and then calls the signup method of the plugin.
     * If the signup is successful, it emits the user object to the outgoing links of the block.
     *
     * @param {string} pluginName - The name of the plugin to use for authentication.
     * @throws {Error} If the specified plugin is not found.
     */
    async authByPlugin(pluginName) {
        // Get the specified plugin from the device
        const plugin = this.app.renderer.a2u.getDevice().getPlugin(`SocialAuth${pluginName}Plugin`);

        // If the plugin is not found, throw an error
        if (!plugin) {
            throw `${pluginName} auth plugin not found`;
        }

        // Create a new instance of the plugin
        const instance = new plugin(this.app);

        // Call the signup method of the plugin and emit the user object to the outgoing links of the block
        return await instance.signup();
    }

    /**
     * Authenticates a user using a promotional code.
     * This method retrieves the promotional code from the block's properties,
     * finds the corresponding authentication plugin for promo codes, and attempts to log in using the promo code.
     * If the plugin is not found, an error is thrown.
     *
     * @async
     * @returns {Promise<Object>} A promise that resolves to the user object if the authentication is successful.
     * @throws {Error} If the promo code authentication plugin is not found.
     */
    async authByPromoCode() {
        // Retrieve the promo code from the block's properties
        const email = this.context.getValue(this.block?.properties?.email);

        // Attempt to get the promo code authentication plugin
        const plugin = this.app.renderer.a2u.getDevice().getPlugin('AuthPromoCodePlugin');

        // If the plugin is not found, throw an error
        if (!plugin) {
            throw 'Promo code auth plugin not found';
        }

        // Create a new instance of the plugin and attempt to log in with the promo code
        const instance = new plugin(this.app);

        // Return the result of the login attempt
        return await instance.signup(email);
    }

    /**
     * Updates and synchronizes in-app purchase (IAP) products.
     * This method attempts to retrieve an instance of the IAPManager plugin. If the plugin is found,
     * it calls the `update` method to refresh the list of products available for purchase and then
     * synchronizes the purchase records with the server by calling `syncPurchases`. If the plugin is not
     * found, the method exits early. Errors during the update or synchronization process are caught and logged.
     */
    async updateIapProducts() {
        try {
            const plugin = await this.context?.renderer?.a2u?.getDevice()?.getPluginInstance?.("IAPManager");

            if (!plugin) {
                return;
            }

            await plugin.update();
            await plugin.syncPurchases();
        } catch (e) {
            console.error('Error updating IAP products', e);
        }
    }
}
