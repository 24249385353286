import {BaseComponentProcessor} from "../../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class AskAssistantProcessor extends BaseComponentProcessor {
    /**
     * Process incoming event
     */
    async processEvent() {
        const backendEndpoint = this.app?.renderer?.a2u?.authUrl;

        const chatId = this.context.interpretString(this.block?.properties?.chatId);
        const bindings = Object.fromEntries(
          Object.entries(this.block?.properties?.instructions?.bindings || {}).map(([key, value]) => [
            key,
            this.context.getValue(value),
          ])
        );
        const outgoingEvents = (this.block?.properties?.outgoingEvents?.items || [])
          .map((e) => e.instructions);

        const type = this.block?.properties?.messageType || 'text';

        let content;

        if (type === 'text') {
            content = this.context.interpretString(this.block?.properties?.message);
        } else {
            const imageUrl = this.context.getValue(this.block?.properties?.image);

            content = await this.context.renderer.a2u.assetBase64(imageUrl);
        }

        if (!content) {
            console.error('Error calling tool ai-assistant: content not found');

            this._debugLog('Error calling tool ai-assistant: content not found');

            return this.processOutgoingLinks(this.context, this.block.id, null, 'error');
        }

        try {
            const {jobId} = await this.app.app.client.call(`${backendEndpoint}/ai-assistant`, 'sendMessage', {
                chatId,
                content,
                type,
                bindings,
                outgoingEvents,
                moduleId: this.context.renderer.a2u.getModuleId(),
                blockId: this.block.id,
            });

            // Process the result
            if (!jobId) {
                throw new Error('Job ID not found');
            }

            const varToSaveJobId = this.block.properties?.varToSaveJobId;

            if (varToSaveJobId) {
                this.context.setOperationValue(varToSaveJobId, 'set', {
                    valueType: 'static',
                    value: jobId,
                });
            }

            this.processOutgoingLinks(this.context, this.block.id, jobId, 'job-id');
        } catch (e) {
            console.error('Error calling tool ai-assistant:', e);

            this._debugLog('Error calling tool ai-assistant');

            this.processOutgoingLinks(this.context, this.block.id, null, 'error');
        }
    }

    /**
     * Logs a debug message with detailed component and process information.
     * This method is used for logging error messages during the debugging process. It constructs a detailed
     * log object that includes the error message, the process number, the current diagram ID, the component ID,
     * and the component properties. This detailed log is then passed to the `debugLogger.log` method of the
     * application's renderer context. The method ensures that all sensitive data is cloned and sanitized
     * before logging to avoid any unintended mutations.
     *
     * @param {string} message - The error message to be logged.
     */
    _debugLog(message) {
        this.context.renderer.a2u.debugLogger.log({
            type: 'error',
            message,
            data: JSON.parse(JSON.stringify({
                processNum: this.processNum,
                diagram: { id: this.context?.currentDiagram?.block?.properties?.diagramComponentId },
                component: {
                    id: this.block?.id,
                    properties: this.context.renderer.a2u.patchComponentProperties(this, this.block?.properties || {}),
                },
                data: {},
            })),
        });
    }
}
