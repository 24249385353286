// You may need to npm install these dependencies

/**
 * Sound manager
 */
export class SoundManager {

    // Singleton
    static instance = null;

    _channels = {};
    appState = false

    /**
     * Singleton getter
     */

    static getInstance() {
        if (!SoundManager.instance) {
            SoundManager.instance = new SoundManager();
        }
        return SoundManager.instance;
    }

    /**
     * Class constructor
     */
    constructor() {
    }

    /**
     * Init manager
     */
    async init(appState) {
        // Store state
        this.appState = appState

        // Update state
        this.updateState()
    }

    /**
     * Check if channel is enabled
     * @param name
     * @return {*}
     */
    isChannelMuted(name) {
        return !this.appState.get(`settings.sound.${name}Enabled`);
    }

    /**
     * Update sound manager state
     */
    updateState() {
        // Apply settings
        this.muteChannel('sound', !this.appState.get("settings.sound.soundEnabled"));
        this.muteChannel('music', !this.appState.get("settings.sound.musicEnabled"));
        this.muteChannel('vibration', !this.appState.get("settings.sound.vibrationEnabled"));
    }

    /**
     * Pause app players
     */
    pauseAll() {
        Object.values(this._channels).forEach((audioPlayer) => {
            if (audioPlayer.backgroundPlay) {
                return;
            }

            audioPlayer.pause();
        });
    }

    /**
     * Pause channel
     */
    pause(channel) {
        // Get the audio player
        let audioPlayer = this.getPlayer(channel);
        audioPlayer.pause();
    }

    /**
     * Resume app players
     */
    resume() {
        Object.values(this._channels).forEach((audioPlayer) => {
            audioPlayer.play();
        });
    }


    /**
     * Get player by channel
     */

    getPlayer(channel) {

        // Not in browser
        if(typeof document === 'undefined') return false

        // If the channel doesn't exist, create a new one
        if (!this._channels[channel]) {
            this._channels[channel] = new Audio();
        }

        // Get the audio player
        return this._channels[channel];
    }

    /**
     * Play sound
     */
    async playSound(channel, soundAsset, looped, backgroundPlay = false, progressCallback = null) {
        // Ads disabled in env
        if(process.env.NO_SOUND != null) return;

        // Get the audio player
        let audioPlayer = this.getPlayer(channel);
        if(!audioPlayer) return;

        // Play the sound
        try {
            audioPlayer.src = soundAsset;
            audioPlayer.loop = looped;
            audioPlayer.backgroundPlay = backgroundPlay;

            // If progress callback is set, add event listener
            if (typeof progressCallback === 'function') {
                audioPlayer.addEventListener('timeupdate', () => {
                    // Call the progress callback
                    progressCallback(audioPlayer.currentTime, audioPlayer.duration);
                });
            }

            await audioPlayer.play();
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Mute all channels
     * @param muted
     */
    muteAll(muted) {
        Object.values(this._channels).forEach((audioPlayer) => {
            audioPlayer.volume = muted ? 0 : 100;
            audioPlayer.muted = muted;
        });
    }

    /**
     * Mute channel
     */
    muteChannel(channel, muted) {
        this.setVolume(channel, muted ? 0 : 100);
    }

    /**
     * Set Volume
     */
    setVolume(channel, volume) {
        let audioPlayer = this.getPlayer(channel);
        if(!audioPlayer) return;
        audioPlayer.volume = volume / 100;
        audioPlayer.muted = volume === 0;
    }

    /**
     * Vibrate
     */
    vibrate(type) {
        try {
            switch (type) {
                case "small":
                    navigator.vibrate([200]);
                    break;
                case "medium":
                    navigator.vibrate([500]);
                    break;
                case "long":
                    navigator.vibrate([1000]);
                    break;
            }
        } catch (e) {
            console.log('Vibration is not allowed: ', e);
        }
    }

    /**
     * Dispose sound manager
     */
    dispose() {
        Object.values(this._channels).forEach((audioPlayer) => {
            audioPlayer.pause();
        });
        this._channels = {};
    }

    /**
     * This method is used to dispose a specific audio channel.
     * It first pauses the audio player associated with the channel, then deletes the channel from the _channels object.
     *
     * @param {string} channel - The name of the channel to be disposed.
     */
    disposeChannel(channel) {
        // Pause the audio player associated with the channel
        this.pause(channel);

        // If the channel exists in _channels, delete it
        if (this._channels[channel]) {
            delete this._channels[channel]
        }
    }
}
