<template>

    <span class="component-menu-pe">
        <q-btn color="primary" icon="menu" round flat size="xs">
            <q-menu ref="menu">
                <q-list>

                    <q-item clickable>
                      <q-item-section>Convert to...</q-item-section>
                      <q-item-section side>
                        <q-icon name="keyboard_arrow_right"/>
                      </q-item-section>

                      <q-menu anchor="top end" self="top start">
                        <q-list>
                            <q-item v-for="(cmp, k) of uiComponentsList()" :key="k" clickable v-close-popup
                                    @click="selectComponent(cmp)">
                                <q-item-section>{{ cmp.title }}</q-item-section>
                          </q-item>
                        </q-list>
                      </q-menu>
                    </q-item>

                    <q-item clickable>
                      <q-item-section>Add block...</q-item-section>
                      <q-item-section side>
                        <q-icon name="keyboard_arrow_right"/>
                      </q-item-section>

                      <q-menu anchor="top end" self="top start">
                        <q-list>
                            <q-item v-for="(cmp, k) of uiComponentsList()" :key="k" clickable v-close-popup
                                    @click="addComponent(cmp)">
                                <q-item-section>{{ cmp.title }}</q-item-section>
                          </q-item>
                        </q-list>
                      </q-menu>
                    </q-item>

                    <q-item clickable>
                      <q-item-section>Data</q-item-section>
                      <q-item-section side>
                        <q-icon name="keyboard_arrow_right"/>
                      </q-item-section>

                      <q-menu anchor="top end" self="top start">
                        <q-list>
                            <q-item v-for="(cmp, k) of dataComponentsList()" :key="k" clickable v-close-popup
                                    @click="addComponent(cmp)">
                                <q-item-section>{{ cmp.title }}</q-item-section>
                          </q-item>
                        </q-list>
                      </q-menu>
                    </q-item>

                    <q-separator/>

                    <q-item clickable v-close-popup @click="deleteComponent()">
                        <q-item-section>Delete</q-item-section>
                    </q-item>
                </q-list>
            </q-menu>
        </q-btn>
    </span>

</template>

<script>

import {contentComponentList} from "./contentComponentList";

export default {
    inject: {
        blockEditor: {
            default: null
        }
    },
    props: ["block"],
    name: "ComponentMenuPe",
    methods: {

        /**
         * Show menu
         */
        show() {
            this.$refs.menu.show()
        },

        /**
         * Apply component to block
         * @param cmp
         */
        selectComponent(cmp) {
            this.mutate(this.block).type = cmp.type
        },

        /**
         * Add block
         * @param cmp
         */
        addComponent(cmp) {
            this.blockEditor.addBlock(this.block, cmp.type)
        },

        /**
         * Delete component
         */
        deleteComponent() {

            // Ask user
            this.$q.dialog({
                title: 'Delete block',
                message: 'Are you sure you want to delete this block?',
                cancel: true,
                persistent: true
            }).onOk(() => {
                this.blockEditor.deleteBlock(this.block)
            })
        },

        /**
         * Get UI components list
         * @return {[]}
         */
        uiComponentsList() {
            return contentComponentList.filter(c => c.grp === 'ui')
        },

        /**
         * Get data components list
         * @return {[]}
         */
        dataComponentsList() {
            return contentComponentList.filter(c => ['data', 'variable'].includes(c.grp))
        },
    }
}

</script>

<style lang="scss">

.component-menu-pe {
  .q-btn--round {
    min-width: 2em;
    min-height: 2em;
  }
}

</style>
