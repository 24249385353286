<template>
  <q-card class="column dg-pt-sa">
    <q-toolbar class="bg-grey-3">
      <div class="text-overline text-red text-weight-bold">Debug console</div>

      <q-space />

      <q-tabs v-model="tab" dense>
        <q-tab name="general" label="General" no-caps />
        <q-tab name="start_event" label="Start event" no-caps />
        <q-tab name="ab_tests" label="A/B Tests" no-caps />
        <q-tab name="log" label="Log" no-caps />
        <q-tab name="sql_playground" label="SQL Playground" no-caps />
        <q-tab name="app_storage" label="Storage" no-caps />
      </q-tabs>
    </q-toolbar>

    <q-card-section>
      <q-tab-panels v-model="tab">
        <q-tab-panel name="general">
          <AppSettings/>
        </q-tab-panel>
        <q-tab-panel name="start_event">
          <StartEvent/>
        </q-tab-panel>
        <q-tab-panel name="ab_tests">
          <AbTests/>
        </q-tab-panel>
        <q-tab-panel name="log">
          <DebugLog/>
        </q-tab-panel>
        <q-tab-panel name="sql_playground">
          <SqlPlayground/>
        </q-tab-panel>
        <q-tab-panel name="app_storage">
          <AppStorage/>
        </q-tab-panel>
      </q-tab-panels>
    </q-card-section>
  </q-card>
</template>

<script>
import AbTests from './debug-console/AbTests.vue';
import StartEvent from './debug-console/StartEvent.vue';
import DebugLog from './debug-console/DebugLog.vue';
import AppSettings from './debug-console/AppSettings.vue';
import SqlPlayground from './debug-console/SqlPlayground.vue';
import AppStorage from './debug-console/AppStorage.vue';

export default {
  // Component name
  name: 'AtuDebugConsole',
  components: {AppStorage, StartEvent, AbTests, DebugLog, AppSettings, SqlPlayground},

  // Provide appConsole
  provide() {
    return {
      appConsole: this,
    }
  },

  // Component data
  data() {
    return {
      // Dialog state
      dialog: true,
      // Active tab
      //tab: 'start_event',
      tab: 'general',
    }
  },

  methods: {
    // Restart application
    restartApp() {
      window.location.reload();
    }
  }
}
</script>
