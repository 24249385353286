<template>

  <ab-flow-base-cmp :block="block" class="button-cmp" :class="containerClasses" :style="buttonStyles">
    <q-btn
      v-if="stopPropagation"
      :style="buttonSize"
      :no-caps="isButtonNoCaps"
      v-bind:[buttonShape]="true"
      v-bind:[buttonType]="true"
      v-bind:[buttonStyle]="true"
      @mousedown.stop.prevent="clickButton"
      @touchstart.stop.prevent="clickButton"
      :class="buttonClasses"
      :icon="buttonIcon"
      :loading="buttonLoadingState"
      v-bind="dynamicProps"
    ><div>{{ buttonLabel }}</div></q-btn>
    <q-btn
      v-else
      :style="buttonSize"
      :no-caps="isButtonNoCaps"
      v-bind:[buttonShape]="true"
      v-bind:[buttonType]="true"
      v-bind:[buttonStyle]="true"
      @click.stop.prevent="clickButton"
      :class="buttonClasses"
      :icon="buttonIcon"
      :loading="buttonLoadingState"
      v-bind="dynamicProps"
    ><div>{{ buttonLabel }}</div></q-btn>

  </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "../../renderMixins";
import AbFlowBaseCmp from "../../Containers/Designer/AbFlowBaseCmp.vue";
import {constants} from "../../../constants"

export default {
  components: {AbFlowBaseCmp},
  mixins: [renderMixins],
  props: ['block', 'type'],
  inject: {
    parentForm: {
      default: null
    }
  },
  name: "ButtonEditorCmp",
  methods: {

    /**
     * Click button
     */
    clickButton(e) {
      if (this.isWeb && e.type === 'touchstart') {
        return;
      } else if (!this.isWeb && e.type === 'mousedown') {
        return;
      }

      // Set progress state
      if (this.block?.properties?.progressState?.valueType === 'variable') {
        this.setValue(this.block?.properties?.progressState, true);
      }

      if(this.type === 'submit' || this.isSubmitButton) {
        if(this.parentForm) this.parentForm.submit();
      }

      this.parentDiagram.processOutgoingLinks(this, this.block.id, false, "click");
    }
  },
  computed: {

    /**
     * Button classes
     */
    buttonClasses() {
      return [this.getClasses({backgroundColor: true, textColor: true}), this.iconPositionClass];
    },

    /**
     * Button classes
     */
    containerClasses() {
      return [
        this.getClasses(false, {backgroundColor: true, textColor: true}),
        `button-cmp--${this.buttonStyle || 'default'}`,
        `button-cmp--type-${this.buttonType || 'regular'}`,
      ]
    },

    /**
     * Button label
     * @return {*|string}
     */
    buttonLabel() {
      return this.interpretString(this.block.properties?.label);
    },

    /**
     * Button icon
     * @return {*|string}
     */
    buttonIcon() {
      if (this.block?.properties?.iconType === 'image') {
        const image = this.renderer.a2u.assetPath(this.block?.properties?.iconImage);

        return image ? `img:${image}` : undefined;
      }

      return this.block?.properties?.icon || undefined;
    },

    /**
     * Subtitle
     * @return {string}
     */
    subtitle() {
      return this.block.properties?.subtitle || "";
    },

    /**
     * Button size
     * @return {*|string}
     */
    buttonSize() {
      if (!this.block?.properties?.btnSize) {
        return {};
      }

      return {'font-size': `${constants.size_to_font_size[this.block?.properties?.btnSize || 'md']}px`};
    },

    /**
     * Button color
     * @return {*|string}
     */
    buttonColor() {
      return this.block.properties ? this.block.properties["color"] : "";
    },

    /**
     * Button shape
     * @return {*|string}
     */
    buttonShape() {
      return this.block.properties ? this.block.properties["shape"] : "";
    },

    /**
     * Button type
     * @return {*|string}
     */
    buttonType() {
      return this.block.properties ? this.block.properties["type"] : "";
    },

    /**
     * Is button no-caps
     * @return {boolean}
     */
    isButtonNoCaps() {
      return this.block.properties ? this.block.properties["noCaps"] === 1 : false;
    },

    /**
     * Button loading state.
     * This method retrieves the value of the 'progressState' property from the block's properties.
     * If the 'progressState' property is truthy, it returns true; otherwise, it returns false.
     * @returns {boolean} The loading state of the button.
     */
    buttonLoadingState() {
      return !!this.getValue(this.block?.properties?.progressState);
    },

    stopPropagation() {
      return this?.block?.properties?.stopPropagation === 1;
    },

    /**
     * Computed property that checks if the current platform is web.
     * It uses the `getPlatform` method of the `a2u` object of the `renderer` object of the component.
     * If the `getPlatform` method returns "web", it returns true.
     * If the `getPlatform` method does not return "web", it returns false.
     * @return {boolean} True if the current platform is web, false otherwise.
     */
    isWeb() {
      return this.renderer?.a2u?.getPlatform?.() === "web";
    },

    /**
     * Computes the class for button icon positioning.
     * This method determines the class to apply based on the icon's position property.
     * If no icon is set, it returns an empty string. Otherwise, it returns a class
     * indicating the icon's position (left, right, top, bottom) relative to the button text.
     *
     * @returns {string} The class name for the icon position.
     */
    iconPositionClass() {
      if (!this.buttonIcon) {
        return '';
      }

      const position = this.block?.properties?.iconPosition || 'left';

      return `button-cmp-icon-${position}`
    },

    /**
     * Determines if the button is a submit button.
     *
     * @returns {boolean} True if the button is a submit button, false otherwise.
     */
    isSubmitButton() {
      return this.block?.properties?.submitButton === 1;
    },

    /**
     * Retrieves the button style.
     * This method checks the `style` property of the block's properties.
     * If the `style` property is set to 'default', it returns an empty string.
     * Otherwise, it returns the value of the `style` property.
     *
     * @returns {string} The style of the button.
     */
    buttonStyle() {
      const style = this.block?.properties?.style || 'default';

      return style === 'default' ? '' : style;
    },

    /**
     * Computes the styles for the button.
     * If the button type is not 'outline', it returns the existing styles string.
     * Otherwise, it computes additional styles for the outline button type.
     *
     * @returns {Object} The computed styles for the button.
     */
    buttonStyles() {
      if (this.buttonType !== 'outline') {
        return this.stylesString;
      }

      const outlineStyles = {};

      const {backgroundColor, background} = this.block?.properties;

      if (backgroundColor) {
        outlineStyles['--button-outline-background-color'] = `var(--background-color-${backgroundColor})`;
      } else if (background?.color) {
        outlineStyles['--button-outline-background-color'] = background.color;
      }

      return {
        ...this.stylesString,
        ...outlineStyles,
      };
    },
  },
}

</script>

<style lang="scss" scoped>
.button-cmp {
  position: relative;

  &.button-cmp--type-outline {
    background-color: transparent !important;
  }

  button {
    font-size: inherit;
    font-weight: inherit;
  }

  &:not(.button-cmp--round) {
    button {
      width: 100%;
    }
  }

  .subtitle {
    display: block;
    font-size: 12px;
  }

  // Click event
  .output {
    position: absolute;
    left: calc(50% - 5px);
    top: calc(50% - 5px);
  }

  .button-cmp-icon-top {
    &:deep(.q-btn__content) {
      flex-direction: column;
    }
  }

  .button-cmp-icon-right {
    &:deep(.q-btn__content) {
      flex-direction: row-reverse;
    }
  }

  .button-cmp-icon-bottom {
    &:deep(.q-btn__content) {
      flex-direction: column-reverse;
    }
  }

  &:deep(.q-btn--outline) {
    &:before {
      border-color: var(--button-outline-background-color);
    }

    .q-focus-helper {
      background-color: var(--button-outline-background-color) !important;
    }

    .q-ripple {
      color: var(--button-outline-background-color) !important;
    }
  }
}
</style>
