<template>

  <ab-flow-base-cmp :block="block" :class="classesString" :style="stylesString">
    <q-slider
      v-model="currentValue"
      :min="min"
      :max="max"
      :color="color"
      label
      v-bind="dynamicProps"
    />
  </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "../renderMixins";
import AbFlowBaseCmp from "../Containers/Designer/AbFlowBaseCmp.vue";
import {formComponentsMixins} from "../Form/formComponentsMixins";

export default {
  components: {AbFlowBaseCmp},
  mixins: [renderMixins, formComponentsMixins],
  props: ['block'],
  name: "SliderEditorCmp",
  data: () => ({

  }),
  computed: {
    /**
     * min
     * @return {number}
     */
     min() {
      return Number(this.block?.properties?.min) || 0;
    },
    /**
     * max
     * @return {number}
     */
     max() {
      return Number(this.block?.properties?.max) || 100;
    },
    /**
     * Color
     * @return {string}
     */
     color() {
      return this.block?.properties?.color || "";
    },
  }
}

</script>
