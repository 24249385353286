import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class RequestCameraPermissionsProcessor extends BaseComponentProcessor {
    constructor(...args) {
        super(...args);

        this.camera = this.context.renderer.a2u.getDevice()?.getPlugin?.("CapacitorCamera");
    }

    async processEvent() {
        // Check if the run mode is debug or web
        if(this.app.renderer.a2u.runMode === 'debug') {
            return this.debugResponse({ok: {}, no: {}});
        }

        // Check if the run mode is debug or web
        if(this.context.renderer.a2u.getPlatform() === 'web') {
            return this.processOutgoingLinks(this.context, this.block.id, null, 'ok');
        }

        try {
            // Get the mode from the block properties
            const mode = this.block?.properties?.mode || 'check_request';

            // Check if the camera and photos permissions are granted
            if (await this._checkPermissions()) {
                return this.processOutgoingLinks(this.context, this.block.id, null, 'ok');
            } else if (mode === 'check') {
                return this.processOutgoingLinks(this.context, this.block.id, null, 'no');
            }

            // Request the camera and photos permissions
            const {camera, photos} = await this.camera.requestPermissions(['camera', 'photos']);

            // Check if the camera and photos permissions are granted
            if (camera === 'granted' && photos === 'granted') {
                return this.processOutgoingLinks(this.context, this.block.id, null, 'ok');
            }

            return this.processOutgoingLinks(this.context, this.block.id, null, 'no');
        } catch (e) {
            console.error('ImagePickerProcessor error:', e);
        }
    }

    /**
     * Checks if the camera and photos permissions are granted.
     *
     * This method first calls the 'checkPermissions' method of the 'camera' object, which returns an object with the status of the 'camera' and 'photos' permissions.
     * It then checks if both the 'camera' and 'photos' permissions are 'granted'.
     *
     * @async
     * @returns {Promise<boolean>} Returns true if both the 'camera' and 'photos' permissions are 'granted', false otherwise.
     */
    async _checkPermissions() {
        const {camera, photos} = await this.camera.checkPermissions();

        return camera === 'granted' && photos === 'granted';
    }
}
