import {BaseComponentProcessor} from "../../../utils/processor/BaseComponentProcessor";

/**
 * Widget processor
 */
export class WidgetProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }

    /**
     * Process incoming event
     * @param event
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    processEvent(event, data) {
        //console.log('Widget processor!', this.block, event, data, );
        this.context.currentDiagram.callHandler(this.block.id, event, data);
    }
}
