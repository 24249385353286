import {BaseComponentProcessor} from "../../../utils/processor/BaseComponentProcessor";

/**
 * Check privacy options processor
 */
export class CheckPrivacyOptionsProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param event
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    async processEvent(event, data) {

        // Get ad instance
        const adManager = this.context.renderer.a2u.adManager

        // Check if no Ad manager - debug response
        if(!adManager || this.context.renderer.a2u.getPlatform() === 'web') {
            return this.debugResponse({available: {}, 'not-available': {}}, `Check privacy options`);
        }

        try {
            // Check if privacy options available
            const res = await adManager.checkPrivacyOptions();

            return this.processOutgoingLinks(this.context, this.block.id, false, res ? "available" : "not-available");
        } catch (ex) {
            console.log(ex)
            return this.processOutgoingLinks(this.context, this.block.id, ex, "not-available");
        }
    }
}
