import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class EnableInAppTestModeProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param event
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    processEvent(event, data) {

        // Enable in app test mode
        this.context.getStorage("app").set("settings.test.premium", 1)

        // Test mode enabled
        console.log("Test mode enabled")

        // Process success
        this.processOutgoingLinks(this.context, this.block.id, undefined, "success");

    }
}
