import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * Loading processor
 */
export class LoadingProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param event
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    processEvent(event, data) {

        // Get props
        const action = this.block.properties?.action;
        const title = this.block.properties?.title;

        // Show / hide loading
        switch (action) {
            case 'show':
                this.context.$q.loading.show({
                    message: title,
                });
                break;
            case 'hide':
                this.context.$q.loading.hide();
                break;
        }

        // Return result event
        this.processOutgoingLinks(this.context, this.block.id, false, 'success');
    }
}
