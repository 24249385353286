import {BaseComponentProcessor} from "../../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class CustomEventProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param event
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    processEvent(event, data) {

        // Check for parent widget and outgoing event
        if(this.block.properties?.eventType === 'outgoing') {

            // Get parent component block id
            const blockId = this.context.currentDiagram.block.id;

            // Solution for the issue where navigation between diagrams within the widget was not working
            // Check for parent widget
            /*const parentWidget = this.context.parentWidget

            // Process events according to parent context
            if(parentWidget) {
                // Get parent component
                //return this.context.parentWidget?.exitDiagram();
                return this.context.parentWidget?.parentDiagram.processOutgoingLinks(parentWidget, blockId, data?.input, this.block?.properties?.name)
            } else {
                // Processing outgoing links
                //console.error("Process outgoing link, current block:", this.block, ', context: ', this.context.currentDiagram.parentDiagram);

                // Get parent component
                this.context.currentDiagram?.exitDiagram();
                return this.context.currentDiagram.parentDiagram.processOutgoingLinks(this.context.currentDiagram.parentDiagram, blockId, data?.input, this.block?.properties?.name)
            }*/

            // Get parent component
            this.context.currentDiagram?.exitDiagram();
            return this.context.currentDiagram.parentDiagram.processOutgoingLinks(this.context.currentDiagram.parentDiagram, blockId, data?.input, this.block?.properties?.name)
        }

        // Process outgoing links
        this.processOutgoingLinks(this.context, this.block.id, data?.input)
    }
}
