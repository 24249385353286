import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * ChatProcessor class for handling chat messages.
 */
export class ChatProcessor extends BaseComponentProcessor {

  /**
   * Init component
   * @return {Promise<void>}
   */
  static async init(blockId) {

    // Get the block data
    const blockData = this?.blocks[blockId]?.node;

    // Retrieve the database
    const db = this?.dbs[blockData?.properties?.dbId];

    // Retrieve the tableId and the fields of the table
    const tableId = blockData?.properties?.tableId;

    // Set the dbModel property to the model of the table in the database
    const dbModel = db?.models[tableId];

    // Subscribing to the chat channel
    this.socketListener.on('ab-chat:answer', async (data) => {
      // Save to local db
      await dbModel.save({
        chat_id: data.chat_id,
        type: "incoming",
        message: data.message,
        options: data.options,
        sent_at: data.sent_at
      });
    });
  }

  /**
   * Processes the event for the chat message.
   * @param {String} event - The event to process.
   * @param {Object} data
   * @returns {Promise} The result of the event processing.
   */
  async processEvent(event, data) {
    // Get the call handler function
    const callHandlerFn = this?.context?.currentDiagram?.callHandler || this?.context?.callHandler;

    // If the call handler function is not a function, return
    if (typeof callHandlerFn !== 'function') {
      return;
    }

    // Call handler
    callHandlerFn(this.block.id, 'incomingEvent', {event, data: data?.input || data});
  }
}
