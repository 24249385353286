import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class ShareDialogProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param source
     * @param source_event
     * @param action
     * @param data
     */
    async processEvent() {

        // Get ad instance
        const shareManager = this.context.renderer.a2u.getDevice()?.getPlugin?.("Share");

        // Check if no shareManager - debug response
        if(!shareManager || (this.context.renderer.a2u.getPlatform() === 'web' && this.app.renderer.a2u.runMode === "debug") ) {
            // Get result
            return this.debugResponse({error: {}});
        }

        // Get url
        const urlPath = this.context.getValue(this.block?.properties?.url) || null;
        const url = urlPath ? await this.context.renderer.a2u.assetPathAsync(urlPath) : null;

        // Get files
        const files = this.context.getValue(this.block?.properties?.files) || null;

        // Get files url
        const filesUrl = files ? await this.context.renderer.a2u.assetPathAsync(files) : null;

        // Defining the configuration for the "Share" plugin
        const shareCfg = {
            title: this.context.interpretString(this.block?.properties?.title),
            text: this.context.interpretString(this.block?.properties?.text),
            url,
            dialogTitle: this.context.interpretString(this.block?.properties?.dialogTitle),
            files: filesUrl ? [filesUrl] : undefined,
        };

        // Cleaning the configuration from empty values
        const finalShareCfg = Object.fromEntries(
          Object.entries(shareCfg).filter(([, v]) => !!v),
        );

        // Returning an error if the configuration ends up empty
        if (!Object.keys(finalShareCfg).length) {
            console.error('No resources have been specified to share', this.block)
            return this.processOutgoingLinks(this.context, this.block.id, false, 'error');
        }

        // Check permission
        const result = await shareManager.share(finalShareCfg);
        console.log("Share result", result);
    }
}
