import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class CheckPermissionProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param event
     * @param data
     */
    async processEvent() {

        // Get permission to check
        const permissionName = this.block?.properties?.permission

        // Get ad instance
        const permManager = this.context.renderer.a2u.getDevice()?.getPlugin?.("PermissionCall")

        // Check if no Ad manager - debug response
        if(!permManager || (this.context.renderer.a2u.getPlatform() === 'web') ) {
            // Get result
            return this.debugResponse({yes: {}, no: {}});
        }

        // Permission check result
        let result

        try {
            // Disallow restore event
            this.context.renderer.a2u.allowRestore(false);
            result = await permManager.checkPermission({permissionName})
        } catch (error) {
            console.error("Request permission error", error);
            result = {granted: false}
        } finally {
            // Allow restore event
            this.context.renderer.a2u.allowRestore(true);
        }

        // Process result
        return this.processOutgoingLinks(this.context, this.block.id, undefined, result?.granted ? 'yes' : 'no');
    }
}
