<template>
  <ab-flow-base-cmp v-if="isReady" :block="block" :class="classesString" :style="stylesString">
    <q-card flat class="chat-card column no-wrap" :class="{'opacity-0': !isScrolledToEnd}">
      <q-card-section style="flex-basis: 100%;" class="q-pa-none">
        <q-scroll-area ref="scrollArea" class="full-height" scroll-position="end">
          <div class="q-px-sm q-pb-md">
            <div v-for="(item, idx) in historyStacked" :key="item.id">
              <template v-if="item.widget">
                <div class="row full-width" :class="{'justify-end': item.type === 'outgoing'}">
                  <div>
                    <diagram-component-editor-cmp
                        ref="diagram"
                        :block="item.widget.block"
                        :arguments="item.widget.arguments"
                    />
                  </div>
                </div>
              </template>
              <template v-else>
                <q-chat-message
                    class="chat-message"
                    :text="Array.isArray(item.message) ? item.message : [item.message]"
                    :stamp="item.sent_at"
                    :sent="item.type === 'outgoing'"
                    :style="item.type === 'outgoing' ? outgoingStyles : incomingStyles"
                    text-html
                    :size="messageSize"
                    @click="onContentClick(item)"
                >
                  <template #name>
                    {{ item.type === 'incoming' ? botName : userName }}
                    <q-spinner-dots
                        v-if="hasPreloader && isLastMessageIncoming && idx === historyStacked.length - 1"
                        size="1rem"
                    />
                  </template>
                </q-chat-message>

                <div v-if="item.options && item.options.length" class="row full-width">
                  <q-btn v-for="option in item.options" :key="option.event" @click="sendAnswer(option)" no-caps square color="primary" text-color="white" :label="option.text" />
                </div>
              </template>
            </div>

            <q-chat-message
                v-if="hasPreloader && !isLastMessageIncoming"
                class="chat-message"
                :name="botName"
                :style="incomingStyles"
            ><q-spinner-dots size="2rem" /></q-chat-message>
          </div>
        </q-scroll-area>
      </q-card-section>

      <q-card-section v-if="!readonly" class="q-pa-none">
        <q-input
            class="chat-field"
            v-model="message"
            filled
            autogrow
            placeholder="Type your message here..."
            :style="fieldStyles"
        >
          <template #append>
            <q-btn
                :class="btnClass"
                round
                dense
                flat
                icon="send"
                :disable="!this.message.length"
                :loading="isBusy"
                @mousedown.stop.prevent="sendRawMessage"
                @touchstart.stop.prevent="sendRawMessage"
            />
          </template>
        </q-input>
      </q-card-section>
    </q-card>
  </ab-flow-base-cmp>
</template>

<script>
import {computed, reactive} from 'vue';
import moment from 'moment';
import {AbStorage} from 'a2u-renderer-common/src/utils/abStorage';
import AbFlowBaseCmp from "../Containers/Designer/AbFlowBaseCmp";
import {renderMixins} from "../renderMixins";
import DiagramComponentEditorCmp from '../Logic/DiagramComponent/DiagramComponentEditorCmp.vue';

/**
 * Checks if the gap between two moments is larger than the specified maximum gap in minutes.
 *
 * @param {string|Date|moment.Moment} start - The start time.
 * @param {string|Date|moment.Moment} end - The end time.
 * @param {number} maxGapMinutes - The maximum allowed gap in minutes.
 * @returns {boolean} True if the gap is larger than the specified maximum gap, false otherwise.
 */
function isGapTooLarge(start, end, maxGapMinutes) {
  const gap = moment.unix(end).diff(moment.unix(start));
  return gap > maxGapMinutes * 60 * 1000;
}

export default {
  components: {DiagramComponentEditorCmp, AbFlowBaseCmp},
  mixins: [renderMixins],
  props: ['block'],
  name: "ChatEditorCmp",

  // Data properties of the component
  data() {
    return {
      isReady: false, // Indicates if the component is ready
      isBusy: false, // Indicates if the component is busy
      message: '', // The message to be sent
      dbModel: null, // The database model
      storageData: false,
      scrollTimeout: undefined,
      isScrolledToEnd: false,
    };
  },

  // Computed properties of the component
  computed: {
    // Returns the channel of the block or 'chat' if not defined
    channel() {
      return this.interpretString(this.block?.properties?.channel) || 'chat';
    },

    /**
     * Get the chat bot id
     * @return {*}
     */
    abBotId() {
      return this.interpretString(this.block?.properties?.abChatBotId);
    },

    // Returns the diagramId of the block
    diagramId() {
      return this.block?.properties?.diagramId;
    },

    // Returns the chatId
    chatId() {
      return `chat:${this.channel}`;
    },

    // Returns the raw history of the chat
    historyRaw() {
      const timeZoneOffset = (new Date().getTimezoneOffset() / 60);

      return this.wait('historyRaw', this.dbModel?.query()?.where({chat_id: this.channel})?.order(
          `CASE
              WHEN sent_at LIKE '%-%' THEN CAST(strftime('%s', sent_at, '${timeZoneOffset} hours') AS INT)
              ELSE CAST(sent_at AS INT)
          END`
      )?.get(), []);
    },

    // Returns the history of the chat with parsed messages and relative timestamps
    history() {
      const marked = this.renderer?.a2u?.device?.getPlugin('MarkdownParser');

      return this.historyRaw.map((item) => ({
        ...item,
        message: marked && item.message ? marked.parse(item.message) : item.message,
        // todo: Remove after a certain period. Added on 04-09-2024.
        sent_at: (/^\d+$/.test(item.sent_at) ? moment.unix(item.sent_at) : moment(item.sent_at)).format('YYYY-MM-DD HH:mm:ss'),
        image: item?.metadata?.type === 'image' ? item.message : null,
        widget: item?.metadata?.widgetId || item?.metadata?.widget ? this.getWidgetBlock(item?.metadata?.widgetId || item?.metadata?.widget, item) : null,
      }));
    },

    // Returns the stacked history of the chat
    historyStacked() {
      const result = [];
      let group = [];
      let lastMessageTime = null;

      // Group messages by type and time
      for (const message of this.history) {
        if (group.length === 0) {
          group.push(message);
          lastMessageTime = message.sent_at;
        } else {
          const lastMessage = group[group.length - 1];

          // If the message type is the same as the last message type and the gap is not too large
          if (message.type === lastMessage.type && !isGapTooLarge(lastMessageTime, message.sent_at, 1) && !lastMessage.widget && !message.widget) {
            group.push(message);
            lastMessageTime = message.sent_at;
          } else {
            result.push(group);
            group = [message];
            lastMessageTime = message.sent_at;
          }
        }
      }

      // Add the last group
      if (group.length > 0) {
        result.push(group);
      }

      console.warn(result.map((stack) => {
        if (stack.length === 1) {
          return stack[0];
        }

        return {
          ...stack[stack.length - 1],
          message: stack.map((s) => s.message),
        };
      }))

      return result.map((stack) => {
        if (stack.length === 1) {
          return stack[0];
        }

        return {
          ...stack[stack.length - 1],
          id: stack[0].id,
          message: stack.map((s) => s.message),
        };
      });
    },

    // Returns the length of the history
    historyLength() {
      return this.history.length;
    },

    // Returns the styles for the outgoing messages
    outgoingStyles() {
      return {
        '--message-text-color': `var(--foreground-color-${this.block?.properties?.outgoingTextColor || 'light'})`,
        '--message-bg-color': `var(--background-color-${this.block?.properties?.outgoingBgColor || 'info'})`,
      };
    },

    // Returns the styles for the incoming messages
    incomingStyles() {
      return {
        '--message-text-color': `var(--foreground-color-${this.block?.properties?.incomingTextColor || 'light'})`,
        '--message-bg-color': `var(--background-color-${this.block?.properties?.incomingBgColor || 'success'})`,
      };
    },

    // Returns the styles for the field
    fieldStyles() {
      return {
        '--chat-control-text-color': `var(--foreground-color-${this.block?.properties?.fieldTextColor || 'dark'})`,
        '--chat-control-bg-color': `var(--background-color-${this.block?.properties?.fieldBgColor || 'tertiary'})`,
      };
    },

    // Returns the class for the button
    btnClass() {
      return `dg-foreground-${this.block?.properties?.btnTextColor || 'dark'}`;
    },

    storage() {

      // Create storage
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      if (!this.storageData) this.storageData = new AbStorage({
        initialStorage: this.renderer.a2u.source?.storage?.data?.[`diagram-${this.diagramId}`],
        storageCreator: (data) => reactive(data),
      });

      // Set incoming params
      for (const [name, val] of Object.entries(this.getArguments(this.block.id, this.block.properties.arguments) || {})) {
        if (val !== undefined) this.storageData.set(name, val)
      }

      // Return storage
      return computed(() => this.storageData);
    },

    // Returns the keyboard visibility flag
    keyboardVisibility() {
      return this.renderer.a2u.storage.get('settings.application.isKeyboardOpen', false);
    },

    // Read-only flag
    readonly() {
      return this.getValue(this.block?.properties?.readonly) || false;
    },

    // Returns the bot name
    botName() {
      return this.interpretString(this.block?.properties?.botName) || 'bot';
    },

    // Returns the user name
    userName() {
      return this.interpretString(this.block?.properties?.userName) || 'me';
    },

    /**
     * Computed property that returns the arguments for the widget.
     * It iterates over the widgetArgs property of the block (or an empty array if not defined),
     * and for each argument, it adds a new property to the resulting object with the name of the argument as the key
     * and the evaluated value of the argument as the value.
     * @return {Object} An object containing the arguments for the widget.
     */
    widgetArgs() {
      return (this.block?.properties?.widgetArgs || []).reduce((args, arg) => {
        args[arg.name] = this.getValue(arg.value);

        return args;
      }, {});
    },

    /**
     * Computed property that checks if the `scrollAnimated` property is defined and equals 1 in the block's properties.
     * If it is, it returns true.
     * If it is not, it returns false.
     * This property can be used to determine if scroll animation is enabled.
     * @return {boolean} True if scroll animation is enabled, false otherwise.
     */
    scrollAnimated() {
      return this?.block?.properties?.scrollAnimated === 1;
    },

    /**
     * Computed property that returns the duration for the scroll animation in the chat.
     * It first checks if the `scrollDuration` property is defined in the block's properties.
     * If it is, it returns the value of the `scrollDuration` property.
     * If it is not, it returns a default value of 300.
     * @return {number} The duration for the scroll animation in the chat.
     */
    scrollDuration() {
      return this?.block?.properties?.scrollDuration || 300;
    },

    /**
     * Computed property that checks if the current platform is web.
     * It uses the `getPlatform` method of the `a2u` object of the `renderer` object of the component.
     * If the `getPlatform` method returns "web", it returns true.
     * If the `getPlatform` method does not return "web", it returns false.
     * @return {boolean} True if the current platform is web, false otherwise.
     */
    isWeb() {
      return this.renderer?.a2u?.getPlatform?.() === "web";
    },

    /**
     * Checks if the `withChatDiagram` property is defined and equals 1 in the block's properties.
     *
     * This computed property can be used to determine if the chat diagram is enabled.
     *
     * @return {boolean} True if the chat diagram is enabled, false otherwise.
     */
    withChatDiagram() {
      return this.block?.properties?.withChatDiagram === 1;
    },

    /**
     * Checks if the bot is currently writing.
     *
     * @returns {boolean} True if the bot is writing, otherwise false.
     */
    hasPreloader() {
      return !!this.getValue(this.block?.properties?.botIsWriting);
    },

    /**
     * Returns the message size.
     *
     * @returns {string} The message size from the block properties, or 9 if not defined.
     */
    messageSize() {
      return String(this.block?.properties?.messageSize || 9);
    },

    /**
     * Checks if the last message in the chat history is incoming.
     *
     * @returns {boolean} True if the last message is incoming, false otherwise.
     */
    isLastMessageIncoming() {
      return this.historyRaw[this.historyRaw.length - 1]?.type === 'incoming';
    },
  },

  // Methods of the component
  methods: {
    /**
     * This method is used to send an answer to the chatbot.
     * It constructs a payload object with the chatId and the answer provided.
     * Then, it checks if there is a promise associated with the chatId in the process manager.
     * If there is no promise, it runs the process with the diagramId and the payload.
     * If there is a promise, it resolves the promise with the payload.
     *
     * @async
     * @param {string} answer - The answer to be sent to the chatbot.
     * @param {string} type
     * @throws Will throw an error if the process manager encounters an issue while running or resolving the promise.
     */
    async sendToChatBot(answer, type = 'outgoing') {

      // Check answer type
      if (typeof answer === 'string') {
        answer = {
          type: 'text',
          content: answer,
        };
      }

      this.isBusy = true;
      try {

        // If the chat diagram is not enabled, return immediately
        if (this.withChatDiagram) {
          const payload = {
            chatId: this.chatId,
            answer,
            type,
          };

          if (!await this.renderer.a2u.processManager.hasPromise(this.chatId)) {
            this.renderer.a2u.processManager.run(this.parentDiagram, this.diagramId, payload, this.storage);
          } else {
            await this.renderer.a2u.processManager.resolvePromise(this.parentDiagram, this.chatId, payload, this.storage);
          }
        }

        if (this.block.properties?.connectedToBackend) {

          // Get chat arguments
          const chatArgs = this.getArguments(this.block.id, undefined, this.block.properties.chatArgs || {});

          // Send message to remote server
          const snRes = await this.renderer.app.client.call(this.renderer.a2u.getBackendUrl() + "/ab-chat", "sendMessage", this.abBotId, this.channel, answer, chatArgs);
          if (snRes !== true) {
            throw new Error(snRes);
          }
        }

        // Notify the parent diagram about the message sent
        this.parentDiagram.processOutgoingLinks(this, this.block.id, {
          message: answer,
          type,
        }, 'message-sent');

      } catch (e) {
        console.error('Error while sending answer:', e);
      } finally {
        this.isBusy = false;
      }
    },

    /**
     * This method is used to send an answer to the chatbot.
     * It first checks if the component is busy. If it is, it returns immediately.
     * If the component is not busy, it sets the isBusy flag to true and starts the process of sending the answer.
     * It first saves the answer to the database, then it saves the outgoing message to the database.
     * After that, it sends the answer to the chatbot using the sendToChatBot method.
     * If an error occurs during this process, it logs the error to the console.
     * Finally, it sets the isBusy flag back to false.
     *
     * @async
     * @param {string} option - The answer to be sent to the chatbot.
     * @throws Will log an error to the console if an error occurs during the process.
     */
    async sendAnswer(option) {
      if (this.isBusy) {
        return;
      }

      try {
        /*await this.dbModel.save({
          id: message.id,
          option: e,
        });

        await this.dbModel.save({
          chat_id: this.channel,
          type: 'outgoing',
          message: message.options.find((v) => v.value === e).label,
          options: [],
          option: e,
          sent_at: moment.utc().local().unix(),
        });*/

        switch (option.type) {
          case "answer":
            return await this.sendToChatBot(Object.assign({type: "callback"}, option));
          case "ui-event":
            return this.parentDiagram.processOutgoingLinks(this, this.block.id, option.callbackId, option.event);
        }

      } catch (e) {
        console.error('Error while sending answer:', e);
      }
    },

    /**
     * This method is used to send a raw message to the chatbot.
     * It first checks if the component is busy. If it is, it returns immediately.
     * If the component is not busy, it sets the isBusy flag to true and starts the process of sending the message.
     * It first checks if the message is not empty. If it is, it returns immediately.
     * Then, it saves the outgoing message to the database.
     * After that, it sends the message to the chatbot using the sendToChatBot method.
     * It then clears the message input field and scrolls to the end of the chat.
     * If an error occurs during this process, it logs the error to the console.
     * Finally, it sets the isBusy flag back to false.
     *
     * @async
     * @throws Will log an error to the console if an error occurs during the process.
     */
    async sendRawMessage(e) {
      if (this.isWeb && e.type === 'touchstart') {
        return;
      } else if (!this.isWeb && e.type === 'mousedown') {
        return;
      }

      if (this.isBusy) {
        return;
      }

      try {
        if (!this.message) {
          return;
        }

        await this.dbModel.save({
          chat_id: this.channel,
          type: 'outgoing',
          message: this.message,
          options: [],
          option: null,
          sent_at: moment.utc().local().unix(),
        });

        await this.sendToChatBot(this.message);

        this.message = '';
      } catch (e) {
        console.error('Error while sending message:', e);
      }
    },

    /**
     * This method is used to listen to the chat completion event.
     * It sets the isBusy flag back to false.
     */
    async chatCompleteListener() {
      this.isBusy = false;
    },

    /**
     * This method is used to listen to new messages in the chat.
     * It first sets the isBusy flag back to false.
     * Then, it saves the incoming message to the database and scrolls to the end of the chat.
     *
     * @async
     * @param {object} message - The incoming message object.
     * @param {object} options - The options associated with the incoming message.
     * @param {string} date - The date the message was sent.
     */
    async chatNewMessageListener({message, options, date}) {
      // Save the incoming message to the database
      await this.dbModel.save({
        id: undefined,
        chat_id: this.channel,
        type: 'incoming',
        message: message,
        options: options || [],
        option: null,
        sent_at: moment.utc(date).local().unix(),
      });

      this.isBusy = false;
      this.scrollToEnd();
    },

    /**
     * This method is used to initialize the chat component.
     * It first checks if the channel and the diagramId are defined. If not, it logs an error and returns immediately.
     * Then, it retrieves the database associated with the block. If the database is not defined, it logs an error and returns immediately.
     * It then retrieves the tableId and the fields of the table from the database. If the table or its fields are not defined, it logs an error and returns immediately.
     * It checks if the table structure is compatible by checking if it contains all the required fields. If not, it logs an error and returns immediately.
     * It then sets the dbModel property to the model of the table in the database.
     * It sets up an event listener for new messages in the chat. When a new message is received, it saves the message to the database and scrolls to the end of the chat.
     * If there is no promise associated with the chatId in the process manager, it runs the process with the diagramId and the chatId.
     *
     * @async
     * @throws Will log an error to the console if an error occurs during the initialization process.
     */
    async init() {
      // Check if the channel are defined
      if (!this.channel) {
        console.error('Channel is not defined for chat:', this.block);
        return;
      }

      // Check if the diagramId is defined
      if (!this.diagramId && this.withChatDiagram) {
        console.error('Diagram is not defined for chat:', this.block);
        return;
      }

      // Retrieve the database
      const db = this.renderer?.a2u?.dbs[this.block?.properties?.dbId];

      // Check if the database is defined
      if (!db) {
        console.error('Database is not defined for chat:', this.block);
        return;
      }

      // Retrieve the tableId and the fields of the table
      const tableId = this.block?.properties?.tableId;

      // Set the dbModel property to the model of the table in the database
      this.dbModel = db?.models[tableId];

      // Check if the dbModel is defined
      if (!this.dbModel) {
        console.error('Table is not defined for chat:', this.block);
        return;
      }

      const tableFields = Object.keys(this.dbModel?.fields || {});

      // Check if the table or its fields are defined
      if (!tableFields) {
        console.error('Table is not defined for chat:', this.block);
        return;
      }

      // Check if the table structure is compatible
      const requiredFields = ['id', 'chat_id', 'type', 'message', 'sent_at'];
      if (!requiredFields.every((field) => tableFields.includes(field))) {
        console.error('Table structure is incompatible:', this.block);
        return;
      }

      // If the chat diagram is enabled
      if (this.withChatDiagram) {
        // Subscribe to the chat completion event
        this.renderer.a2u.processManager.eventEmitter.on(`chat:complete.${this.channel}`, this.chatCompleteListener);

        // Subscribe to new messages in the chat
        this.renderer.a2u.processManager.eventEmitter.on(`chat:new-message.${this.channel}`, this.chatNewMessageListener);

        // Run the process if there is no promise associated with the chatId in the process manager
        if (!await this.renderer.a2u.processManager.hasPromise(this.chatId)) {
          this.renderer.a2u.processManager.run(this.parentDiagram, this.diagramId, {
            chatId: this.chatId,
          }, this.storage);
        }
      }

      // Register management event
      this.parentDiagram.registerHandler(this.block.id, 'incomingEvent', this.incomingEventHandler)
    },

    /**
     * Scrolls the chat to the end.
     * This method is used to ensure that the latest messages are visible in the chat.
     * It first gets the scroll area and the scroll target from the component's refs.
     * Then, it sets the scroll position of the scroll area to the scroll height of the scroll target.
     * This effectively scrolls the chat to the end.
     * The scroll animation can be performed with or without animation, depending on the `withoutAnimation` parameter.
     * By default, the scroll animation is performed with animation.
     * The duration of the scroll animation is determined by the `scrollDuration` computed property of the component.
     *
     * @param {boolean} [withoutAnimation=false] - A flag indicating whether the scroll animation should be performed without animation.
     */
    scrollToEnd(withoutAnimation = false) {
      clearTimeout(this.scrollTimeout);

      this.scrollTimeout = setTimeout(() => {
        const scrollArea = this.$refs.scrollArea;
        const scrollTarget = scrollArea.getScrollTarget();

        const duration = withoutAnimation || !this.scrollAnimated ? 0 : this.scrollDuration;

        scrollArea.setScrollPosition('vertical', scrollTarget.scrollHeight, duration);

        if (!this.isScrolledToEnd) {
          setTimeout(() => {
            this.isScrolledToEnd = true;
          }, duration);
        }
      }, 100);
    },

    /**
     * This method is used to handle incoming events.
     * @param event
     * @param data
     * @return {Promise<void>}
     */
    async incomingEventHandler({event, data}) {
      switch (event) {
        case 'clear-messages':
          await this.clearMessages();
          return;
        case "callback":
          await this.sendToChatBot(Object.assign({type: "callback", event: data}));
          return;
      }
    },

    /**
     * This method is used to clear the messages in the chat.
     * @return {Promise<void>}
     */
    async clearMessages() {
      try {
        await this.dbModel.query().getRaw({
          query: `DELETE
                  FROM ${this.dbModel.entity}
                  WHERE chat_id = ?`,
          params: [this.channel],
        });

        this.dbModel.triggerUpdate();
      } catch (e) {
        console.error('Error clearing messages:', e);
      }
    },

    /**
     * This method is used to get the widget block.
     * @param alias / widget id
     * @param data
     * @return {Object}
     */
    getWidgetBlock(alias, data) {
      // Find the widget
      const widget = (this.renderer?.source?.diagrams || []).find((d) => (d.alias === alias || d.id === alias) && d.diagram_type === 'widget');

      if (!widget) {
        console.error(`Widget not found: ${alias}`);

        return undefined;
      }

      // Get the markdown parser
      const marked = this.renderer?.a2u?.device?.getPlugin('MarkdownParser');

      let message = '';

      if (data?.metadata?.type === 'image') {
        message = data?.metadata?.image || data.message;
      } else {
        message = marked && data.message ? marked.parse(data.message) : data.message
      }

      return {
        block: {
          widget: widget.title,
          type: "DiagramComponent",
          id: widget.id,
          properties: {
            diagramComponentId: widget.id,
          }
        },
        arguments: {
          ...this.widgetArgs,
          ...data,
          ...(data?.metadata?.widgetArgs || {}),
          message_type: data?.metadata?.type || 'text',
          message,
          sent_at: data.sent_at,
        },
      };
    },

    /**
     * This method is used to handle the content click event.
     * @param message
     */
    onContentClick(message) {
      this.parentDiagram.processOutgoingLinks(this, this.block.id, message, 'content-click');
    },
  },

  watch: {
    /**
     * Watcher for the keyboardVisibility computed property.
     * When the keyboard becomes visible, it scrolls the chat to the end.
     * This is useful for ensuring the latest messages are visible when the keyboard is opened.
     */
    async keyboardVisibility() {
      if (this.keyboardVisibility) {
        this.scrollToEnd();
      }
    },
  },

  // Lifecycle hook called when the component is mounted
  async mounted() {
    // Initialize the chat component
    try {
      await this.init();
    } catch (e) {
      console.error('Error while initializing chat:', e);

      return;
    }

    // Set the component as ready
    this.isReady = true;

    setTimeout(() => {
      this.scrollToEnd(true);

      this.$watch(() => this.historyLength, async () => {
        this.scrollToEnd();
      });
    }, 100);
  },

  async beforeUnmount() {
    // If the chat diagram is enabled
    if (this.withChatDiagram) {
      // Unsubscribe from the chat completion event
      this.renderer.a2u.processManager.eventEmitter.removeListener(`chat:complete.${this.channel}`, this.chatCompleteListener);

      // Unsubscribe from new messages in the chat
      this.renderer.a2u.processManager.eventEmitter.removeListener(`chat:new-message.${this.channel}`, this.chatNewMessageListener);
    }
  }
}

</script>

<style lang="scss">
.chat-card {
  width: 100%;
  background: transparent !important;
}

.chat-message {
  .q-message-text {
    background: var(--message-bg-color);
    color: var(--message-bg-color);

    .q-message-text-content {
      color: var(--message-text-color);

      p:only-child {
        margin: 0;
      }
    }
  }

  .q-message-container {
    & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &.reverse > div {
      align-items: flex-end;
    }
  }
}

.chat-field {
  .q-field__control {
    align-items: flex-end;
    background: var(--chat-control-bg-color) !important;

    &:after {
      display: none !important;
    }

    .q-field__native {
      max-height: 180px;
    }

    .q-field__native, .q-field__label {
      color: var(--chat-control-text-color) !important;
    }
  }
}

.opacity-0 {
  opacity: 0;
}
</style>
