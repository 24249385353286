<template>
  <data-provider storage-key="diagram" :data="storage">
    <diagram-component-renderer ref="renderer" v-if="isReady" :block="block" :current-fragment-id="currentFragmentId"
                                :start-event="startEvent" @fragment-shown="$emit('fragment-shown')" @exit-diagram="$emit('exit-diagram')"/>
  </data-provider>
</template>

<script>

import {reactive} from 'vue';
import {AbStorage} from "a2u-renderer-common/src/utils/abStorage";
import {renderMixins} from "../../renderMixins";
import DataProvider from "../../DataViews/DataProvider/DataProvider.vue";
import DiagramComponentRenderer from "../../Logic/DiagramComponent/DiagramComponentRenderer.vue";

export default {
  components: {DiagramComponentRenderer, DataProvider},
  emits: ['fragment-shown', 'exit-diagram'],
  mixins: [renderMixins],
  props: {
    block: {},
    currentFragmentId: {},
    incomingParams: {
      default: false
    },
    startEvent: {
      default: "start"
    },
    arguments: {
      default: false,
    },
  },
  name: "DiagramComponentEditorCmp",
  setup: () => {
    return {
      storageData: false
    }
  },
  data: () => ({
    isReady: false,
  }),
  created() {
    // Page is ready
    this.isReady = true
  },

  computed: {

    /**
     * Storage
     * @return {AbStorage}
     */
    storage() {
      // Get diagram id
      const diagramId = this.block?.properties?.diagramComponentId;

      // Create storage
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      if (!this.storageData) this.storageData = new AbStorage({
        initialStorage: this.renderer.a2u.source?.storage?.data?.[`diagram-${diagramId}`],
        storageCreator: (data) => reactive(data),
      });

      // Get diagram arguments
      const diagramArgs = Object.values(this.renderer.a2u.source?.storage?.meta || {})
        .filter((v) => v.blockId === `diagram-${diagramId}` && v.isArgument === 1)
        .map((v) => v?.isReference ? [v.name, `_${v.name}`] : v.name)
        .flat();

      // Set arguments
      for (const [name, val] of Object.entries(this.arguments || {})) {
        if (val !== undefined && diagramArgs.includes(name)) {
          this.storageData.set(name, val)
        }
      }

      // Set incoming params
      for (const [name, val] of Object.entries(this.getArguments(this.block.id, this.incomingParams) || {})) {
        if (val !== undefined && diagramArgs.includes(name)) {
          this.storageData.set(name, val)
        }
      }

      // Return storage
      return this.storageData;
    }
  },

  methods: {

    /**
     * Send event to all who interested
     * @param recipient
     * @param event
     * @param data
     */
    sendEvent(recipient, event, data) {
      console.log('Send event', recipient, event, data)
    },
  },
}

</script>
