import {BaseComponentProcessor} from "../../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class SendEmailProcessor extends BaseComponentProcessor {
    /**
     * Process incoming event
     */
    processEvent() {
        // Get the endpoint URL
        const endpointUrl = this._getEndpoint();

        // If the endpoint URL is not set, return an error
        if (!endpointUrl) {
            return this.processOutgoingLinks(this.context, this.block.id, null, 'error');
        }

        const styles = `
          .dg-text-weight-thin {
            font-weight: 100;
          }
        
          .dg-text-weight-regular {
            font-weight: 400;
          }
        
          .dg-text-weight-bold {
            font-weight: 700;
          }
          ${this.context.renderer.a2u.getHtmlStyles()}
        `;

        // Get the email properties
        const to = this.context.interpretString(this.block?.properties?.to);
        const from = this.context.interpretString(this.block?.properties?.from);
        const subject = this.context.interpretString(this.block?.properties?.subject);
        const sourceHtml = this.context.interpretString(this.block?.properties?.html);
        const html = sourceHtml ? `
            <html lang="${this.context.renderer.currentLanguage || 'en'}">
                <head><style>${styles}</style></head>
                <body>${sourceHtml}</body>
            </html>
        ` : null;

        // If any of the email properties are not set, return an error
        if (!to || !from || !subject || !html) {
            const notFilledProperties = Object.entries({
                sender: !!to,
                recipient: !!from,
                subject: !!subject,
                body: !!html,
            }).filter(([, value]) => !value).map(([key]) => key).join(', ');

            this.context.renderer.a2u.debugLogger.log({
                type: 'error',
                message: `Missing email properties: ${notFilledProperties}`,
                data: JSON.parse(JSON.stringify({
                    processNum: this.processNum,
                    diagram: { id: this.context?.currentDiagram?.block?.properties?.diagramComponentId },
                    component: {
                        id: this.block?.id,
                        properties: this.context.renderer.a2u.patchComponentProperties(this, this.block?.properties || {}),
                    },
                    data: {},
                })),
            });

            return this.processOutgoingLinks(this.context, this.block.id, null, 'error');
        }

        try {
            const payload = [{
                to,
                from,
                subject,
                html,
            }];

            // Send the email
            this.context?.app?.client?.getAxios().post(`${endpointUrl}/sendgrid/mail`, payload, {
                headers: {
                    'x-api-key': process.env.VUE_APP_A2U_ACCESS_TOKEN,
                },
            });

            return this.processOutgoingLinks(this.context, this.block.id, null, 'success');
        } catch (e) {
            return this.processOutgoingLinks(this.context, this.block.id, null, 'error');
        }
    }

    /**
     * Retrieves the endpoint URL from the module configuration.
     *
     * This method first retrieves all modules from the context. It then finds the module
     * that is of type 'server' and has the 'sendGridEnable' property set.
     *
     * If such a module is found, its 'endpointUrl' property is returned. If no such module
     * is found, the method falls back to returning the 'VUE_APP_A2U_URL' environment variable.
     *
     * @returns {string} The endpoint URL.
     */
    _getEndpoint() {
        const module = Object.values(this.context?.renderer?.a2u?.source?.modules || {})
          .find((m) => m.type === 'server' && m?.sendGridEnable);

        return module?.endpointUrl || process.env.VUE_APP_A2U_URL;
    }
}
