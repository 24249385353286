<template>
  <div>
    {{ node.label }}
  </div>
</template>

<script>

export default {
  props: ['block', 'node'],
  name: "TreeViewDefaultItem",
  data: () => ({
  }),

  methods: {

    /**
     * On node select
     */
    onNodeSelect() {
      // Fire select event
      this.parentDiagram.processOutgoingLinks(this, this.block.id, this.node, "select")
    }
  },
}
</script>
