<template>
    <div class="ed-content" :class="{empty: ifEdit && !hasContent}" ref="content" tabindex="1" :contenteditable="isFocused" @input="setValue" @focus="focus"
         @blur="blur"
         @keydown="processKeys"
         :data-id="block.id"
         v-html="currentValue"/>
</template>

<script>

import {componentMixins} from "./componentMixins";

export default {
    mixins: [componentMixins],
    props: ["block", "modelValue"],
    emits: ["openMenu", "update:modelValue"],
    data: () => ({
        currentValue: "",
        focused: false,
        content: ""
    }),
    name: "ContentEditablePe",
    mounted() {
        // Set current value
        this.content = this.currentValue = this.modelValue || ''

        // Watch edit mode
        this.$watch("isFocused", (value) => {
            if (value) {
                // Set focus to content
                this.$nextTick(() => {
                    // Set focus to content
                    this.$refs.content.focus()
                })
            }
        })

        // Watch current value
        this.$watch("modelValue", (value) => {
            // Set current val
            if (!this.focused) {
                this.currentValue = value
                this.content = value
            }
        })
    },
    methods: {

        /**
         * Process keys
         * @param ev
         */
        processKeys(ev) {
            const key = ev.key
            switch (key) {

                // Forbid new line
                case "Enter":
                    if (this.blockEditor.singleLine) {
                        return ev.preventDefault()
                        //ev.stopPropagation()
                    }
                    break
            }
        },


        /**
         * Exit block
         */
        blur() {
            this.focused = false
        },

        /**
         * Focus block
         */
        focus() {
            this.focused = true
        },

        /**
         * Set value
         * @param event
         */
        setValue(event) {
            this.$nextTick(() => {
                // Get value
                let value = event.target.innerHTML.replace(/^<br>|<br>$/g, '');
                this.$emit("update:modelValue", value)
                this.content = value
            })
        },
    },

    computed: {
        hasContent() {
            return this.content.length > 0
        }
    }
}

</script>

<style lang="scss">

.ed-content {
    outline: none;
    display: inline;
}

.ed-content.empty {
    background: #eee;
    display: inline-block;
    width: 1em;
    min-height: 1em;
}


</style>
