<template>
  <ab-flow-base-cmp :block="block" class="three-object-cmp" />
</template>

<script>
import {shallowRef} from 'vue';
import AbFlowBaseCmp from "../Containers/Designer/AbFlowBaseCmp";
import {renderMixins} from "../renderMixins";

/**
 * Vue component for editing 3D objects.
 * @component
 */
export default {
  /**
   * Components used in this component.
   */
  components: {AbFlowBaseCmp},

  /**
   * Mixins used in this component.
   */
  mixins: [renderMixins],

  /**
   * Props passed to this component.
   */
  props: ['block'],

  /**
   * Name of this component.
   */
  name: "ThreeDObjectEditorCmp",

  /**
   * Injected properties.
   */
  inject: {
    /**
     * The plugin used for 3D rendering.
     */
    plugin: {
      default: null
    },
  },

  /**
   * Setup function for the component.
   * @returns {Object} Contains references to the object, animationMixer, and animationAction.
   */
  setup() {
    const object = shallowRef(null);
    const animationMixer = shallowRef(null);
    const animationAction = shallowRef(null);

    return { object, animationMixer, animationAction };
  },

  /**
   * Computed properties for the component.
   */
  computed: {
    /**
     * Compute the path of the 3D object.
     * @returns {string} The path of the 3D object.
     */
    objectPath() {
      const objectPath = this.block?.properties?.object;

      return this.renderer.a2u.assetPath(objectPath?.source_url || objectPath);
    },
    /**
     * Compute the path of the animation.
     * @returns {string} The path of the animation.
     */
    animationPath() {
      const animationPath = this.block?.properties?.animation;

      return this.renderer.a2u.assetPath(animationPath?.source_url || animationPath);
    },
    /**
     * Compute the position of the 3D object.
     * @returns {Array} The position of the 3D object.
     */
    position() {
      return [
        this.block?.properties?.positionX || 0,
        this.block?.properties?.positionY || 0,
        this.block?.properties?.positionZ || 0,
      ];
    },
    /**
     * Compute the scale of the 3D object.
     * @returns {Array} The scale of the 3D object.
     */
    scale() {
      return [
        this.block?.properties?.scaleX || 1,
        this.block?.properties?.scaleY || 1,
        this.block?.properties?.scaleZ || 1,
      ];
    },
    /**
     * Compute the rotation of the 3D object.
     * @returns {Array} The rotation of the 3D object.
     */
    rotation() {
      return [
        this.block?.properties?.rotationX || 0,
        this.block?.properties?.rotationY || 0,
        this.block?.properties?.rotationZ || 0,
      ];
    },
  },

  /**
   * Methods for the component.
   */
  methods: {
    /**
     * Load the 3D model.
     */
    async loadModel() {
      if (this.object) {
        this.plugin.scene.remove(this.object);
        this.object = null;
      }

      if (!this.objectPath) {
        console.error('Object path not found');

        return;
      }

      const object = await this.plugin.fbxLoader(this.objectPath);

      object.userData = {
        blockId: this.block.id,
      };

      this.object = object;
      this.object.position.set(...this.position);
      this.object.scale.set(...this.scale);
      this.object.rotation.set(...this.rotation);

      this.plugin.scene.add(this.object);

      this.animationMixer = this.plugin.createAnimationMixer(this.object);

      if (object?.animations?.length) {
        this.animationAction = this.animationMixer.clipAction(object.animations[0]);
        this.animationAction.play();
      }
    },

    /**
     * Load the animation.
     */
    async loadAnimation() {
      if (this.animationAction) {
        this.animationAction.stop();
      }

      const animationObject = await this.plugin.fbxLoader(this.animationPath);

      const animation = animationObject.animations[0];

      this.animationAction = this.animationMixer.clipAction(animation);

      this.animationAction.play();
    },
  },

  /**
   * Watchers for the component.
   */
  watch: {
    /**
     * When the plugin changes, load the 3D model.
     */
    plugin() {
      if (this.plugin.scene) {
        this.loadModel();
      }
    },
    /**
     * When the position changes, set the position of the 3D object.
     */
    position() {
      this.object.position.set(...this.position);
    },
    /**
     * When the scale changes, set the scale of the 3D object.
     */
    scale() {
      this.object.scale.set(...this.scale);
    },
    /**
     * When the rotation changes, set the rotation of the 3D object.
     */
    rotation() {
      this.object.rotation.set(...this.rotation);
    },
    /**
     * When the object path changes, load the 3D model and the animation.
     */
    async objectPath() {
      await this.loadModel();

      if (this.animationPath) {
        await this.loadAnimation();
      }
    },
    /**
     * When the animation path changes, load the animation.
     */
    animationPath() {
      this.loadAnimation();
    },
  },

  /**
   * Lifecycle hook for when the component is mounted.
   */
  async mounted() {
    if (!this.plugin?.scene) {
      console.error('Scene not found');

      return;
    }

    await this.loadModel();

    if (this.animationPath) {
      await this.loadAnimation();
    }
  },

  /**
   * Lifecycle hook for when the component is about to be unmounted.
   */
  beforeUnmount() {
    if (!this.plugin?.scene || !this.object) {
      return;
    }

    this.plugin.scene.remove(this.object);
  },
}

</script>

<style lang="scss">
.three-object-cmp {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}
</style>
