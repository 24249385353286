<template>
  <ab-flow-base-cmp :block="block" class="image-editor-cmp" :class="classesString" :style="imageStyles">
    <q-spinner
      v-if="!imageSource && isRemote"
      class="q-mx-auto q-my-auto"
      color="primary"
      size="2em"
      :thickness="10"
    />
  </ab-flow-base-cmp>
</template>

<script>

import {renderMixins} from "../../renderMixins";
import AbFlowBaseCmp from "../../Containers/Designer/AbFlowBaseCmp.vue";

export default {
  mixins: [renderMixins],
  components: {AbFlowBaseCmp},
  inject: {
    renderer: {
      default: false
    },
    animation_frame: {
      default: 0
    }
  },
  props: ['block'],
  name: "ImageEditorCmp",

  computed: {

    /**
     * Get image styles
     */
    imageStyles() {
      const styles = {
        background: `url(${this.imageBg}) no-repeat`
      }

      // Apply settings
      styles['background-size'] = this.block.properties?.fitting || 'cover'
      styles['background-position'] = this.block.properties?.position || 'center'

      // Return styles list
      return Object.assign(styles, this.stylesString);
    },

    /**
     * Compute the path value of the image.
     * @returns {string} The path value of the image.
     */
    imgPathVal() {
      return this.getValue(this.block?.properties?.image);
    },

    /**
     * Determine if the image is hosted remotely.
     * @returns {boolean} True if the image is hosted remotely, false otherwise.
     */
    isRemote() {
      const path = typeof this.imgPathVal === 'string' ? this.imgPathVal : this.imgPathVal?.source_url || '';

      return path.startsWith('remote:');
    },

    /**
     * image source
     * @return {boolean|*|boolean}
     */
    imageSource() {
      // Get path
      return this.renderer.a2u.assetPath(this.imgPathVal);
    },

    /**
     * This computed property is used to determine the background image of the component.
     * If an image source is available, it will be used as the background image.
     * Otherwise, a default image located at "../../../assets/plugs/default-image.png" will be used.
     *
     * @returns {string} The path to the image that should be used as the background.
     */
    imageBg() {
      return this.imageSource ? this.imageSource : require("../../../assets/plugs/default-image.png");
    },
  }
}

</script>

<style lang="scss">

.image-editor-cmp {
}

</style>
