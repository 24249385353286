import {BaseComponentProcessor} from "../../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class FormProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param source
     * @param source_event
     * @param action
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    processEvent(source, source_event, action, data) {
        this.diagram.getApplication().log("info", "FormProcessor.processEvent", source, source_event, action, data)
        switch (action) {
            case 'validation':
                this.block.validationResults = data
                break
        }
    }
}
