<template>
  <div>
    <div v-if="!abTests.length" class="text-subtitle1">No A/B tests found</div>

    <q-card
      v-for="diagram in abTests"
      :key="`diagram-${diagram.id}`"
      class="q-mb-lg"
    >
      <q-card-section>
        <div class="text-overline" v-text="diagram.title" />

        <q-separator class="q-my-md" />

        <div v-for="(test, idx) in diagram.tests" :key="`test-${test.id}`">
          <q-separator v-if="idx > 0" class="q-my-md" />

          <div class="text-subtitle1" v-text="`${test.title} (${test.uniqueId})`" />

          <q-option-group
            :options="test.variants"
            type="radio"
            v-model="abTestsConfig[test.id]"
          />
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
export default {
  name: 'AbTests',

  // Injected properties
  inject: ['renderer'],

  data() {
    return {
      // Configuration for A/B tests
      abTestsConfig: {},
    };
  },

  // Computed properties
  computed: {
    // A/B tests derived from the renderer's source diagrams
    abTests() {
      return (this.renderer?.source?.diagrams || []).reduce((diagrams, diagram) => {
        // If the diagram does not contain any A/B tests, return the current list of diagrams
        if (!(diagram?.source?.children || []).some((node) => node.type === 'AbTest')) {
          return diagrams;
        }

        // Add the diagram to the list of diagrams
        diagrams.push({
          id: diagram.id,
          title: diagram.title,
          // Extract A/B tests from the diagram
          tests: diagram.source.children.filter((node) => node.type === 'AbTest').map((test) => ({
            id: test.id,
            title: test.properties?.title || test.title,
            uniqueId: test.properties?.uniqueId,
            // Extract variants from the A/B test
            variants: (test.properties?.tests || []).map((variant) => ({
              label: variant.name,
              value: variant.name,
            })),
          })),
        });

        return diagrams;
      }, []);
    },
  },

  // Watchers
  watch: {
    // Watch for changes in the A/B tests configuration
    abTestsConfig: {
      handler: function (value) {
        // Update the configuration in the client when it changes
        this.app.client.config.set('settings.abtests', value);
      },
      deep: true,
    },
  },

  // Lifecycle hooks
  mounted() {
    // Initialize the A/B tests configuration from the client's configuration
    this.abTestsConfig = JSON.parse(JSON.stringify(this.app.client.config.get('settings.abtests', {})));
  },
}
</script>
