import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";
import {SoundManager} from "../../utils/soundManager";

/**
 * Code processor
 */
export class PlaySoundProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param event
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    processEvent(event, data) {

        // Get props
        const sound = this.context.getValue(this.block.properties?.soundFile, undefined, data?.input);
        const soundFile = this.context.renderer.a2u.assetPath(sound?.source_url || sound);
        const soundChannel = this.block.properties?.soundChannel;
        const duration = this.context.interpretString(this.block.properties?.duration);
        const looped = this.block.properties?.loop
        const backgroundPlay = this.block.properties?.backgroundPlay === 1;
        const title = this.context.interpretString(this.block.properties?.toolbarTitle);
        const description = this.context.interpretString(this.block.properties?.toolbarDesc);

        const A2UAudioPlayer = this.context.renderer.a2u.getDevice()?.getPlugin?.("A2UAudioPlayer");

        if (this.context.renderer.a2u.getDevice()?.getPlatform() !== 'web' && A2UAudioPlayer && backgroundPlay) {
            try {
                const playedResult = A2UAudioPlayer.playAudio({
                    path: soundFile, // string, path to audiofile
                    title, // string, title
                    description, // string, description
                    playTime: parseInt(duration) || 0, // integer, value in seconds,
                    loop: looped ? 1 : 0, // integer, 1 - loop enabled, 0 - loop disabled
                });

                if (playedResult?.status === 'error') {
                    console.error('Error playing audio', playedResult?.message);

                    return;
                }
            } catch (e) {
                console.error('Error playing audio', e);
                return;
            }
        } else { // default behavior
            // Last progress value
            let lastProgress = -1;

            // Play sound
            SoundManager.getInstance().playSound(soundChannel, soundFile, looped, backgroundPlay, (currentTime, duration) => {
                // Calculate progress
                const progress = Math.floor((currentTime / duration) * 100);

                // If progress changed
                if (progress !== lastProgress) {
                    lastProgress = progress;

                    // Process outgoing links
                    this.processOutgoingLinks(this.context, this.block.id, progress, 'play-progress');
                }
            });

            // Stop sound after delay if set
            if(duration) {
                setTimeout(() => {
                    SoundManager.getInstance().pause(soundChannel);
                }, duration * 1000);
            }
        }

        // Process stopped
        this.processOutgoingLinks(this.context, this.block.id, {}, 'played');
    }
}
