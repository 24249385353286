<template>

  <ab-flow-base-cmp class="circular-progress-cmp" :block="block" :class="classesString" :style="stylesString">
    <div class="progress-container"  :style="{width: `${size}px`, height: `${size}px`}">
      <svg class="progress-svg" viewBox="0 0 36 36">
        <!-- Progress gradient -->
        <linearGradient
          v-if="strokeGradient"
          :id="strokeGradient.id"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
          :gradientTransform="`rotate(${strokeGradient.radius}, .5, .5)`"
        >
          <stop offset="0" :stop-color="strokeGradient.fromColor" />
          <stop offset="100" :stop-color="strokeGradient.toColor" />
        </linearGradient>

        <!-- Background circle -->
        <circle cx="18" cy="18" :r="18 - strokeWidth/2" :class="`dg-background-${backColor}`" :stroke-width="strokeWidth" fill="none" />

        <!-- Progress circle -->
        <circle
          cx="18"
          cy="18"
          :r="18 - strokeWidth/2"
          :class="trackClass"
          fill="none"
          :stroke="stroke"
          :stroke-width="strokeWidth"
          :stroke-dasharray="circumference"
          :stroke-dashoffset="offset"
          :stroke-linecap="linecap"
        />
      </svg>

    </div>

  </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "../../renderMixins";
import AbFlowBaseCmp from "../../Containers/Designer/AbFlowBaseCmp.vue";
import {nanoid} from 'nanoid';

export default {
  components: {AbFlowBaseCmp},
  mixins: [renderMixins],
  props: ['block'],
  name: "CircularProgressEditorCmp",
  computed: {
    radius() {
      return 18 - this.strokeWidth / 2;
    },
    circumference() {
      return 2 * Math.PI * this.radius;
    },
    offset() {
      return this.circumference - (this.progress / 100) * this.circumference;
    },
    /**
     * This computed property determines the linecap style of the progress circle.
     * It checks the `trackRounded` property of the `block` object.
     * If `trackRounded` is equal to 1, it returns 'round', otherwise it returns 'square'.
     * @return {string} The linecap style ('round' or 'square').
     */
    linecap() {
      return this.block?.properties?.trackRounded === 1 ? 'round' : 'square';
    },

    /**
     * TextValue
     * @return {string}
     */
     progress() {
       let v = parseInt(this.getValue(this.block?.properties?.currentProgress))
      if(v<0) v = 0
      if(v>100) v = 100
      return v;
    },

    /**
     * strokeWidth
     * @return {string}
     */
    strokeWidth() {
      return this.block?.properties?.strokeWidth || 5;
    },
    /**
     * strokeWidth
     * @return {string}
     */
    size() {
      return this.block?.properties?.size || 50;
    },

    /**
     * Color
     * @return {string}
     */
     backColor() {
      return this.block?.properties?.backColor || "";
    },

    /**
     * Text color
     * @return {string}
     */
     trackColor() {
      return this.block?.properties?.trackColor || "";
    },

    /**
     * This computed property retrieves the `trackBackground` property from the `block` object.
     * @return {Object|null} The `trackBackground` object or null if it doesn't exist.
     */
    trackBackground() {
      return this.block?.properties?.trackBackground;
    },

    /**
     * Determines the CSS class for the progress circle track.
     * If the `trackBackground` property of the block is defined, it returns undefined.
     * Otherwise, it returns a string that combines 'dg-background-' with the `trackColor` property of the block.
     * @return {string|undefined} The CSS class for the progress circle track or undefined.
     */
    trackClass() {
      if (this.trackBackground) {
        return undefined;
      }

      return `dg-background-${this.trackColor}`;
    },

    /**
     * This computed property determines the stroke color of the progress circle.
     * It checks the `type` property of the `trackBackground` object.
     * If `type` is equal to 'color', it returns the `color` property of `trackBackground`, otherwise it returns null.
     * @return {string|null} The stroke color or null.
     */
    strokeColor() {
      return this.trackBackground?.type === 'color' ? this.trackBackground?.color : null;
    },

    /**
     * This computed property determines the stroke gradient of the progress circle.
     * It checks the `type` property of the `trackBackground` object.
     * If `type` is equal to 'gradient', it returns an object with gradient properties, otherwise it returns null.
     * @return {Object|null} The stroke gradient object or null.
     */
    strokeGradient() {
      return this.trackBackground?.type === 'gradient' ? {
        id: nanoid(10),
        fromColor: this.trackBackground?.fromColor,
        toColor: this.trackBackground?.toColor,
        radius: this.trackBackground?.radius,
      } : null;
    },

    /**
     * This computed property determines the stroke style of the progress circle.
     * It checks if `strokeGradient` exists.
     * If `strokeGradient` exists, it returns a URL referencing the gradient's ID.
     * Otherwise, it returns `strokeColor` or `trackColor` or '#000' if neither exist.
     * @return {string} The stroke style.
     */
    stroke() {
      if (this.strokeGradient) {
        return `url(#${this.strokeGradient.id})`;
      }

      return this.strokeColor || this.trackColor || '#000';
    },
  }
}

</script>

<style lang="scss">
.circular-progress-cmp {
  rotate: -90deg;
}

</style>
