<template>

  <ab-flow-base-cmp :draggable="false" class="native-ads" :block="block" :is_container="true">
    <div v-if="item" @click="goToAd">
      <data-provider :storage-key="block?.title" :data="new AbStorage(prepareStorageData(item))">
        <atu-components-renderer :items="block.children"/>
      </data-provider>
    </div>
  </ab-flow-base-cmp>

</template>

<script>
import {reactive} from 'vue';
import {AbStorage} from "a2u-renderer-common/src/utils/abStorage";
import {renderMixins} from "../../components/renderMixins";
import AbFlowBaseCmp from "../Containers/Designer/AbFlowBaseCmp";
import DataProvider from "../../components/DataViews/DataProvider/DataProvider.vue";
import AtuComponentsRenderer from "../../AtuComponentsRenderer.vue";

export default {
  components: {AtuComponentsRenderer, DataProvider, AbFlowBaseCmp},
  mixins: [renderMixins],
  props: ['block'],
  name: "NativeAdsEditorCmp",
  data: () => ({
    currentProduct: false,
    item: null,
  }),

  async created() {
    try {
      // Get native ad
      this.item = await this.renderer.a2u.adManager?.getNativeAd(this.block?.properties?.placement, 30) || null;
    } catch (e) {
      console.log(e);
    }
  },

  methods: {
    /**
     * Open ad in new tab
     */
    goToAd() {
      window.open(this.item.click_url, '_blank');
    },

    /**
     * Prepares the data for storage.
     *
     * This method is responsible for preparing the data that will be stored.
     * It creates an object with two properties: `initialStorage` and `storageCreator`.
     *
     * @param {Object} item - The item object that will be stored.
     * @returns {Object} The prepared data for storage. It includes:
     * - `initialStorage`: the original item object.
     * - `storageCreator`: a function that takes data as a parameter and returns a reactive version of the data.
     */
    prepareStorageData(item) {
      return {
        initialStorage: item,
        storageCreator: (data) => reactive(data),
      };
    }
  },

  computed: {
    AbStorage() {
      return AbStorage
    },
  },
}

</script>


<style lang="scss">

.native-ads {
  flex-direction: inherit;
  flex-wrap: inherit;
  justify-content: inherit;
  align-items: inherit;
  width: 100%;
  position: relative;
}

</style>
