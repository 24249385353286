<template>

  <ab-flow-base-cmp class="toolbar-cmp" :block="block" :class="classesString" :style="stylesString">
    <q-toolbar :class="bgClass">
      <div v-if="isBackButton">
        <q-btn flat round dense icon="arrow_back" @click.stop.prevent="goBack"></q-btn>
      </div>
      <div v-if="menuPosition === 'left'">
        <q-btn flat round dense icon="menu"  @click.stop.prevent="openMenu"/>
      </div>
      <q-toolbar-title>{{ title }}</q-toolbar-title>
      <div v-if="menuPosition === 'right'">
        <q-btn flat round dense icon="menu"  @click.stop.prevent="openMenu"/>
      </div>

      <div v-if="hasCloseButton">
        <q-btn flat round dense icon="close"  @click.stop.prevent="closeScreen"/>
      </div>

    </q-toolbar>



  </ab-flow-base-cmp>

  </template>

  <script>
  import AbFlowBaseCmp from "../Containers/Designer/AbFlowBaseCmp.vue";
  import {renderMixins} from "../renderMixins";

  export default {
    components: { AbFlowBaseCmp },
    mixins: [renderMixins],
    props: ['block'],
    name: "AppHeaderEditorCmp",
    methods: {

      /**
       * Click on back button
       */
      goBack() {
        this.parentDiagram.processOutgoingLinks(this, this.block.id, false,"back")
      },

      /**
       * Click on menu button
       */
      openMenu() {
        this.parentDiagram.processOutgoingLinks(this, this.block.id, false,"menu")
      },

      /**
       * Close screen
       */
      closeScreen() {
        this.parentDiagram.processOutgoingLinks(this, this.block.id, false,"close")
      }
    },
    computed: {
      /**
       * Title
       * @return {string}
       */
      title() {
        return this.interpretString(this.block.properties?.title);
      },

      /**
       * Menu position
       * @return {string}
       */
      menuPosition() {
        return this.block.properties?.menuPosition || "";
      },

      /**
       * Is back button
       * @return {boolean}
       */
      isBackButton() {
        return this.block.properties?.isBackButton || false;
      },

      /**
       * Has close button
       * @return {boolean}
       */
      hasCloseButton() {
        return this.block.properties?.hasCloseButton || false;
      },

      /**
       * Toolbar background class color
       * @return {string}
       */
      bgClass() {
        return this.block?.properties?.color ? `bg-${this.block.properties.color}` : "";
      }
    },
  }

  </script>

<style lang="scss">
.toolbar-cmp {}
</style>
