<template>
  <slot/>
</template>

<script>

import {computed} from 'vue'

export default {
  name: "DataProvider",
  props: {
    storageKey: {
      default: "unknown"
    },
    data: {
      default: () => {}
    }
  },
  inject: {
    dataContext: {
      type: Object,
      default: () => {}
    }
  },
  provide:function () {

    // Nothing to provide
    if(!this.data) return {}

    // Init inject data
    const newContext = Object.assign({}, this.dataContext)

    // Update inject data
    newContext[this.storageKey.toLowerCase()] = computed( () => {
      return this.data
    })

    //const newContext = Object.assign({},this.dataContext || {}, {[this.storageKey.toLowerCase() ]: this.data || {}});
    //console.error('DataProvider provide', this.storageKey, this.data, newContext);

    return {
      //parentWidget: this
      dataContext: newContext
    }
  },
}

</script>
