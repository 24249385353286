import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * Check ad consent processor
 */
export class ShowAdConsentProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param event
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    async processEvent(event, data) {

        // Get ad instance
        const adManager = this.context.renderer.a2u.adManager

        // Check if no Ad manager - debug response
        if(!adManager || this.context.renderer.a2u.getPlatform() === 'web') {
            return this.debugResponse({success: {}, error: {}}, `Show user ad consent dialog`)
        }

        try {
            // Cancel old alarms
            this.context.$q.loading.show({message: 'Loading...'})
            const res = await adManager.showConsentDialog();
            this.context.$q.loading.hide()
            this.processOutgoingLinks(this.context, this.block.id, false, res ? "success" : "error");
        } catch (ex) {
            console.log(ex)
            this.context.$q.loading.hide()
            this.processOutgoingLinks(this.context, this.block.id, ex, "error");
        }
    }
}
