<template>

  <ab-flow-base-cmp class="banner-cmp" :block="block" :class="classesString" :style="stylesString">
    <div class="banner-container full-width full-height">
      <atu-components-renderer v-if="testView || visibilityDefaultBanner" :items="children"/>
    </div>
  </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "../renderMixins";
import AbFlowBaseCmp from "../Containers/Designer/AbFlowBaseCmp.vue";
import AtuComponentsRenderer from '../../AtuComponentsRenderer.vue';

export default {
  components: {AtuComponentsRenderer, AbFlowBaseCmp},
  mixins: [renderMixins],
  props: ['block'],
  name: "BannerEditorCmp",
  data: () => ({
    testView: false,
    visibilityDefaultBanner: true,
    visibilityBannerTimeout: null,
  }),
  created() {

    // Check if test view
    this.testView = this.renderer?.a2u?.getPlatform?.() === "web";

    // Delay showing ad
    this.visibilityBannerTimeout = setTimeout(() => {
      // Show ad
      try {
        // Cancel old alarms
        this.renderer.a2u.adManager?.showBanner(this.context, this.block?.properties?.placement, () => {
          // Hide default banner
          this.visibilityDefaultBanner = false;
          return this.parentDiagram.processOutgoingLinks(this.context, this.block.id, false, "success");
        }, (error) => {
          this.visibilityDefaultBanner = true;
          return this.parentDiagram.processOutgoingLinks(this.context, this.block.id, error?.message, error?.type || 'error')
        }, true, 30)
      } catch (ex) {
        console.log(ex)
        return this.parentDiagram.processOutgoingLinks(this.context, this.block.id, ex, "error");
      }
    }, this.minDisplayTime);
  },
  beforeUnmount() {
    // Clear banner visibility timeout
    clearTimeout(this.visibilityBannerTimeout);

    // Hide ad
    try {
      this.renderer.a2u.adManager?.hideBanner(this.block?.properties?.placement)
    } catch (ex) {
      console.log(ex)
      return this.parentDiagram.processOutgoingLinks(this.context, this.block.id, ex, "error");
    }
  },
  computed: {
    /**
     * Placement name
     * @return {string}
     */
    placementName() {
      return this.block?.properties?.placementName || "Banner";
    },

    bannerStylesObj() {
      return ({
        "background-color": this.testView ? "#ddd" : "",
      })
    },

    /**
     * Children list
     * @return {*}
     */
    children() {
      // Return regular children
      return this.block.children
    },

    /**
     * Computed property that calculates the minimum display time for the banner.
     * It retrieves the default time from the block properties, if it exists.
     * If it doesn't exist, it defaults to 5 seconds.
     * The result is then multiplied by 1000 to convert the time to milliseconds.
     * @return {number} The minimum display time for the banner in milliseconds.
     */
    minDisplayTime() {
      return (this.block?.properties?.defaultTime || 5) * 1000;
    },
  }
}

</script>

<style lang="scss" scoped>
.banner-cmp {
  display: flex;
  justify-content: center;
  align-items: center;

  .banner-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
