<template>

  <ab-flow-base-cmp :block="block" :class="' reach-text-cmp ' + classesString" :style="stylesString" ref="html">
    <div class="reach-text" v-html="getHtml"></div>
  </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "../../renderMixins";
import AbFlowBaseCmp from "../../Containers/Designer/AbFlowBaseCmp.vue";

export default {
  components: {AbFlowBaseCmp},
  mixins: [renderMixins],
  props: ['block'],
  name: "RichTextEditorCmp",
  mounted() {
    // Replace all <a> href with @click handler
    this.$nextTick(() => {
      // Add click event to ref html, process click to the <a href> tag
      this.$refs.html.$el.addEventListener('click', this.clickLink);
    })
  },
  beforeUnmount() {
    this.$refs.html.$el.removeEventListener('click',this.clickLink);
  },
  methods: {
    /**
     * Click event
     * @param url
     */
    clickLink(e) {
      if (e.target.tagName === 'A') {
        e.preventDefault();

        // If there is no outgoing event handler, open the link in a new tab
        if (!this.hasOutgoingEventHandler) {
          window.open(e.target.href);

          return;
        }

        this.$emit('click', e.target.href);
        this.parentDiagram.processOutgoingLinks(this, this.block.id,e.target.href, "navigate")
      }
    }
  },
  computed: {
    /**
     * This computed property checks if there is an outgoing event handler for the current block.
     *
     * @returns {boolean} True if an outgoing event handler exists, false otherwise.
     */
    hasOutgoingEventHandler() {
      return (this.currentDiagram?.diagramObj?.source?.children || []).some(
        (item) => item?.type === 'link' && item?.properties?.connection?.source?.id === this.block.id,
      );
    },

    /**
     * Value
     * @return {string}
     */
    getHtml() {
      return this.interpretString(this.block.properties?.html) || "Reach Text";
    },
  },
}

</script>

<style lang="scss">
.reach-text-cmp {
  position: relative;

  .reach-text {
    width: 100%;
  }
}
</style>
