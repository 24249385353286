import {BaseComponentProcessor} from "../../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class ForEachProcessor extends BaseComponentProcessor {
    /**
     * Process incoming event
     */
    processEvent(event, data) {
        const items = data?.input || data;

        if (!Array.isArray(items)) {
            console.error('ForEach: items is not an array');
            return;
        }

        // Process items
        for (const item of items) {
            this.processOutgoingLinks(this.context, this.block.id, item, 'item');
        }

        // Process completed
        this.processOutgoingLinks(this.context, this.block.id, null, 'completed');
    }
}
