import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class KeepAwakeProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }

    async processEvent() {

        // Get ad instance
        const KeepAwake = this.context.renderer.a2u.getDevice()?.getPlugin?.("KeepAwake");

        // Check if no KeepAwake
        if(!KeepAwake || this.context.renderer.a2u.getPlatform() === 'web') {
            return;
        }

        const state = this.block?.properties?.state || 0;

        state === 1 ? await KeepAwake.keepAwake() : await KeepAwake.allowSleep();
    }
}
