import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class CheckRoleFunctionProcessor extends BaseComponentProcessor {
    /**
     * Process incoming event
     */
    processEvent() {
        if(this.app.renderer.a2u.runMode === "debug") {
            return this.debugResponse({
                yes: {},
                no: {},
            });
        }

        // Get roles list
        const roles = this.block.properties?.roles?.split(',') || []

        // Check if user has role
        return this.processOutgoingLinks(this.context, this.block.id, {}, this.app.app.auth().hasRole(roles) ? 'yes' : 'no');
    }
}
