export const constants = {
  sizes_to_pixels: {
    xs: 4,
    sm: 8,
    md: 16,
    lg: 24,
    xl: 36,
    xxl: 48
  },
  size_to_font_size: {
    xs: 8,
    sm: 10,
    md: 14,
    lg: 20,
    llg: 22,
    xl: 24,
    xxl: 28,
  },
}
