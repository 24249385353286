<template>

  <ab-flow-base-cmp
    class="roulette-selector"
    :block="block"
    :class="classesString"
    :style="`--roulette-line-height: ${lineHeight};--roulette-height: ${height};` + stylesString"
  >
      <vue-scroll-picker ref="selector" @update:modelValue="changed" v-model="currentValue" :options="options" :stripes-color="stripesColor" background-color="transparent" :options-color="optionsColor" :selection-color="selectionColor"/>
  </ab-flow-base-cmp>

  </template>

  <script>
  import AbFlowBaseCmp from "../../Containers/Designer/AbFlowBaseCmp.vue";
  import {renderMixins} from "../../renderMixins";
  import {VueScrollPicker} from "./VueScrollPicker/VueScrollPicker";
  import "./VueScrollPicker/styles.css"
  import {formComponentsMixins} from "../formComponentsMixins";

  export default {
    components: { AbFlowBaseCmp, VueScrollPicker },
    mixins: [renderMixins, formComponentsMixins],
    props: ['block'],
    name: "RouletteSelectorEditorCmp",
    data: () => ({
      value: "",
      opts: [],
    }),

    created() {
      // Watch state
      this.$watch("parentFragment.isShown", (newVal, oldVal) => {
        if(newVal !== oldVal) this.$refs.selector.resize();
      })
    },

    methods: {

      /**
       * Value updated
       * @param event
       */
      changed() {
        //if(event !== undefined && event != this.currentValue) this.parentDiagram.processOutgoingLinks(this, this.block.id, event, "changed")
      }
    },
    computed: {
      /**
       * Computed property that returns the position of extra options.
       * @return {string} The position of extra options. If the `manualOptionsState` property of the block is not defined, it returns 'none'.
       */
      extraOptionsPosition() {
        return this.block.properties?.manualOptionsState || 'none';
      },

      /**
       * Computed property that returns an array of extra options.
       * Each option is an object with `title` and `value` properties.
       * If the `extraOptionsPosition` is 'none', it returns an empty array.
       * If the `manualOptions` property of the block is not defined, it also returns an empty array.
       * @return {Array} An array of extra options.
       */
      extraOptions() {
        return (this.block.properties?.manualOptions?.items || [])
          .map((v) => ({name: v.label, value: v.value}));
      },

      /**
       * Options list
       * @return {*}
       */
      options() {
        // Main options
        const mainOptions = (this.getValue(this.block?.properties?.options) || []).map(v => ({name: v.title, value: v.value}));

        // Extra options position
        const extraOptsPos = this.extraOptionsPosition;

        // Options
        const opts = [];

        // If extra options should be before main options
        if (extraOptsPos === 'before' && this.extraOptions.length) {
          opts.push(...this.extraOptions);
        }

        // Main options
        opts.push(...mainOptions);

        // If extra options should be after main options
        if (extraOptsPos !== 'before' && this.extraOptions.length) {
          opts.push(...this.extraOptions);
        }

        // return options
        return opts;
      },

      /**
       * Options color
       * @return {*}
       */
      optionsColor() {
        return this.renderer.a2u.getColor(this.block?.properties?.optionsColor || "primary", "foreground")
      },

      /**
       * Selection color
       * @return {*}
       */
      selectionColor() {
        return this.renderer.a2u.getColor(this.block?.properties?.selectionColor || "info", "foreground")
      },


      /**
       * Stripes color
       * @return {*}
       */
      stripesColor() {
        return this.renderer.a2u.getColor(this.block?.properties?.stripesColor || "primary", "foreground")
      },

      /**
       * Line height
       * @return {*}
       */
      lineHeight() {
        return `${this.block?.properties?.lineHeight || 1.2}em`;
      },

      /**
       * Height
       * @return {*}
       */
      height() {
        // Get line height
        const lineHeight = parseFloat(this.block?.properties?.lineHeight || 1.2);

        // Calculate height
        // 5 - default height
        // 1.2 - default line height
        const height = 5 / 1.2 * lineHeight;

        return `${height.toFixed(4)}em`;
      },
    },
  }

  </script>

<style lang="scss">

.roulette-selector {
  display: flex;
  flex-grow: 1;

  .vue-scroll-picker {
    height: var(--roulette-height, 5em);
    mask-image: linear-gradient(to bottom, transparent, black calc(50% - 1em), black calc(100% - calc(50% - 1em)), transparent);
    mask-size: 100%;
    mask-position: 0 0;
    mask-repeat: no-repeat;
  }

  .vue-scroll-picker-layer-top, .vue-scroll-picker-layer-bottom {
    background: none !important;
  }

  .vue-scroll-picker-item {
    line-height: var(--roulette-line-height, 1.2em);
  }
}
</style>
