<template>

  <ab-flow-base-cmp class="linear-progress-cmp" :block="block" :class="classesString" :style="stylesString">
    <div class="progress-container" :class="`dg-background-${backColor}`">
      <div class="progress-track" :style="`width:${progress}%`" :class="`dg-background-${trackColor}`"></div>
    </div>
  </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "../../renderMixins";
import AbFlowBaseCmp from "../../Containers/Designer/AbFlowBaseCmp.vue";

export default {
  components: {AbFlowBaseCmp},
  mixins: [renderMixins],
  props: ['block'],
  name: "LinearProgressEditorCmp",
  computed: {

    /**
     * TextValue
     * @return {string}
     */
    progress() {
      // Get progress value
      const prVal = this.getValue(this.block?.properties?.currentProgress);
      return prVal === undefined ? 50 : prVal;
    },


    /**
     * Color
     * @return {string}
     */
    backColor() {
      return this.block?.properties?.backColor || "";
    },

    /**
     * Text color
     * @return {string}
     */
    trackColor() {
      return this.block?.properties?.trackColor || "";
    },
  }
}

</script>

<style lang="scss">
.linear-progress-cmp {
  min-height: 2px !important;

  .progress-container {
    width: 100%;
    height: 100%;
  }

  .progress-track {
    height: 100%;
    min-height: 1px;
  }
}

</style>
