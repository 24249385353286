/**
 * The EventEmitter class is used to create an event-driven architecture.
 * It allows you to register listeners for specific events, remove listeners, and emit events.
 */
export class EventEmitter {
  /**
   * The constructor initializes an empty object to store the events and their listeners.
   */
  constructor() {
    this.events = {};
  }

  /**
   * The 'on' method is used to register a listener for a specific event.
   * If the event does not exist, it creates an empty array for the event.
   * It then adds the listener to the array of listeners for the event.
   *
   * @param {string} event - The name of the event.
   * @param {Function} listener - The listener function to register for the event.
   */
  on(event, listener) {
    if (typeof this.events[event] !== 'object') {
      this.events[event] = [];
    }

    this.events[event].push(listener);
  }

  /**
   * The 'removeListener' method is used to remove a listener from a specific event.
   * It filters out the listener from the array of listeners for the event.
   *
   * @param {string} event - The name of the event.
   * @param {Function} listener - The listener function to remove from the event.
   */
  removeListener(event, listener) {
    this.events[event] = this.events[event]?.filter(l => l !== listener);
  }

  /**
   * The 'emit' method is used to emit a specific event.
   * It calls all the listener functions registered for the event with the provided arguments.
   *
   * @param {string} event - The name of the event.
   * @param {...any} args - The arguments to pass to the listener functions.
   */
  emit(event, ...args) {
    for (const listener of this.events[event] || []) {
      listener(...args);
    }
  }
}
