import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class GetAnAppPurchasedAmountProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param event
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    processEvent(event, data) {

        // Get products list
        const productId = this.context.interpretString(this.block?.properties?.productId)?.trim();

        const targetPlatform = this.context.renderer.a2u.source.modules[this.context.renderer.a2u.getModuleId()]?.type === 'web' ? 'web' : 'mobile';

        // Debug list of goods for web environment
        if(this.context.renderer.a2u.getPlatform() === "web" && targetPlatform !== 'web') {
            return this.debugResponse({count: {}}, `Bought amount: ${productId} ?`)
        }

        // Get ad instance
        const iapManager = this.context.renderer.a2u.getDevice()?.getPluginInstance?.("IAPManager");

        // Get bought amount
        iapManager.ownedAmount(productId).then(count => {
            this.processOutgoingLinks(this.context, this.block.id, count, "count");
        }).catch(e => {
            console.error("Error check purchase", e);
            this.processOutgoingLinks(this.context, this.block.id, 0, "count");
        })
    }
}
