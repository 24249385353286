export const evalHelper = {

    /**
     * Eval code in vars context
     * @param func
     * @param data
     * @return {undefined|*}
     */
    evalInContext(func, data) {
        try {
            return new Function("v", "with (v) { " + func + " }")(data ? data : {})
        } catch (ex) {
            return undefined
        }
    }
}
