<template>
  <div v-if="isVisible" class="editor-cmp" v-on="blockEvents">
    <slot/>
  </div>
</template>

<script>

import {renderMixins} from "../../renderMixins";

export default {
  mixins: [renderMixins],
  inject: {
    renderer: {
      default: null
    },
    parentDiagram: {
      default: null
    },
  },
  name: "AbFlowBaseCmp",
  props: {
    block: {
      default: null
    }
  },
  data: () => ({
    blockEvents: {},
    eventsList: {click: ['click'], 'start-click': ['mousedown', 'touchstart'], 'end-click': ['mouseup', 'touchend']}
  }),
  created() {
    // Setup events
    for (const [event, nativeEvents] of Object.entries(this.eventsList)) {
      if (this.block.properties?.events?.find(e => e.name === event)) {
        for (const ne of nativeEvents) {
          this.blockEvents[ne] = (e) => {
            this.parentDiagram.processOutgoingLinks(this, this.block.id, {}, event);
            e.preventDefault();
            e.stopPropagation();
          }
        }
      }
    }
  },

  computed: {

    /**
     * Check if block has visibility condition
     * @return {*}
     */
    isVisible() {

      if (this.block.properties?.visibility) {
        // Get variable value
        const vVal = this.getValue(this.block.properties?.visibility?.variable)

        // Value to compare
        let vValToCompare = !!vVal

        // Decide how to compare value
        switch (typeof vVal) {
          case 'object':
            vValToCompare = !(!vVal || !Object.keys(vVal).length);
            break;
          case 'boolean':
            vValToCompare = vVal;
            break;
          default:
            vValToCompare = !!vVal;
        }

        // Get value to compare
        const vCompare = this.block.properties?.visibility?.variableValue === 'true';

        // Return result
        return vValToCompare === vCompare
      }

      // Return true
      return true;
    }
  }
}

</script>

<style lang="scss">

.editor-cmp {
  display: flex;
  min-height: 1em;
  min-width: 1em;
}
</style>
