import {BaseComponentProcessor} from "../../../utils/processor/BaseComponentProcessor";

/**
 * Show privacy options processor
 */
export class ShowPrivacyOptionsProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param event
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    async processEvent(event, data) {

        // Get ad instance
        const adManager = this.context.renderer.a2u.adManager

        // Check if no Ad manager - debug response
        if(!adManager || this.context.renderer.a2u.getPlatform() === 'web') {
            return this.debugResponse({success: {}, error: {}}, `Show GDPR privacy options dialog`)
        }

        try {
            // Show loading dialog
            this.context.$q.loading.show({message: 'Loading...'})

            // Show privacy options
            const res = await adManager.showPrivacyOptions();

            // Process outgoing links
            this.processOutgoingLinks(this.context, this.block.id, false, res ? "success" : "error");
        } catch (ex) {
            console.log(ex);
            this.processOutgoingLinks(this.context, this.block.id, ex, "error");
        } finally {
            // Hide loading dialog
            this.context.$q.loading.hide()
        }
    }
}
