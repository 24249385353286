//import {StorageNode} from "@/../../common/db/StorageNode"

export default {


    // Save db
    save: async () => {
        /*console.log("Save: ", data.length)
        localStorage.setItem('db', btoa(String.fromCharCode.apply(null, data)))*/
    },

    // Load db
    load: async () => {
        /*const dta = localStorage.getItem('db');
        console.log("Loading db...")
        return dta ? new Uint8Array(atob(dta).split('').map(char => char.charCodeAt(0))) : false;*/
    },

    webWasmFile: () => "/js/sql-wasm.wasm",

    models: [
        //StorageNode,
    ]
}
