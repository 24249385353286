<template>
  <q-layout
    class="page-layout"
    style="height:100%;"
    view="lHh Lpr lFf"
    v-if="isReady"
    ref="pageContainer"
  >

    <q-page-container style="height:100%;" class="dg-background-primary dg-foreground-primary">
      <q-page v-if="isReady && startDiagram" class="render-service dg-media" :class="pageClasses" :style="pageStyles">

        <template v-if="runWithDebugConsole">
          <q-dialog
              v-model="debugConsoleVisible"
              persistent
              maximized
              transition-show="slide-up"
              transition-hide="slide-down"
          >
            <q-card>
              <q-card-section class="q-pa-none">
                <atu-debug-console />
              </q-card-section>

              <q-separator/>

              <q-card-actions align="right" class="text-primary">
                <q-btn flat label="Ok" @click="hideDebugConsole" />
              </q-card-actions>
            </q-card>
          </q-dialog>
        </template>

        <data-provider storage-key="app" :data="a2u.storage">
          <diagram-component-editor-cmp ref="mainDiagram" :block="startDiagram" :start-event="debugStartEvent"/>
        </data-provider>

        <q-page-sticky
            v-if="runWithDebugConsole"
            v-show="!debugConsoleVisible"
            class="debug-console-sticky"
            position="bottom-left"
            :offset="[18, 18]"
        >
          <q-btn
              class="debug-console-btn"
              icon="terminal"
              color="red"
              padding="sm"
              size="xs"
              rounded
              @click="debugConsoleVisible = true"
          />
        </q-page-sticky>

      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
import {markRaw} from "vue";
import {builtInCodeFunctions} from "a2u-renderer-common/src/code-library/builtInCodeFunctions";
import "./styles.scss"
import DiagramComponentEditorCmp from "./components/Logic/DiagramComponent/DiagramComponentEditorCmp.vue";
import DataProvider from "./components/DataViews/DataProvider/DataProvider.vue";
import {AppParser} from "./utils/appParser";
import AtuDebugConsole from './AtuDebugConsole.vue';

export default {
  name: "AppExecutor",
  components: {DataProvider, DiagramComponentEditorCmp, AtuDebugConsole},
  props: ['source', 'moduleId', 'language', 'functions', 'models', 'nativeComponents', 'debug', 'startEvent', 'dbConfig', 'runMode', 'dbServerUrl', 'plugins', 'onInit', 'onDestroy', 'platform', 'designerComponentsList'],
  provide() {
    return {
      renderer: this
    }
  },
  data: () => ({
    currentDiagramParams: {},
    startDiagram: false,
    currentFragmentId: false,
    navigationPath: [],
    navigationKey: "-",
    diagramsStack: [],
    listOfPageType: ['Fragment', 'AdInterstitial', 'AdAppOpen', 'AdRewarded'],
    navigationDiagram: false,
    isReady: false,
    stylesSource: {},
    animationTimer: false,
    animationFrame: 0,
    animationDuration: 0,
    sideBarShown: false,
    sideBarBlock: false,
    route: window.location.hash,
    a2u: false,
    runWithDebugConsole: false, // this.runMode !== 'release',
    debugConsoleVisible: false, // Visibility dialog with debug console
    allowDiagramRendering: true, // Allow to render diagram
    currentState: false,
    currentLanguage: 'en',
  }),

  // Set vars
  setup() {
    return {
      sideBar: false,
      mainPage: false
    }
  },

  /**
   * On create
   * @return {Promise<void>}
   */
  async created() {

    // Create app parser and set source
    this.a2u = markRaw(new AppParser({
      context: this,
      dbServerUrl: this.dbServerUrl,
      dbConfig: this.dbConfig,
      runMode: this.runMode,
      nativeComponents: this.nativeComponents,
      designerComponentsList: this.designerComponentsList,
      functions: Object.assign({}, builtInCodeFunctions, this.functions || {}),
      plugins: this.plugins,
      models: this.models,
      onInit: this.onInit,
      onDestroy: this.onDestroy,
      platform: this.platform,
      moduleId: this.moduleId,
    })).setSource(markRaw(this.source));

    // Init current state
    this.initCurrentAppState();

    // Parse app
    if (!await this.a2u.parseApp()) {
      throw "Can't parse app";
    }

    // Add state watcher
    this.$watch('stateChanged', () => {
    });

    // Set debug console visibility
    this.runWithDebugConsole = this.runMode !== 'release';
    //this.debugConsoleVisible = this.runMode !== 'release';

    // Set root diagram
    this.startDiagram = this.a2u.blocks['root']?.node;

    // Add body class
    document.body.classList.add('dg-media');

    // Apply styles
    const st = document.createElement("style");
    st.innerHTML = this.a2u.getHtmlStyles();
    document.body.appendChild(st);

    try {
      // Get localization integration
      const localization = this.source?.localization || {};

      // Get available locales
      const language = this.getAppLanguage();

      // Set fallback language
      this.app.client.setDefaultLanguage(language);

      // Set current language
      if (this.runMode !== 'release') {
        this.currentLanguage = this.app.client.config.get('settings.applocale', language);
      } else {
        this.currentLanguage = language;
      }

      // Set current language
      this.app.client.changeLanguage(this.currentLanguage);

      // Add localization resources
      Object.entries(JSON.parse(JSON.stringify(localization.resources))).forEach(([lang, messages]) => {
        this.app.client.setTranslateResources(lang, messages);
      });
    } catch (e) {
      console.error('Error while initializing localizations', e);
    }

    // Ready
    this.isReady = true
  },

  computed: {

    /**
     * On state changed
     */
    stateChanged() {

      // Update sound manager state
      this.a2u.stateChanged()

      // All ok
      return true;
    },

    /**
     * This computed property is used to determine the start event for the application.
     * The start event is determined based on the run mode of the application.
     * If the run mode is "release", the start event is the one passed as a prop to the component.
     * Otherwise, it retrieves the start event from the application's client configuration, with a fallback to the start event passed as a prop.
     *
     * @return {string} The start event for the application.
     */
    debugStartEvent() {
      // If the application is in "release" mode, return the start event passed as a prop
      if (this.a2u.runMode === "release") {
        return this.startEvent;
      }

      // Otherwise, retrieve the start event from the application's client configuration
      // If it's not set in the configuration, fallback to the start event passed as a prop
      return this.app.client.config.get('settings.startevents', this.startEvent);
    },

    /**
     * Computes the maximum width for the page based on the module settings.
     * @returns {number|null} The maximum width of the page in pixels, or null if not applicable.
     */
    pageMaxWidth() {
      const module = this.a2u?.source?.modules?.[this.a2u?.moduleId];

      if (module?.type !== 'web') {
        return null;
      }

      return module?.settings?.styles?.page?.maxWidth || null;
    },

    /**
     * Computes the styles for the page based on the maximum width.
     * @returns {Object} An object containing the style properties for the page.
     */
    pageStyles() {
      if (!this.pageMaxWidth) {
        return {};
      }

      return {
        maxWidth: `${this.pageMaxWidth}px`
      };
    },

    /**
     * Computes the CSS classes for the page based on the maximum width.
     * @returns {Array<string>} An array of CSS class names.
     */
    pageClasses() {
      if (!this.pageMaxWidth) {
        return [];
      }

      return [
        'q-ml-auto',
        'q-mr-auto',
      ]
    },
  },

  methods: {

    /**
     * Initialize current app state for debug purposes.
     * This object will contain a nested path of diagrams with params: {diagramId, pageId}
     * App will load state from local storage and save on change
     */
    initCurrentAppState() {

      // Init state if not release mode
      if (this.runMode !== 'release' && !!this.app.client.config.get('settings.appLockState', false)) {

        // Get state from local storage
        try {
          this.currentState = JSON.parse(this.app.client.config.get('settings.appLockState', "{}")) || {};
        } catch (e) {
          this.currentState = {};
        }

        // Watch current app state
        this.$watch("currentState", (newState) => {
          console.log('Current state changed', newState)
          this.app.client.config.set('settings.appLockState', JSON.stringify(newState));
        }, {deep: true});

      }
    },

    /**
     * Get app to up application instance
     * @return {boolean|*}
     */
    getA2U() {
      return this.a2u
    },

    /**
     * This method is used to hide the debug console.
     * It sets the `debugConsoleVisible` data property to false, effectively hiding the console.
     * It also sets the `allowDiagramRendering` data property to true, allowing the diagram to be rendered.
     */
    hideDebugConsole() {
      this.debugConsoleVisible = false;
    },

    /**
     * Get app language
     * @return {string}
     */
    getAppLanguage() {
      // Get localization settings
      const localization = this.source?.localization || {};

      // Get available locales
      const availableLocales = localization?.additionalLocales || [];

      // If language is set and available
      if (this.language && availableLocales.includes(this.language)) {
        return this.language;
      }

      // If english is available
      if (availableLocales.length && availableLocales.includes('en')) {
        return 'en';
      }

      // Return first available locale
      return availableLocales[0] || 'en';
    },
  },

  /**
   * On destroy
   */
  beforeUnmount() {
    //window.removeEventListener('hashchange', this.updatePath)
    this.a2u.dispose();
  },
}

</script>

<style lang="scss">

.render-service {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.debug-console-sticky {
  z-index: 10000;
}

.debug-console-btn {
  opacity: .4;
}

</style>
