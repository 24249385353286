<template>
  <ab-flow-base-cmp :block="block" class="three-scene-cmp dg-extended">
    <div ref="container"></div>

    <q-resize-observer @resize="onResize" />

    <atu-components-renderer
      v-if="isInitialized"
      :items="children"
    />

  </ab-flow-base-cmp>
</template>

<script>
import {provide, shallowRef} from 'vue';
import AbFlowBaseCmp from "../Containers/Designer/AbFlowBaseCmp";
import AtuComponentsRenderer from "../../AtuComponentsRenderer";
import {renderMixins} from "../renderMixins";

/**
 * Vue component for creating a 3D scene editor.
 * @component
 */
export default {
  /**
   * Components used in this component.
   */
  components: {AtuComponentsRenderer, AbFlowBaseCmp},

  /**
   * Mixins used in this component.
   */
  mixins: [renderMixins],

  /**
   * Props passed to this component.
   */
  props: ['block'],

  /**
   * Name of this component.
   */
  name: "ThreeDSceneEditorCmp",

  /**
   * Setup function for the component.
   * @returns {Object} Contains a reference to the plugin.
   */
  setup() {
    const plugin = shallowRef(null);

    provide('plugin', plugin);

    return { plugin };
  },

  /**
   * Data properties for the component.
   */
  data() {
    return {
      isInitialized: false,
      size: {width: 100, height: 100},
    };
  },

  /**
   * Computed properties for the component.
   */
  computed: {
    /**
     * Compute the children of the block.
     * @returns {Array} The children of the block.
     */
    children() {
      return this.block.children
    },
    /**
     * Compute the background color of the block.
     * @returns {string} The background color of the block.
     */
    bgColor() {
      return this?.block?.properties?.bgColor || '#eee';
    },
    // Compute the position of the camera
    cameraPosition() {
      return [
        this.block?.properties?.cameraPositionX || 0,
        this.block?.properties?.cameraPositionY || 3,
        this.block?.properties?.cameraPositionZ || 10,
      ];
    },
    // Compute the look at position of the camera
    cameraLookAt() {
      return [
        this.block?.properties?.cameraLookAtX || 0,
        this.block?.properties?.cameraLookAtY || 5,
        this.block?.properties?.cameraLookAtZ || 0,
      ];
    },
  },

  /**
   * Methods for the component.
   */
  methods: {
    /**
     * Handle the resize event.
     * @param {Object} size - The new size.
     */
    onResize (size) {
      this.size = size;
    },

    /**
     * Initialize the scene.
     */
    initScene() {
      const plugin = this.renderer?.a2u?.getDevice()?.getPlugin('ThreePlugin');

      if (!plugin) {
        console.error('ThreePlugin not found.');
        return;
      }

      this.plugin = new plugin({
        container: this.$refs.container,
        size: this.size,
        bgColor: this.bgColor,
        cameraPosition: this.cameraPosition,
        cameraLookAt: this.cameraLookAt,
      });

      this.plugin.initScene();

      this.isInitialized = true;
    },
  },

  /**
   * Watchers for the component.
   */
  watch: {
    /**
     * Watch for changes in the background color and update the scene background.
     */
    bgColor(val) {
      this.plugin.setSceneBackground(val);
    },
    /**
     * Watch for changes in the size and resize the scene.
     */
    size: {
      handler() {
        this.plugin.resizeScene(this.size);
      },
      deep: true,
    },
    // Watch the camera position and update the camera when it changes
    cameraPosition() {
      this.plugin.setCameraPosition(this.cameraPosition)
    },
    // Watch the camera look at position and update the camera when it changes
    cameraLookAt() {
      this.plugin.setCameraLookAt(this.cameraLookAt);
    },
  },

  /**
   * Lifecycle hook for when the component is mounted.
   * Initialize the scene.
   */
  mounted() {
    try {
      this.initScene();
    } catch (e) {
      console.error('Error initializing scene:', e);
    }
  },
}

</script>

<style lang="scss">
.three-scene-cmp {
  position: relative;
}
</style>
