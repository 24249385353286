import {BaseComponentProcessor} from '../../utils/processor/BaseComponentProcessor';

export class BaseToolProcessor extends BaseComponentProcessor{
  // The name of the tool where the error occurred.
  toolName = 'n/a';

  /**
   * Logs a debug message with detailed component and process information.
   * This method is used for logging error messages during the debugging process. It constructs a detailed
   * log object that includes the error message, the process number, the current diagram ID, the component ID,
   * and the component properties. This detailed log is then passed to the `debugLogger.log` method of the
   * application's renderer context. The method ensures that all sensitive data is cloned and sanitized
   * before logging to avoid any unintended mutations.
   *
   * @param {string|Object} error - The error to be logged.
   */
  _debugLog(error) {
    let message;
    let data = {};

    if (error && typeof error === 'object') {
      message = error?.payload?.message || error?.message || `Error calling tool ${this.toolName}`;
      data = error?.payload || {};
    } else {
      message = error;
    }

    this.context.renderer.a2u.debugLogger.log({
      type: 'error',
      message: `${this.toolName}: ${message}`,
      data: JSON.parse(JSON.stringify({
        processNum: this.processNum,
        diagram: { id: this.context?.currentDiagram?.block?.properties?.diagramComponentId },
        component: {
          id: this.block?.id,
          properties: this.context.renderer.a2u.patchComponentProperties(this, this.block?.properties || {}),
        },
        data,
      })),
    });
  }
}
