import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";
import {SoundManager} from "../../utils/soundManager";

/**
 * Stop sound processor
 */
export class StopSoundProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param event
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    processEvent(event, data) {

        // Get props
        const soundChannel = this.block.properties?.soundChannel;

        // Retrieve A2UAudioPlayer plugin
        const A2UAudioPlayer = this.context.renderer.a2u.getDevice()?.getPlugin?.("A2UAudioPlayer");

        if (this.context.renderer.a2u.getDevice()?.getPlatform() !== 'web' && A2UAudioPlayer && soundChannel === 'background') {
            try {
                // Stop sound
                const result = A2UAudioPlayer.stopAudio();

                // Check result
                if (result?.status === 'error') {
                    console.error('Error playing audio', result?.message);

                    return;
                }
            } catch (e) {
                console.error('Error stopping audio', e);
            }
        } else {
            // Stop sound
            SoundManager.getInstance().disposeChannel(soundChannel);
        }

        // Process stopped
        this.processOutgoingLinks(this.context, this.block.id);
    }
}
