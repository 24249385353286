<template>
  <ab-flow-base-cmp class="navigation-panel row" :block="block" :class="classesString" :style="stylesString">
    <diagram-component-editor-cmp v-if="componentProperties?.properties?.diagramComponentId" :key="componentProperties?.properties?.diagramComponentId" :block="componentProperties"/>
  </ab-flow-base-cmp>
</template>

<script>

import DiagramComponentEditorCmp from "../Logic/DiagramComponent/DiagramComponentEditorCmp.vue";
import AbFlowBaseCmp from "../Containers/Designer/AbFlowBaseCmp.vue";
import {renderMixins} from "../renderMixins";

export default {
  components: {AbFlowBaseCmp, DiagramComponentEditorCmp},
  mixins: [renderMixins],
  props: {
    block: {},
  },
  name: "WidgetRendererEditorCmp",
  computed: {

    /**
     * Component properties
     * @return {*}
     */
    componentProperties() {
      return this.wait("componentProperties", async () => {
        // Get component alias
        const alias = this.interpretString(this.block?.properties?.alias);

        // Find diagram component by alias
        const diagram = Object.values(this.renderer.a2u.diagrams).find(e => e.alias === alias);

        // Find diagram component by alias
        return diagram ? {
          id: this.block?.id,
          properties: {
            diagramComponentId: diagram?.id
          }
        } : {}
      }, {})
    }
  }
}

</script>
