<template>

  <q-intersection once @visibility="onVisibilityChange" :root="rootEl">
    <ab-flow-base-cmp class="ab-intersection-editor-cmp" :block="block" />
  </q-intersection>

</template>

<script>

import {renderMixins} from "../../renderMixins";
import AbFlowBaseCmp from "../../Containers/Designer/AbFlowBaseCmp.vue";

export default {
  components: {AbFlowBaseCmp},
  mixins: [renderMixins],
  props: ['block', 'type'],

  name: "IntersectionEditorCmp",

  data() {
    return {
      rootEl: document.body
    }
  },

  methods: {
    onVisibilityChange(visible) {
      if (visible) {
        this.parentDiagram.processOutgoingLinks(this, this.block.id, true, "visible");
      }
    }
  },
}

</script>

<style lang="scss" scoped>
.ab-intersection-editor-cmp {
  display: block;
  height: 1px;
  min-height: 1px !important;
  width: 100%;
  margin-top: -1px;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}
</style>
