<template>

  <ab-flow-base-cmp class="tableview-cmp" :block="block" :class="classesString" :style="stylesString">
    <q-table
        :columns="columns"
        :rows="items"
        row-key="id"
        v-model:selected="selectedIds"
        :selection="selectionMode"
    >
      <template v-slot:header="props">
        <q-tr :props="props">
          <q-th v-if="selectionAllowed"/>
          <q-th
              v-for="col in props.cols"
              :key="col.name"
              :props="props"
          >
            {{ col.label }}
          </q-th>
        </q-tr>
      </template>

      <template v-slot:body="props">
        <q-tr :props="props" @click="rowClick(props.row)">
          <q-td v-if="selectionAllowed">
            <q-toggle v-model="props.selected" />
          </q-td>
          <q-td
              v-for="col in props.cols"
              :key="col.name"
              :props="props"
          >
            <template v-if="cellSlots[col.name]">
              <data-provider :storage-key="block?.title" :data="new AbStorage(prepareStorageData(props.row))">
                <atu-components-renderer :items="cellSlots[col.name].children"/>
              </data-provider>

            </template>
            <template v-else>

              <template v-if="col.type === 'file'">
                file: {{ col.value?.source_url }}
              </template>
              <template v-else>
                {{ col.value }}
              </template>

            </template>
          </q-td>
        </q-tr>
      </template>
    </q-table>

  </ab-flow-base-cmp>

</template>

<script>
import {reactive} from 'vue';
import {AbStorage} from "a2u-renderer-common/src/utils/abStorage";
import AbFlowBaseCmp from "../../Containers/Designer/AbFlowBaseCmp.vue";
import {renderMixins} from "../../renderMixins";
import DataProvider from "../../DataViews/DataProvider/DataProvider.vue";
import AtuComponentsRenderer from "../../../AtuComponentsRenderer.vue";
import {formComponentsMixins} from "../../Form/formComponentsMixins";

export default {
  components: {AtuComponentsRenderer, DataProvider, AbFlowBaseCmp},
  mixins: [renderMixins, formComponentsMixins],
  props: ['block'],
  name: "TableViewEditorCmp",
  data: () => ({
    selectedIds: []
  }),

  created() {
    // Get final var type
    this.targetVarMeta = this.getVarMeta(this.block.properties?.field)

    // Check for array
    if (this.targetVarMeta?.isArray && (this.currentValue === undefined || !Array.isArray(this.currentValue))) {

      // Init array
      this.currentValue = []
    }

    // Watch selections and update current value
    this.$watch("selectedIds", () => {

      // Set value
      if (this.selectionMode === "single") this.currentValue = this.selectedIds?.[0]?.id
      else this.currentValue = this.selectedIds.map(item => item?.id)

      // Process selected event
      this.parentDiagram.processOutgoingLinks(this, this.block.id, this.currentValue, "selected")
    }, {deep: true})
  },

  methods: {

    /**
     * Row click event
     * @param e
     * @param row
     */
    rowClick(row) {
      this.parentDiagram.processOutgoingLinks(this, this.block.id, row, "row-click")
    },

    /**
     * Prepare storage data
     * @param row
     * @return {{initialStorage: {item: {number: number}}, storageCreator: (function(*): object)}}
     */
    prepareStorageData(row) {
      return {
        initialStorage: {item: Object.assign({number: 1}, row)},
        storageCreator: (data) => reactive(data),
      };
    }
  },

  computed: {

    /**
     * If selection is allowed
     * @return {boolean}
     */
    selectionAllowed() {
      return !!this.block.properties?.field
    },

    /**
     * Return multiple
     * @return {string}
     */
    selectionMode() {
      return Array.isArray(this.currentValue) ? 'multiple' : 'single'
    },

    /**
     * Return storage
     * @return {AbStorage}
     * @constructor
     */
    AbStorage() {
      return AbStorage
    },

    /**
     * Get cell slots
     */
    cellSlots() {

      // Get slides list
      const slides = Object.fromEntries((this.renderer.a2u.links.outgoing[this.block.id]?.slot || [])?.map(l => [l.unique, l.target]))
      const frs = {};

      // Get slides
      for (const [key, sl] of Object.entries(slides)) {
        const fr = this.renderer.a2u.blocks[sl]?.node
        if (fr) frs[key] = fr
      }

      // Return frames
      return frs
    },


    /**
     * Return fields list
     * @return {*|*[]}
     */
    columns() {
      return (this.block?.properties?.fields?.items || []).map(i => ({
        name: i.name,
        type: i.type,
        label: i.title,
        field: i.name,
      }))
    },

    /**
     * Return fields list
     * @return {*}
     */
    fields() {
      return this.getValue(this.block?.properties?.fields)
    },

    /**
     * Return items list
     * @returns {*|*[]}
     */
    items() {
      // Get items list
      const items = this.getValue(this.block?.properties?.dataSource) || []

      // Show log message
      this.renderer.getA2U().log(`Table items: `, items)

      // Return items
      return Array.isArray(items) ? items : [items]
    }
  }
}
</script>

<style lang="scss">

.tableview-cmp {
  flex-direction: column;
  position: relative;

  .events {
    position: absolute;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    z-index: 1;
  }
}

</style>
