import axios from 'axios';

export class AnalyticsManager {

    // Manager instance
    static instance = null;

    /**
     * Constructor
     */
    constructor(a2u) {

        // Testing mode
        this.a2u = a2u;
        this.isTesting = this.a2u.runMode !== 'release'
        this.userParams = {}

        // Checks if the platform is 'web' and the build target is 'mobile'.
        // If both conditions are met, it immediately returns from the constructor, preventing further execution.
        if (this.a2u.getPlatform() === 'web' && this.a2u.getBuildTarget() === 'mobile') {
            return;
        }

        // Store analytics provider
        this.provider = this.a2u.getDevice().getPlugin("AnalyticsProvider");
        if(!this.provider) {
            this.a2u.log("Analytics provider not found")
        }

        // Load mmp provider
        this.mmp = this.a2u.getDevice().getPlugin("MMPProvider");
        if(!this.mmp) {
            this.a2u.log("MMP provider not found")
        }

        // Load crashlytics provider
        this.crashlytics = this.a2u.getDevice().getPlugin("CrashlyticsProvider");
        if(!this.crashlytics) {
            this.a2u.log("Crashlytics provider not found")
        }

        // Nothing to do if testing
        if(this.isTesting) return;

        // Set user properties
        this.userParams = {
            code_version: this.a2u.getCodeVersion(),
            schema_version: this.a2u.getSchemaVersion(),
            moduleId: this.a2u.getModuleId(),
        }
    }

    /**
     * Set user property
     * @param key
     * @param value
     * @return {Promise<void>}
     */
    async setUserProperty(key, value) {
        if(!this.provider) return;
        await this.provider.setUserProperty({key, value});
    }

    /**
     * Log event
     * @param name
     * @param params
     * @return {Promise<void>}
     */
    async logEvent(name, params) {
        if(this.isTesting) {
            return;
        }

        // Log event
        if (this.provider) {
            await this.provider.logEvent({
                name,
                params: Object.assign(params, this.userParams)
            });
        }
    }

    /**
     * Log revenue
     * @param name
     * @param source
     * @param product
     * @param revenue
     * @return {Promise<void>}
     */
    async logRevenue(name, source, product, revenue) {
        if(this.isTesting || !this.provider) return;

        // Force convert revenue to number
        revenue = Number(revenue)

        // Check if revenue is valid
        if(isNaN(revenue)) revenue = 0;

        // Log event
        await this.provider.logEvent({
            name,
            params: Object.assign({
                ad_platform: this.a2u.getDevice().getPlatform(),
                source,
                product,
                value: revenue,
                currency: "USD"
            }, this.userParams)
        })
    }

    /**
     * Log ad revenue
     * @param name
     * @param source
     * @param type
     * @param placement
     * @param revenue
     * @return {Promise<void>}
     */
    async logAdRevenue(name, source, type, placement, revenue) {
        if(this.isTesting) return;

        // Log event
        if (this.provider) {
            await this.provider.logEvent({
                name,
                params: Object.assign({
                    ad_platform: this.a2u.getDevice().getPlatform(),
                    ad_source: source,
                    ad_format: type,
                    ad_unit_name: placement,
                    value: revenue,
                    currency: "USD"
                }, this.userParams)
            })
        }

        // Send revenue to mmp
        if(this.mmp && this.provider) {
            await this.mmp.logAdRevenue({
                name,
                source,
                type,
                placement,
                currency: "USD",
                revenue
            })
        }
    }

    /**
     * Logs an error message using the Crashlytics provider.
     * This method is asynchronous and returns a Promise.
     * It calls the log method of the Crashlytics provider with an object containing the error message.
     * If the application is in testing mode or the Crashlytics provider is not available, it does nothing and returns immediately.
     *
     * @async
     * @param {string} message - The error message to log.
     * @throws {Error} If the Crashlytics provider is not available.
     */
    async logError(message) {
        if (this.isTesting || this.a2u.getPlatform() === 'web' || !this.crashlytics) return;

        await this.crashlytics.log({
            message,
        });
    }

    /**
     * This asynchronous method is used to get the application instance ID.
     * If the application is in testing mode or the provider is not available, it returns null.
     * Otherwise, it calls the getAppInstanceId method of the provider and returns the result.
     *
     * @async
     * @returns {Promise<string|null>} The application instance ID, or null if the application is in testing mode or the provider is not available.
     */
    async getAppInstanceId() {
        if (this.isTesting || !this.provider) {
            return null;
        }

        const result = await this.provider.getAppInstanceId();

        return result?.appInstanceId || null;
    }

    /**
     * This asynchronous method is used to get the advertising ID.
     * It first tries to get the "AdvertisingId" plugin from the device.
     * If the plugin is available, it calls the getAdvertisingId method of the plugin and returns the ID.
     * If any error occurs during this process, it logs the error message and returns null.
     *
     * @async
     * @returns {Promise<string|null>} The advertising ID, or null if an error occurs.
     * @throws {Error} If an error occurs when trying to get the advertising ID.
     */
    async getAdvertisingId() {
        try {
            const plugin = this.a2u.getDevice().getPlugin("AdvertisingId");

            if (!plugin) {
                return null;
            }

            const { id } = await plugin.getAdvertisingId();

            return id;
        } catch (e) {
            console.error('Error getting advertising ID:', e.message)

            return null;
        }
    }

    /**
     * This asynchronous method is used to save the relation between a user and a purchase.
     * It first gets the user ID by calling the getAppInstanceId method.
     * If the user ID is not available, it returns immediately.
     * Otherwise, it sends a POST request to the server to save the user's purchase information.
     *
     * @async
     * @param {Object} purchaseInfo - An object containing the purchase information.
     * @param {string} purchaseInfo.purchaseToken - The purchase token.
     * @param {number} purchaseInfo.purchasePrice - The price of the purchase.
     * @param {string} purchaseInfo.currencyCode - The currency code of the purchase.
     * @param {string} purchaseInfo.productId - The product ID of the purchase.
     * @param {string} purchaseInfo.diagramId - The diagram ID of the purchase.
     * @returns {Promise<void>} Returns a promise that resolves when the request is complete.
     * @throws {Error} Throws an error if the request fails.
     */
    async saveUserRelationWithPurchase({ purchaseToken, purchasePrice, currencyCode, productId, diagramId }) {
        // Get the user ID by calling the getAppInstanceId method.
        const userId = await this.getAppInstanceId();
        const advertisingId = await this.getAdvertisingId();

        // If the user ID is not available, return immediately.
        if (!userId && !advertisingId) {
            return;
        }

        try {
            // Send a POST request to the server to save the user's purchase information.
            // The request body includes the current module ID, user ID, purchase token, purchase price, and currency code.
            await axios.post(
              `${process.env.VUE_APP_A2U_URL}/analytics/saveUserRelationWithPurchase`,
              [
                  this.a2u.getModuleId(), // current module id
                  userId,
                  purchaseToken,
                  purchasePrice,
                  currencyCode,
                  productId,
                  diagramId,
                  this.a2u.getPlatform(),
                  this.a2u.getSchemaVersion(),
                  this.a2u.getCodeVersion(),
                  advertisingId,
              ]);
        } catch (e) {
            this.a2u.log('Error saving user relation with purchase:', e.message);
        }
    }
}
