<template>
    <content-editable-pe class="text-pe" v-model="blockProps.content" :block="block"/>
</template>

<script>

import ContentEditablePe from "../../ContentEditablePe.vue";
import {componentMixins} from "../../componentMixins";

export default {
    mixins: [componentMixins],
    components: {ContentEditablePe},
    props: ["block"],
    name: "TextPe",
}

</script>

<style lang="scss">

.text-pe {
    font-size: 1em;
}

</style>
