<template>

  <div>
      Test component: GamePlay!!!
  </div>

</template>

<script>

export default {
    name: "GamePlay"
}

</script>
