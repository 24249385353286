<template>
    <ab-flow-base-cmp class="ad-interstitial-editor-cmp">
        <atu-components-renderer v-if="!isLoaded" :items="block.children"/>
        <template v-else>
            <div class="ad-interstitial-editor-cmp__content">
                <div class="text-h1">
                    Ad title
                </div>
                <div class="ad-interstitial-editor-cmp__content__button">
                    <q-btn @click="show" label="Close" color="primary"/>
                </div>
            </div>
        </template>
    </ab-flow-base-cmp>
</template>

<script>
import AbFlowBaseCmp from "../Containers/Designer/AbFlowBaseCmp";
import AtuComponentsRenderer from "../../AtuComponentsRenderer.vue";
import {renderMixins} from "../renderMixins.js";

export default {
    mixins: [renderMixins],
    components: {AtuComponentsRenderer, AbFlowBaseCmp},
    props: ['block'],
    name: "AdBaseInterstitialEditorCmp",
    data: () => ({
        isLoaded: false
    }),
    mounted() {
        setTimeout(() => {
            this.loaded()
        }, 1000)
    },

    methods: {

        /**
         * Ad loaded
         */
        loaded() {
            this.isLoaded = true;
        },

        /**
         * Show interstitial
         */
        show() {
            this.diagram.fireEvent('success', this.block, this.injectedData)
        },

        /**
         * Ad load error
         */
        loadError() {
            this.diagram.fireEvent('error', this.block, this.injectedData)
        }
    }
}

</script>

<style lang="scss">

.ad-interstitial-editor-cmp {
  flex-grow: 1;
}

.ad-interstitial-editor-cmp__content {
  width: 100vw;
  height: 100vh;
  background: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

</style>
