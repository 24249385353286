import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class DbSubscribeProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param event
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    async processEvent(event, data) {

        // Errors accumulator
        const errors = [];

        //console.log("sub", this.diagramObj?.source?.properties?.subscriptions);
        for (const sub of Object.values(this.block.properties?.subscriptions || {})) {

            // Prepare params
            const params = {};
            for (const [name, val] of Object.entries(sub.query?.bindings || {})) {
                params[name] = this.context.getValue(val)
            }

            // Subscribe for data
            try {
                await this.app.renderer.a2u.dbs[sub.dbId].subscribe(sub.tableId, sub.subId, params)
            } catch (e) {
                errors.push(e)
                console.error(e)
            }
        }

        // Return result event
        this.processOutgoingLinks(this.context, this.block.id, errors, errors.length > 0 ? "error" : "success");
    }
}
