<template>
  <div class="q-gutter-x-md">

    <!-- Language select -->
    <div v-if="!languages.length" class="text-subtitle1 text-grey">
      No languages found
    </div>
    <q-select
        v-else
        class="q-mt-md"
        v-model="appLocale"
        label="App locale"
        map-options
        emit-value
        filled
        :options="languages"
    />

    <!-- Lock application state -->
    <q-toggle
        class="q-mt-md"
        v-model="lockAppState"
        label="Lock application state, application will retain last state when restarted"/>

    <q-separator spaced/>

    <!-- Restart application button -->
    <div>
      <q-btn
          class="q-mt-md"
          color="primary"
          label="Restart application"
          @click="appConsole.restartApp"/>
    </div>
  </div>

</template>

<script>
import {constants} from 'a2u-renderer-common/src/constants/contants';

export default {
  name: 'AppSettings',

  inject: {
    appConsole: {
      default: null,
    },
    renderer: {
      default: null,
    },
  },

  data() {
    return {
      appLocale: null,
    };
  },

  computed: {
    /**
     * Returns the localization object from the renderer's a2u source.
     * If the renderer or the a2u source is not defined, or if the localization object is not present, it returns an empty object.
     *
     * @returns {Object} The localization object from the renderer's a2u source, or an empty object if it's not present.
     */
    localization() {
      return this.renderer?.a2u?.source?.localization || {};
    },

    /**
     * App lock state
     */
    lockAppState: {
      get() {
        return !!this.app.client.config.get('settings.appLockState', false);
      },
      set(value) {
        this.app.client.config.set('settings.appLockState', value ? {} : false);
      }
    },

    /**
     * Returns the main locale from the localization object.
     * If the localization object or the main locale is not defined, it returns undefined.
     *
     * @returns {string} The main locale from the localization object, or undefined if it's not present.
     */
    mainLocale() {
      return this.localization?.mainLocale;
    },

    /**
     * Returns an array of locales for the app.
     * The array includes the main locale and any additional locales defined in the localization object.
     *
     * @returns {Array} An array of locales for the app, including the main locale and any additional locales.
     */
    appLocales() {
      return [
        this.mainLocale,
        ...(this.localization?.additionalLocales || [])
      ];
    },

    /**
     * Returns an array of language objects that are included in the app's locales.
     * Each language object includes a label and a value.
     * The label is the title of the language, and the value is the value of the language.
     *
     * @returns {Array} An array of language objects that are included in the app's locales.
     */
    languages() {
      return constants.options.collections.languages.filter(
          (v) => this.appLocales.includes(v.value)
      ).map((v) => ({label: v.title, value: v.value}));
    },
  },

  watch: {
    // Watch for changes to the appLocale and update the app's config settings.
    appLocale(value) {

      // Store lock mode
      this.app.client.config.set('settings.applocale', value);

      // Restart app if lock mode is enabled
      if (this.lockAppState) {
        this.appConsole.restartApp();
      }
    },
  },

  mounted() {
    // Set the appLocale to the value of the app's config settings.
    this.appLocale = this.app.client.config.get('settings.applocale', null);
  },
}
</script>
