<template>
  <ab-flow-base-cmp class="treeview-cmp" :block="block" :class="classesString" :style="stylesString">

    <q-tree
        class="col"
        :nodes="datasource"
        node-key="id"
        no-selection-unset
        v-model:selected="selectedId"
        v-model:expanded="expandedIds"
        @update:expanded="onNodeExpand"
    >
      <template v-slot:default-header="data">
        <data-provider v-if="itemSlot" :storage-key="block?.title"
                       :data="new AbStorage(prepareStorageData(data.node))">
          <div @click.stop.prevent class="full-width">
            <atu-components-renderer :items="[itemSlot]"/>
          </div>
        </data-provider>
        <template v-else>
          <tree-view-default-item :block="block" :node="data.node"/>
        </template>
      </template>

    </q-tree>


  </ab-flow-base-cmp>
</template>

<script>
import {reactive} from 'vue';
import difference from "lodash/difference";
import {AbStorage} from "a2u-renderer-common/src/utils/abStorage";
import {treeHelper} from "a2u-renderer-common/src/utils/treeHelper";
import AbFlowBaseCmp from "../../Containers/Designer/AbFlowBaseCmp.vue";
import {renderMixins} from "../../renderMixins";
import AtuComponentsRenderer from "../../../AtuComponentsRenderer.vue";
import DataProvider from "../DataProvider/DataProvider.vue";
import TreeViewDefaultItem from "./TreeViewDefaultItem.vue";

export default {
  components: {TreeViewDefaultItem, DataProvider, AtuComponentsRenderer, AbFlowBaseCmp},
  mixins: [renderMixins],
  props: ['block'],
  name: "TreeViewEditorCmp",
  data: () => ({
    selectedId: false,
    itemSlot: false,
    expandedIds: [],
    oldExpandedIds: []
  }),

  created() {

    // Check if tree has slot
    const slotFragmentId = this.renderer.a2u.links.outgoing[this.block.id]?.["item-slot"]?.[0]?.target
    if (slotFragmentId) {
      this.itemSlot = this.renderer.a2u.blocks[slotFragmentId]?.node
    }
  },

  /**
   * Tree is mounted
   */
  mounted() {

    // Run expand processor first time
    this.parentDiagram.processOutgoingLinks(this, this.block.id, {id:"", title: "Root"}, "expand")

  },

  methods: {

    /**
     * On node expand
     * @param expanded
     */
    onNodeExpand(expanded) {

      // Find difference between old and new expanded ids
      const diff = difference(expanded, this.oldExpandedIds)

      // Store expanded
      this.oldExpandedIds = expanded;

      // Get node id
      const exNodeId = diff?.[0]

      // Check if node id is not empty
      if(exNodeId) {

        // Get exact expanded node
        const node = treeHelper.traverseTree({children: this.datasource}, (node) => {
          if (node.id === exNodeId) return true
        })

        // Call expand processor
        this.parentDiagram.processOutgoingLinks(this, this.block.id, node, "expand")
      }
    },

    /**
     * Prepares the data for storage.
     *
     * This method is responsible for preparing the data that will be stored.
     * It creates an object with two properties: `initialStorage` and `storageCreator`.
     *
     * @param {Object} node - The node object that will be stored.
     * @returns {Object} The prepared data for storage. It includes:
     * - `initialStorage`: an object that contains the node as an item.
     * - `storageCreator`: a function that takes data as a parameter and returns a reactive version of the data.
     */
    prepareStorageData(node) {
      return {
        initialStorage: {item: node},
        storageCreator: (data) => reactive(data),
      }
    }
  },

  computed: {
    AbStorage() {
      return AbStorage
    },

    /**
     * Get datasource
     * @return []
     */
    datasource() {
      // Get nodes list
      const nodes = this.getValue(this.block?.properties?.dataSource) || [];
      return nodes !== undefined ? Array.isArray(nodes) ? nodes : [nodes] : []
    }

  }
}
</script>

<style lang="scss">

.treeview-cmp {
}

</style>
